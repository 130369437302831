<template>
  <div class="section">
    <div class="section-title">为什么要学习H5前端</div>
    <div class="section-content">
      <div class="left">
        <button :class="currentIndex === 1 ? 'active' : ''" @mouseover="currentIndex = 1">
          <span style="font-size: 26px; vertical-align: middle;">01</span><span>前端应用范围广</span>
        </button>
        <button :class="currentIndex === 2 ? 'active' : ''" @mouseover="currentIndex = 2">
          <span style="font-size: 26px; vertical-align: middle;">02</span><span>跨全平台</span>
        </button>
        <button :class="currentIndex === 3 ? 'active' : ''" @mouseover="currentIndex = 3">
          <span style="font-size: 26px;vertical-align: middle;">03</span><span>技术更新快，市场需求大</span>
        </button>
      </div>
      <div class="right">
        <div class="content">
          <div class="content-left">
            <div class="title">
              <span>
                <img src="@/assets/img/classes/icon/text-title-icon.png" />
                H5前端
              </span>
            </div>
            <div>
              <ul>
                <li>
                  <img src="@/assets/img/classes/icon/cricle.png" alt="" />
                  <div>
                    几乎所有的我们使用的电子设备上的应用都可以<br />
                    使用前端技术机型开发。
                  </div>
                </li>
                <li>
                  <img src="@/assets/img/classes/icon/cricle.png" alt="" />
                  <div>
                    涵盖网站、App、小程序、快应用、鸿蒙App、电脑<br />
                    桌面应用等多类型应用的开发。
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="content-right">
            <img src="@/assets/img/classes/web-img/why-1.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentIndex: 1,
    };
  },
  components: {},
  mounted() { },
  methods: {},
};
</script>
<style scoped lang='scss'>
* {
  box-sizing: border-box;
}

.active {
  background-color: #216df4 !important;
  color: #fff;
}

.section {
  margin-top: 60px;
  width: 100%;
  height: 395px;
  background-color: #fafbff;
  padding: 0 15px 14px;
  box-sizing: border-box;

  .section-title {
    font-size: 24px;
  }

  .section-content {
    height: 325px;

    display: flex;
    justify-content: space-between;
    margin-top: 27px;

    .left {
      display: flex;
      flex-wrap: wrap;
      align-content: space-between;

      width: 310px;
      height: 100%;
      padding: 27px 0 27px 17px;
      border: 1px solid #cfe4fd;
      border-radius: 12px;

      button {
        line-height: 56px;
        height: 56px;
        width: 260px;
        border-radius: 56px;
        text-align: left;
        font-size: 18px;
        padding-left: 15px;
        background-color: transparent;

        span:nth-child(1) {
          padding-right: 10px;
        }
      }
    }

    .right {
      // display: flex;
      width: 837px;
      border: 1px solid #cfe4fd;
      border-radius: 12px;
      // justify-content: center;
      // align-items: center;
      padding: 43px 75px 41px 67px;

      .content {
        display: flex;
        justify-content: space-between;
        background-color: #fff;
        padding: 10px;

        .content-left {
          .title {
            width: 238px;
            height: 56px;
            border-bottom: 1px solid #e3effb;
            font-size: 36px;

            span {
              display: inline-block;
              line-height: 49px;
              border-bottom: 2px solid #216df4;
              color: #216df4;

              img {
                height: 56px;
                vertical-align: middle;
              }
            }
          }

          ul {
            padding: 20px 0;
            margin-top: 10px;

            li {
              position: relative;
              line-height: 2em;

              img {
                position: absolute;
                top: 7px;
                height: 16px;
              }

              div {
                padding-left: 20px;
              }
            }
          }
        }
      }
    }
  }
}
</style>