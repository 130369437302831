<template>
  <div class="banner">
      <el-carousel :interval="3000" indicator-position="none" :arrow="arrow">

    <slot>
      
    </slot>
  </el-carousel>
  </div>
</template>

<script>

export default {
  data () {
    return {
    }
  },
  props: {
    arrow:{
      type:String,
      default:'hover'
    }
  },
  mounted() {

  },
  methods: {

  },
}

</script>

<style scoped lang='less'>
.banner {
  position: relative;
}
::v-deep(.el-carousel__container) {
  position: static;
  height: 485px;
  width: 1920px;
img {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
}


</style>