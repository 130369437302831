import { render, staticRenderFns } from "./next-star.vue?vue&type=template&id=221fb112&scoped=true"
import script from "./next-star.vue?vue&type=script&lang=js"
export * from "./next-star.vue?vue&type=script&lang=js"
import style0 from "./next-star.vue?vue&type=style&index=0&id=221fb112&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.11.0_vue-template-compiler@2.7.16_webpack@5.93.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "221fb112",
  null
  
)

export default component.exports