<template>
    <div class="section">
        <div class="section-title">职业发展路径</div>
        <div class="section-content">
            <div class="left">
                <ul>
                    <li>
                        <img
                            src="@/assets/img/classes/web-img/peo-7.png"
                            alt=""
                        />
                        <p>
                            初级前端开发<br />
                            工程师
                        </p>
                    </li>
                    <li>
                        <img
                            src="@/assets/img/classes/web-img/peo-6.png"
                            alt=""
                        />
                        <p>
                            初级前端开发<br />
                            工程师
                        </p>
                    </li>
                    <li>
                        <img
                            src="@/assets/img/classes/web-img/peo-5.png"
                            alt=""
                        />
                        <p>
                            初级前端开发<br />
                            工程师
                        </p>
                    </li>
                </ul>
            </div>
            <div class="right">
                <div class="top">
                    <div class="first">
                        <img
                            src="@/assets/img/classes/icon/icon-com.png"
                            alt=""
                        />
                        <span> 技术方向</span>
                    </div>
                    <div>
                        <span> 自身前端技术专家</span>
                    </div>
                    <div>
                        <span> 前端架构师</span>
                    </div>
                    <div>
                        <span> CTO</span>
                    </div>
                </div>
                <div class="bottom">
                    <div class="first">
                        <img
                            src="@/assets/img/classes/icon/icon-user.png"
                            alt=""
                        />
                        <span> 管理方向</span>
                    </div>
                    <div>
                        <span> 技术开发LEADER</span>
                    </div>
                    <div>
                        <span> 项目经理</span>
                    </div>
                    <div>
                        <span> CEO</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {}
    },
    props: {},
    components: {},
    mounted() {},
    methods: {},
}
</script>
<style scoped lang="scss">
.section {
    margin-top: 60px;

    .section-title {
        font-size: 23px;
        margin-bottom: 80px;
    }

    .section-content {
        display: flex;

        .left {
            width: 40%;

            ul {
                display: flex;
                justify-content: space-between;

                li {
                    width: 130px;
                    text-align: center;

                    img {
                        width: 100%;
                        height: 130px;
                    }

                    p {
                        line-height: 1.5em;
                    }
                }
            }
        }

        .right {
            width: 60%;
            padding-top: 30px;
            margin-left: 20px;

            .bottom,
            .top {
                display: flex;
                margin-bottom: 30px;

                div {
                    position: relative;
                    display: table;
                    width: 141px;
                    height: 63px;
                    padding: 0 20px;
                    margin-left: 20px;
                    border: 1px solid #55c8fe;
                    border-radius: 5px;
                    box-sizing: border-box;
                }

                div:nth-child(1) {
                    height: 60px;
                    width: 175px;
                    background-color: #55c8fe;
                    color: #fff;
                    border: 0;

                    span {
                        padding-left: 20px;
                    }

                    img {
                        position: absolute;
                        left: 20px;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }

                div:not(.first)::before {
                    position: absolute;
                    content: "→";
                    color: #55c8fe;
                    top: 50%;
                    left: -18px;
                    transform: translateY(-50%);
                }

                span {
                    text-align: center;
                    vertical-align: middle;
                    display: table-cell;
                }
            }

            .bottom {
                div {
                    border-color: #32c7b1;
                }

                div:nth-child(1) {
                    background-color: #32c7b1;
                }

                div:not(.first)::before {
                    color: #32c7b1;
                }
            }
        }
    }
}
</style>
