<template>
  <div class="teacher-page">
    <!-- 页头 -->
    <pageHeader />
    <!-- 头部导航 -->

    <Head :nav="true" :hover="true"> </Head>
  
    <div class="banner"></div>
    <teacher-name></teacher-name>
    <footer-nav></footer-nav>
    <Footer></Footer>
    <Sidebar />
  </div>
</template>

<script>
import pageHeader from "@/components/pageHeader.vue";
import Head from "@/components/head.vue";
import footerNav from "@/components/footerNav.vue";
import teacherName from "./components/teachers-name.vue";
import Footer from "@/components/footer.vue";
import Sidebar from "@/components/sidebar.vue";
export default {
  name: "teacherIndex",
  components: {
    pageHeader,
    Head,
    footerNav,
    teacherName,
    Footer,
        Sidebar,
  },
  mounted() {
  	document.title = '合肥一元数智教育有限公司'
  },
};
</script>

<style scoped>
.home-nav-li {
  background-color: #d72502;
}

.course-list {
  display: none;
}
.home-nav-li:hover .course-list {
  display: block;
}
.banner {
  width: 100%;
  height: 600px;
  background: url(../../assets/img/about/bannerabout.jpg) no-repeat center;
}
</style>