<template>
    <div class="section clear">
        <div class="section-title">
            <span class="text-blue">完整化、系统化、全方位培训</span> 保障高薪就业
        </div>
        <div class="section-content">
            <img src="@/assets/img/classes/common/curve.png" alt="" class="img-p" />
            <ul class="chart-list">
                <li>
                    <div class="s-1">01</div>
                    <div class="text"><div>入学建档系统意向</div></div>
                </li>
                <li>
                    <div class="s-1">02</div>
                    <div class="text"><div>预科一周学习</div></div>
                </li>
                <li>
                    <div class="s-1">03</div>
                    <div class="text"><div>选择适合自己的专业</div></div>
                </li>
                <li>
                    <div class="s-1">04</div>
                    <div class="text"><div>入学建档系统意向</div></div>
                </li>
                <li>
                    <div class="s-1">05</div>
                    <div class="text"><div>正式学习</div></div>
                </li>
                <li>
                    <div class="s-1">06</div>
                    <div class="text"><div>阶段面授学习</div></div>
                </li>
                <li>
                    <div class="s-1">07</div>
                    <div class="text"><div>实战项目演练</div></div>
                </li>
                <li>
                    <div class="s-1">14</div>
                    <div class="text"><div>毕业项目制作测评</div></div>
                </li>
                <li>
                    <div class="s-1">13</div>
                    <div class="text">
                        <div>面试通过</div>
                        <button>面试未通过</button>
                    </div>
                </li>
                <li>
                    <div class="s-1">12</div>
                    <div class="text"><div>正式面试</div></div>
                </li>
                <li>
                    <div class="s-1">11</div>
                    <div class="text"><div>推荐就业</div></div>
                </li>
                <li>
                    <div class="s-1">10</div>
                    <div class="text"><div>模拟面试</div></div>
                </li>
                <li>
                    <div class="s-1">09</div>
                    <div class="text"><div>面试指导</div></div>
                </li>
                <li>
                    <div class="s-1">08</div>
                    <div class="text"><div>完善个人简介</div></div>
                </li>
                <li>
                    <div class="s-1">15</div>
                    <div class="text"><div>职业专业培训</div></div>
                </li>
                <li>
                    <div class="s-1">16</div>
                    <div class="text"><div>就业服务中心</div></div>
                </li>
                <li>
                    <div class="s-1">17</div>
                    <div class="text"><div>二次就业指导</div></div>
                </li>
                <li>
                    <div class="s-1">18</div>
                    <div class="text"><div>面试通过</div></div>
                </li>
                <li>
                    <div class="s-1">19</div>
                    <div class="text"><div>顺利就业</div></div>
                </li>
                <li>
                    <div class="s-1">20</div>
                    <div class="text"><div>工作指导</div></div>
                </li>
                <li>
                    <div class="s-1">21</div>
                    <div class="text"><div>顺利晋升</div></div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {}
    },
    components: {},
    mounted() {},
    methods: {}
}
</script>
<style scoped lang="scss">
.section {
    margin-top: 60px;
    width: 110%;
    margin-left: -60px;
    .section-title {
        text-align: center;
        font-size: 48px;
        color: #333334;
        .text-blue {
            font-weight: 600;
        }
    }
    .section-content {
        position: relative;
        height: 765px;
        margin-top: 60px;
        .img-p {
            width: 100%;

            text-align: center;
            color: #fff;

            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: -1;
        }
        .chart-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            margin-left: 60px;
            li {
                position: relative;
                width: 156px;
                height: 227px;
                margin: 0 15px 25px 0;
                transition: all 0.3s;
                cursor: pointer;
                .s-1 {
                    position: absolute;
                    top: 0;
                    left: 50%;
                    width: 71px;
                    height: 83px;
                    line-height: 83px;
                    color: #fff;
                    font-size: 36px;
                    text-align: center;
                    background: url(@/assets/img/classes/common/six.png) no-repeat;
                    transform: translateX(-50%);
                    z-index: 5;
                }

                .text {
                    position: absolute;
                    display: table;
                    width: 100%;
                    height: 187.6px;
                    bottom: 0;
                    background-color: #fff;
                    border: 1px solid #ccc;
                    text-align: center;
                    border-radius: 19px;
                    div {
                        display: table-cell;
                        width: 108px;
                        height: 187.6px;
                        vertical-align: middle;
                        line-height: 26px;
                        font-size: 18px;
                        padding: 0 20px;
                    }
                    button {
                        position: absolute;
                        bottom: 10px;
                        left: 50%;
                        transform: translateX(-50%);
                        height: 38px;
                        width: 117px;
                        background-color: #5147ff;
                        color: #fff;
                        border-radius: 38px;
                    }
                }
            }
            li:hover {
                transform: scale(1.05);
            }
        }
    }
}
</style>
