import request from "@/utils/request"

// 课程阶段模块
export function getModule(type) {
    // console.log(data);
    return request({
        url: `/outline/list`,
        method: 'GET',
        params: { type }
    })
}

export function getProject(type) {
    return request({
        url: "/realProject/list",
        method: "get",
        params: {
            type: type
        }
    })
}
export function getStudent() {
    return request({
        url: "/job/list",
        method: "get",

    })
}
export function getTeacher(type) {
    return request({
        url: "/lecturer/list",
        method: "get",
        params: {
            type: type
        }
    })
}
export function getFigures(type) {
    return request({
        url: "/figures/list",
        method: "get",
        params: {
            type: type
        }
    })
}
