<template>
  <nav>
    <ul class="nav-list">
      <slot></slot>
    </ul>
  </nav>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  mounted() {},
  methods: {},
};
</script>
<style scoped lang='scss'>
nav {
  font-size: 16px;
  .nav-list {
    display: flex;
    height: 40px;
    line-height: 40px;
    justify-content: center;
    text-align: center;
    a {
      color: #000;
    }
    a:hover {
      color: #d72502;
    }

    li {
      width: 100px;
      margin: 0 40px 0 20px;
    }
    .home-nav-li {
      width: 233px !important;
      margin-left: -30px !important;

      border-radius: 9px 11px 0px 0px;
      a {
        color: #fff !important;
        cursor: pointer;
      }
    }
  }
  .course-list {
    position: absolute;
    width: 233px;
    line-height: 35px;
    font-size: 15px;
    padding: 23px 0;
    // line-height: 25px;
    // font-size: 12px;
    // padding: 23px 24px;
    text-align: left;
    // background-color: #757482;
    background-color: #4b4b4bba;
    box-sizing: border-box;
    border-radius: 0px 0px 30px 30px;
    z-index: 999;
    .item {
      line-height: 48.5px;
      padding-left: 23px;
      transition: all 0.2s;
    }
    .item:hover {
      background-color: #4b4b4bc0;
      padding-left: 30px;
    }
  }
}
</style>