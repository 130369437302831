<template>
  <div class="section clear">
    <div class="bg-blue"></div>
    <div class="section-title">
      这门课程<span class="text-green">适合谁？</span>
    </div>
    <div class="section-content">
      <div class="content-main">
        <ul>
          <li>
            <img src="@/assets/img/classes/icon/avatar-1.png" alt="" />
            <div class="white-bg"><span class="text">计算机相关专业</span></div>
            <button>立即咨询</button>
          </li>
          <li>
            <img src="@/assets/img/classes/icon/avatar-2.png" alt="" />
            <div class="white-bg"><span class="text"> 数字相关专业</span></div>
            <button>立即咨询</button>
          </li>
          <li>
            <img src="@/assets/img/classes/icon/avatar-3.png" alt="" />
            <div class="white-bg">
              <span class="text"> ICT在职转行大数据</span>
            </div>
            <button>立即咨询</button>
          </li>
          <li>
            <img src="@/assets/img/classes/icon/avatar-4.png" alt="" />
            <div class="white-bg">
              <span class="text"> <span>对大数据特别有兴趣的人群</span></span>
            </div>
            <button>立即咨询</button>
          </li>
        </ul>
      </div>
      <div class="content-foot">
        <button>我适合学这门课程么</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
 
  components: {},
  mounted() {},
  methods: {},
};
</script>
<style scoped lang='scss'>
.section {
  position: relative;


  .bg-blue {
    position: absolute;
    left: -360px;
    width: 1920px;
    height: 616px;
    background-color: #4666ff;
    z-index: -1;
  }
  .section-title {
    margin-top: 60px;
    text-align: center;
    font-size: 36px;
    color: #fff;

    .text-green {
      color: #01ff75;
      font-weight: 600;
    }
  }
  .section-content {
    margin-top: 80px;
    ul {
      display: flex;
      justify-content: space-between;
      li {
        position: relative;
        width: 271px;
        height: 288px;
        img {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
        }
        .white-bg {
          display: table;
          width: 271px;
          height: 198px;
          margin-top: 46px;
          text-align: center;
          background-color: #fff;
          border-radius: 8px;
          .text {
            display: table-cell;
            vertical-align: middle;

            font-size: 24px;
            font-weight: bold;
            span {
              display: inline-block;
              width: 195px;
            }
          }
        }
        button {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 210px;
          height: 72px;
          font-size: 24px;
          border-radius: 8px;
          color: #fff;
          background-image: linear-gradient(to right, #04e074, #018fe8);
        }
      }
    }
    .content-foot {
        margin: 60px 0 30px;
        text-align: center;
        button {
            height: 58px;
            width: 345px;
            border-radius: 29px;
            background-image: linear-gradient( #04e074, #018fe8);
            color: #fff;
        }
       
    }
  }

}
</style>