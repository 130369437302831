<template>
    <div>
        <PageHeader></PageHeader>
        <Head :nav="true" :hover="true"> </Head>
        <div style="margin-bottom: 20px;">
            <el-image
      style="width: 100%; height: 360px"
      :src="require('@/assets/img/news/再出发.jpg')"
      ></el-image>
        </div>
        <div class="news">
            <el-row>
                <el-col :span="7">
                <div style="background-color: #b7433d;
                height: 52px;
                width: 99.85%;
                color: white;
                text-align: center;
                line-height: 52px;
                font-size: 21px;">
                    <span slot="title" style="font-weight: bold;">北大青鸟</span>
                </div>
                <el-menu default-active="2" class="el-menu-vertical-demo"
                    background-color="#ededed" active-text-color="#ffd04b">
                    <el-submenu index="1">
                        <template slot="title">
                            <span style="font-size: 16px;font-weight: bold;">培训课程</span>
                        </template>
                        <el-menu-item-group>
                            <el-menu-item index="1-1" >
                                <a style="font-size: 16px;color: rgb(98, 95, 95);" @click="$router.push('/java')">Java开发培训</a>
                            </el-menu-item>
                            <el-menu-item index="1-2">
                                <a style="font-size: 16px;color: rgb(98, 95, 95);" href="#" @click="$router.push('/ui')">UI/UE设计培训</a>
                            </el-menu-item>
                            <el-menu-item index="1-3">
                                <a style="font-size: 16px;color: rgb(98, 95, 95);" href="" @click="$router.push('/web')">大前端(H5+小程序+APP)</a>
                            </el-menu-item>
                            <el-menu-item index="1-4">
                                <a style="font-size: 16px;color: rgb(98, 95, 95);" href="#" @click="$router.push('/bigdata')">大数据培训</a>
                            </el-menu-item>
                            <el-menu-item index="1-5">
                                <a style="font-size: 16px;color: rgb(98, 95, 95);" href="">Python+人工智能</a>
                            </el-menu-item>
                            <el-menu-item index="1-6">
                                <a style="font-size: 16px;color: rgb(98, 95, 95);" href="">PHP开发设计</a>
                            </el-menu-item>
                            <el-menu-item index="1-7">
                                <a style="font-size: 16px;color: rgb(98, 95, 95);" href="">云计算工程师培训</a>
                            </el-menu-item>
                            <el-menu-item index="1-8">
                                <a style="font-size: 16px;color: rgb(98, 95, 95);" href="">短视频运营+剪辑</a>
                            </el-menu-item>
                        </el-menu-item-group>
                    </el-submenu>
                    <el-menu-item index="2">
                        <a style="font-size: 16px;font-weight: bold;color: black;" href="#" @click="$router.push('/')">首页</a>
                    </el-menu-item>
                    <el-menu-item index="3">
                        <a style="font-size: 16px;font-weight: bold;color: black;" href="#" @click="$router.push('/teacher')">师资力量</a>
                    </el-menu-item>
                    <el-menu-item index="4">
                        <a style="font-size: 16px;font-weight: bold;color: black;" href="#" @click="$router.push('/student')">就业保障</a>
                    </el-menu-item>
                    <el-menu-item index="5">
                        <a style="font-size: 16px;font-weight: bold;color: black;" href="#" @click="$router.push('/')">教学环境</a>
                    </el-menu-item>
                    <el-menu-item index="6">
                        <a style="font-size: 16px;font-weight: bold;color: black;" href="#" @click="$router.push('/')">项目实战</a>
                    </el-menu-item>
                </el-menu>

                </el-col>
                <el-col :offset="1" :span="16">
                    <h2 class="title">{{ news.title }}</h2>
                    <div v-html="news.context"></div>
                </el-col>
            </el-row>
            <div class="before_after" style="margin-top: 20px;">
                <div class="hd_ff">上下文导航</div>
                <ul>
                    <li>
                        <a href="">匠心教学,诚信兴企,北大青鸟荣获“放心品牌”大奖！</a>
                    </li>
                    <li>
                        <a href="">职场直达，彰显北大青鸟超强就业服务能力！</a>
                    </li>
                </ul>
            </div>
            <div class="recent_entries">
                <span class="hd_tt">相关内容</span>
                <ul>
                    <li>
                        <a href="/wz/5394---.html"
                            >对接企业真实项目，首创职场化教学模式，带给学员最有价值的项目经验</a
                        >
                    </li>
                    <li>
                        <a href="/wz/5399---.html"
                            >北大青鸟一元中心的“上分”秘诀：从“倔强青铜”上到“荣耀王者”</a
                        >
                    </li>
                    <li>
                        <a href="/wz/5407---.html">UI界面排版搞不定？来看看这篇版式设计干货</a>
                    </li>
                    <li>
                        <a href="/wz/5414---.html">哪些人适合学前端？想入门的你卡在了哪里</a>
                    </li>
                    <li><a href="/wz/5435---.html">合肥web前端开发培训哪家好</a></li>
                    <li><a href="/wz/5438---.html">合肥web前端哪家培训好</a></li>
                    <li><a href="/wz/5441---.html">合肥UI设计培训机构哪家好</a></li>
                    <li><a href="/wz/5445---.html">合肥UI设计的学费大约是多少</a></li>
                    <li><a href="/wz/5450---.html">合肥有什么好的UI设计培训班</a></li>
                    <li><a href="/wz/5453---.html">合肥到什么地方学电脑技术比较好</a></li>
                </ul>
            </div>
        </div>
        <FooterNav />
        <Footer />
    </div>
</template>

<script>
import PageHeader from '@/components/pageHeader.vue'
import Head from '@/components/head.vue'

import FooterNav from '@/components/footerNav'
import Footer from '@/components/footer.vue'
import { getNews, getNewsById } from '@/api/home'
import { reactive } from 'vue'
export default {
    name: 'News',
    components: {
        PageHeader,
        Head,
        Footer,
        FooterNav
    },
    data() {
        return {
            isShow: false,
            list: [],
            news: '',
            newsId: 0
        }
    },
    created() {
        this.newsId = this.$route.query.id

        this.getNews()
    },
    mounted() {
    	document.title = '合肥一元数智教育有限公司'
    },
    methods: {
        enter() {
            this.isShow = true
        },
        async getNews() {
            // const r = await getNews()
            this.news = await getNewsById(this.newsId)
        }
    }
}
</script>

<style scoped lang="less">
.item:hover {
    background-color: #313131;
}
.home-nav-li {
    background-color: #d72502;
}
.course-list {
    display: none;
}
.home-nav-li:hover .course-list {
    display: block;
}
.news {
    width: 1200px;
    margin: 0 auto;
    // text-align: center;
    line-height: 30px;
    .title {
        text-align: center;
        font-size: 24px;
        margin-bottom: 20px;
    }
}

.entry-content {
    margin: 0 auto;
    display: block;
    padding: 20px 0;
}

.entry-content .page-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 35px;
    padding: 20px;
}

.page-title {
    height: 40px;
    line-height: 40px;
    margin: 10px auto;
    text-align: center;
    font-size: large;
}

.before_after {
    height: 70px;
}
.hd_ff {
    font-size: 22px;
    padding-left: 20px;
    border-left: 4px solid #d72502;
}
.hd_tt {
    float: left;
    font-size: 22px;
    border-left: 4px solid #d72502;
    padding-left: 20px;
}

before_after ul,
.recent_entries ul {
    width: 100%;
    display: block;
    clear: both;
}

.before_after ul li,
.recent_entries ul li {
    display: inline-table;
    clear: both;
    line-height: 24px;
    width: 45%;
    overflow: hidden;
    white-space: nowrap;
    word-wrap: break-word;
}

.footNav {
    width: 100%;
    height: 225px;
    background-color: #f1f1f1;
}

.mainBox {
    width: 1200px;
    padding-top: 50px;
    margin: 0 auto;
}

.mainBox ul {
    list-style-type: none;
    height: 125px;
}

.clear {
    height: 125px;
    // background-color: #d72502;
}

.mainBox ul li {
    float: left;
    width: 240px;
    height: 125px;
    margin-right: 80px;
    text-align: center;
    position: relative;
    cursor: pointer;
}

.mainBox ul li:last-child {
    margin-right: 0px;
}

.mainBox ul li > a {
    display: block;
    width: 200px;
    height: 40px;
    line-height: 40px;
    border: 1px solid #b7b7b7;
    font-size: 14px;
    color: #292832;
    margin: 0 20px;
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 14px;
    transition: all 0.3s ease-out;
}

.mainBox ul li > a:hover {
    background-color: #ec6c00;
}

.course-list {
    background-color: orangered;
}
</style>
