<template>
    <div class="section">
        <div class="section-title">最新课程大纲</div>
        <div class="section-content">
            <div class="left">
                <ul>
                    <li
                        :class="currentIndex === item ? 'active' : ''"
                        v-for="(item, index) in plates"
                        :key="index"
                        @click="currentIndex = item"
                    >
                        <i></i> <span>{{ item }}</span>
                    </li>
                </ul>
            </div>
            <div class="right" v-for="box in outline" :key="box.id" v-show="currentIndex == box.module">
                <p class="title">{{ box.title }}</p>
                <p class="s-title">
                    <img src="@/assets/img/classes/icon/books-icon.jpg" alt="" />
                    主要内容
                </p>
                <div class="content">
                    <span v-for="(item, index) in box.skill" :key="index">{{ item }}</span>
					
                </div>
                <p class="s-title">
                    <img src="@/assets/img/classes/icon/books-icon.jpg" alt="" />
                    特色亮点可掌握的核心能力
                </p>
                <div class="list">
                    <div v-html="box.ability">

					</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        plates: {
            type: Array,
            default() {
                return [
                    '技术阶段一',
                    '技术阶段二',
                    '技术阶段三',
                    '技术阶段四',
                    '技术阶段五',
                    '技术阶段六',
                    '技术阶段七',
                    '技术阶段八'
                ]
            }
        },
        outline: {
            type: Array,
            default() {
                return [
                    {
                        id: 1,
                        title: '前端网页重构',
                        skill: [
                            'HTML CSS BFC',
                            'lFC、FFC、GFC模型',
                            'PS切图蓝湖',
                            '盒模型',
                            '浮动',
                            '背景定位',
                            'Flex Grid',
                            '字体动画',
                            '标签语法',
                            '布局标签',
                            '多媒体标签',
                            '电商网页开发',
                            'BootStrap',
                            '响应式布局',
                            '自适式布局'
                        ],
                        ability: [
                            '1、编写HTML的能力',
                            '2、编写css的能力',
                            '3、合理使用语义化标签及W3C规范的能力',
                            '4、开发PC端网页的能力5、视口适配的能力',
                            '6、移动端差异化兼容解决的能力',
                            '7、基于BootStrap及媒体查询开发响应式网站的能力',
                            '8、基于企业主流流程重构网页的能力',
                            '9、flex弹性盒子及grid网格布局的能力',
                            '10、熟悉真实团队开发流程'
                        ]
                    }
                ]
            }
        }
    },
    data() {
        return {
            currentIndex: '技术阶段一'
        }
    },
    components: {},
    created() {},
    mounted() {},
    methods: {}
}
</script>
<style scoped lang="scss">
* {
    box-sizing: border-box;
}
.section {
    margin-top: 60px;
    width: 100%;
    .section-title {
        text-align: center;
        font-size: 35px;
        font-weight: 700;
    }
    .section-content {
        display: flex;
        margin-top: 21px;
        height: 582px;
        .left {
            width: 210px;
            height: 528px;
            background: #4666ff;
            padding: 22px 10px 25px;
            border-radius: 5px;
            ul {
                .active {
                    background-color: #55adff;
                    border-radius: 40px;
                }
                li {
                    height: 40px;
                    line-height: 40px;
                    color: #8b9dfa;
                    //   background-color: #55adff;
                    text-align: center;
                    margin: 0 11px 0;
                    cursor: pointer;
                    i {
                        display: inline-block;
                        height: 10px;
                        width: 10px;
                        background-color: #80e2e1;
                        border-radius: 50%;
                        margin-right: 10px;
                    }
                    span {
                        color: #fff;
                    }
                }
            }
        }
        .right {
            height: 528px;
            width: 950px;
            padding-left: 39px;
            .title {
                font-size: 24px;
                font-weight: 600;
                margin: 20px 0 30px;
            }
            .s-title {
                font-weight: 600;
                margin-bottom: 20px;
                img {
                    vertical-align: middle;
                    margin-right: 10px;
                }
            }
            .content {
                // height: 122px;
                padding: 0px 0 20px 0;
                span {
                    display: inline-block;
                    height: 30px;
                    line-height: 30px;
                    text-align: center;
                    padding: 0 10px;
                    background-color: #82a8e9;
                    color: #fff;
                    margin: 0 15px 15px 0;
                    border-radius: 20px;
                    cursor: pointer;
                }
            }
        }
        .list {
            line-height: 1.5em;
        }
    }
}
</style>
