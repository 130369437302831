var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section"},[_vm._m(0),_c('div',{staticClass:"section-content"},[_c('div',{staticClass:"content-left"},[_c('ul',[_c('li',{class:{ active: _vm.currentIndex == 1 },on:{"mouseover":function($event){return _vm.change(1)}}},[_vm._m(1)]),_c('li',{class:{ active: _vm.currentIndex == 2 },on:{"mouseover":function($event){return _vm.change(2)}}},[_vm._m(2)]),_c('li',{class:{ active: _vm.currentIndex == 3 },on:{"mouseover":function($event){return _vm.change(3)}}},[_vm._m(3)]),_c('li',{class:{ active: _vm.currentIndex == 4 },on:{"mouseover":function($event){return _vm.change(4)}}},[_vm._m(4)])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentIndex === 1),expression:"currentIndex === 1"}],staticClass:"content-right"},[_c('div',{staticClass:"title"},[_vm._v("万亿市场+百万人才缺口")]),_vm._m(5),_vm._m(6),_vm._m(7)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentIndex === 2),expression:"currentIndex === 2"}],staticClass:"content-right"},[_c('div',{staticClass:"title"},[_vm._v("大数据技能应用领域广，行业覆盖全")]),_vm._m(8)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentIndex === 3),expression:"currentIndex === 3"}],staticClass:"content-right"},[_c('div',{staticClass:"title"},[_vm._v("万亿市场+百万人才缺口")]),_vm._m(9),_vm._m(10)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentIndex === 4),expression:"currentIndex === 4"}],staticClass:"content-right"},[_c('div',{staticClass:"title"},[_vm._v("万亿市场+百万人才缺口")]),_vm._m(11),_vm._m(12),_vm._m(13)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-title"},[_c('div',{staticClass:"bg"},[_c('img',{attrs:{"src":require("@/assets/img/classes/common/BIG DATA.png"),"alt":""}})]),_c('span',{staticClass:"text-blue"},[_vm._v("应时而生")]),_vm._v("大数据助力解锁薪职场 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"title"},[_vm._v("好前景")]),_c('p',{staticClass:"p"},[_vm._v("万亿市场+百万人才缺口")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"title"},[_vm._v("好发展")]),_c('p',{staticClass:"p"},[_vm._v("应用广，行业无限制")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"title"},[_vm._v("好就业")]),_c('p',{staticClass:"p"},[_vm._v("各企大厂都需要")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"title"},[_vm._v("好薪资")]),_c('p',{staticClass:"p"},[_vm._v("平均月薪19300元/月")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-1"},[_vm._v(" 近年来随着互联网和智能硬件的快速发展，数据呈爆炸式增长，国家数字建设、企业产业转型级、企业上云用云等，人才缺口越来越大，未来发展前景可见一般。"),_c('br'),_vm._v(" 据工V部和信息化部发布的《 “十四五” 大数据产业发展规划》指出，2025年我国大数据产业测算规模突破3万亿元。中国商业联合会数据分析专业委员会统计，未来中国基础性数据分析人才缺口将达到1400万。截止到2022年8月11日，仅职友及相关岗位招聘111720个 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-2"},[_c('div',{staticClass:"text-b"},[_c('p',{staticClass:"text-red"},[_c('span',{staticClass:"text-big"},[_vm._v(" 30000")]),_vm._v("亿万")]),_c('p',[_vm._v("年产业规模突破")])]),_c('div',{staticClass:"text-b"},[_c('p',{staticClass:"text-red"},[_c('span',{staticClass:"text-big"},[_vm._v(" 14000")]),_vm._v("万")]),_c('p',[_vm._v("国内基础数据人才缺口")])]),_c('div',{staticClass:"text-b"},[_c('p',{staticClass:"text-red"},[_c('span',{staticClass:"text-big"},[_vm._v(" 111720")]),_vm._v("个")]),_c('p',[_vm._v("职友及相关职位日招聘")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-3"},[_c('p',[_vm._v("此处相关数据来源分别为《 “ 十四五” 大数据产业发展规划》；")]),_c('p',[_vm._v(" 中国商业联合会数据分析专业委员会统计；职友及样本选取日期2022年8月11日 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-img"},[_c('img',{attrs:{"src":require("@/assets/img/classes/bigdata/21_03.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list"},[_c('ul',[_c('li',[_c('div',{staticClass:"content"},[_c('p',[_vm._v("数据开发工程师")]),_c('p',{staticStyle:{"color":"#ff5143"}},[_c('span',{staticStyle:{"font-size":"38px"}},[_vm._v(" 24-30K")]),_vm._v("/月 ")]),_c('p',[_vm._v("互联网大厂")])])]),_c('li',[_c('div',{staticClass:"content"},[_c('p',[_vm._v("数据开发工程师")]),_c('p',{staticStyle:{"color":"#ff5143"}},[_c('span',{staticStyle:{"font-size":"38px"}},[_vm._v(" 24-30K")]),_vm._v("/月 ")]),_c('p',[_vm._v("互联网大厂")])])]),_c('li',[_c('div',{staticClass:"content"},[_c('p',[_vm._v("数据开发工程师")]),_c('p',{staticStyle:{"color":"#ff5143"}},[_c('span',{staticStyle:{"font-size":"38px"}},[_vm._v(" 24-30K")]),_vm._v("/月 ")]),_c('p',[_vm._v("互联网大厂")])])]),_c('li',[_c('div',{staticClass:"content"},[_c('p',[_vm._v("数据开发工程师")]),_c('p',{staticStyle:{"color":"#ff5143"}},[_c('span',{staticStyle:{"font-size":"38px"}},[_vm._v(" 24-30K")]),_vm._v("/月 ")]),_c('p',[_vm._v("互联网大厂")])])]),_c('li',[_c('div',{staticClass:"content"},[_c('p',[_vm._v("数据开发工程师")]),_c('p',{staticStyle:{"color":"#ff5143"}},[_c('span',{staticStyle:{"font-size":"38px"}},[_vm._v(" 24-30K")]),_vm._v("/月 ")]),_c('p',[_vm._v("互联网大厂")])])]),_c('li',[_c('div',{staticClass:"content"},[_c('p',[_vm._v("数据开发工程师")]),_c('p',{staticStyle:{"color":"#ff5143"}},[_c('span',{staticStyle:{"font-size":"38px"}},[_vm._v(" 24-30K")]),_vm._v("/月 ")]),_c('p',[_vm._v("互联网大厂")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-3"},[_c('p',[_vm._v(" 数据来源于boss直聘，数据样本选取日期为2022年8月17日,仅作为信息展示,不作为效果承诺 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-title"},[_c('div',[_vm._v("岗位平均薪资19.3K/月")]),_c('div',[_vm._v("2016-2021年工资变化")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("@/assets/img/classes/bigdata/4_03.jpg"),"alt":"","width":"100%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-3"},[_c('p',[_vm._v(" 数据来源于职友集,数据样本选取日期为2022年8月11日,仅作为信息展示，不作为效果承诺 ")])])
}]

export { render, staticRenderFns }