import { render, staticRenderFns } from "./suitable.vue?vue&type=template&id=53ed52c1&scoped=true"
import script from "./suitable.vue?vue&type=script&lang=js"
export * from "./suitable.vue?vue&type=script&lang=js"
import style0 from "./suitable.vue?vue&type=style&index=0&id=53ed52c1&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.11.0_vue-template-compiler@2.7.16_webpack@5.93.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53ed52c1",
  null
  
)

export default component.exports