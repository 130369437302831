<template>
    <div class="about-page">
        <div class="page-container">
            <!-- 页头 -->
            <pageHeader />
            <!-- 头部导航 -->

            <Head>
                <template>
                    <li class="home-nav-li">
                        <a href="javascript:">培训课程</a>
                        <div class="course-list">
                            <div>
                                <div class="item">
                                    <a @click.prevent="$router.push('/java')">Java开发培训</a>
                                </div>
                                <div class="item">
                                    <a href="#" @click.prevent="$router.push('/ui')">UI/UE设计培训</a>
                                </div>
                                <div class="item">
                                    <a href="" @click.prevent="$router.push('/web')"
                                        >大前端（H5+小程序+APP）
                                    </a>
                                </div>
                                <div class="item">
                                    <a href="#" @click.prevent="$router.push('/bigdata')">大数据培训</a>
                                </div>
                                <div class="item"><a href="">Python+人工智能</a></div>
                                <div class="item"><a href="">PHP开发设计</a></div>
                                <div class="item"><a href="">云计算工程师培训</a></div>
                                <div class="item"><a href="">C/安卓开发工程师培训</a></div>
                                <div class="item"><a href="">短视频运营+剪辑</a></div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <a href="#" @click.prevent="$router.push('/')">首页</a>
                    </li>
                    <!-- <li><a href="">教学管理</a></li> -->
                    <li>
                        <a href="#" @click.prevent="$router.push('/teacher')">师资力量</a>
                    </li>
                    <li>
                        <a href="#" @click.prevent="$router.push('/student')">就业保障</a>
                    </li>
                    <li>
                        <a href="#" @click.prevent="$router.push('/')">教学环境</a>
                    </li>
                    <li>
                        <a href="#" @click.prevent="$router.push('/')">项目实战</a>
                    </li>
                </template>
            </Head>
            <!-- 背景 -->
            <div class="banner"></div>
            <main class="clear">
                <!-- 关于我们 -->
                <About />
                <!-- 精耕细作 -->
                <History />
            </main>
            <Honor />
            <footerNav></footerNav>
            <Footer />
            <sidebar />
        </div>
    </div>
</template>

<script>
import pageHeader from '@/components/pageHeader.vue'
import Head from '@/components/head.vue'
import About from '../Layout/components/about.vue'
import Footer from '@/components/footer.vue'
import Sidebar from '@/components/sidebar.vue'
import Honor from './components/Certificate-Honors.vue'
import footerNav from '@/components/footerNav.vue'
import History from './components/history.vue'
export default {
    data() {
        return {
            isShow: false
        }
    },
    components: {
        pageHeader,
        Head,
        About,
        Footer,
        Sidebar,
        footerNav,
        Honor,
        History
    },
    mounted() {
    	document.title = '合肥一元数智教育有限公司'
    },
    methods: {
        enter() {
            this.isShow = true
        },
        leave() {
            this.isShow = false
        }
    }
}
</script>
<style scoped lang="less">
.item:hover {
    background-color: #313131;
}

.home-nav-li {
    background-color: #d72502;
}
.course-list {
    display: none;
}
.home-nav-li:hover .course-list {
    display: block;
}
.banner {
    width: 100%;
    height: 600px;
    background: url(../../assets/img/about/bannerabout.jpg) no-repeat center;
}
</style>
