// author: 张世龙
<template>
    <div>
        <div class="employment">
            <span class="employment-1">名企直招</span>
            <span class="employment-2">高薪就业</span>
        </div>

        <div class="section-content">
            <ul>
                <li v-for="item in companysList" :key="item.id">
                    <img :src="item.img" align="middle" />
                    <div class="text">
                        <div>{{ item.remark }}</div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="environment-img">
            <ul class="environment">
                <li
                    style=" cursor: pointer;"
                    v-for="item in list"
                    :key="item.id"
                    @mouseover="change(item)"
                    :class="item.id == selected ? 'selected' : ''"
                >
                    {{ item.name }}
                </li>
            </ul>
            <div class="environment-study-img">
                <img
                    :src="item.img"
                    alt=""
                    :title="item.place"
                    v-for="item in viewList"
                    :key="item.envId"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { getCompanys, getEnvironmentList } from '@/api/home'
export default {
    data() {
        return {
            list: [
                {
                    id: 0,
                    name: '学习环境'
                },
                {
                    id: 1,
                    name: '园区环境'
                }
            ],
            selected: 0,
            companysList: [],
            envList: []
        }
    },
    computed: {
        viewList() {
            return this.envList.filter(item => item.id == this.selected)
        }
    },
    created() {
        this.getCompanys()
        this.getEnvironmentList()
    },
    methods: {
        change(item) {
            this.selected = item.id
        },
        async getCompanys() {
            const res = await getCompanys()
            this.companysList = res.reverse()
        },
        async getEnvironmentList() {
            this.envList = await getEnvironmentList()
            console.log(this.envList)
        }
    }
}
</script>

<style scoped lang="scss">
.employment {
    font-size: 36px;
    text-align: center;
    font-family: PingFangSC;
    margin: 60px 0;
}

.employment-1 {
    font-weight: 600;
    color: #100bca;
}

.employment-2 {
    font-weight: 400;
    color: #333333;
}

.environment {
    text-align: center;
    display: flex;
    justify-content: space-evenly;
    font-size: 25px;
    margin: 80px 0 40px;
    color: rgb(106, 116, 255);
}

.environment li {
    line-height: 40px;
    width: 200px;
    // transition: all 0.3s;
}

.employment-img,
.environment-study-img,
.environment-park-img {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    // overflow: hidden;

    img {
        margin-top: 5px;
        transition: all 0.3s;
    }
    img:hover {
        transform: scale(1.05);
    }
}
.section-content {
    width: 100%;
    margin: 31px 0 0;
    ul {
        display: flex;

        justify-content: flex-start;
        align-content: space-between;
        flex-flow: wrap;
        li {
            position: relative;
            width: 375px;
            height: 187px;
            margin: 0 29px 20px 0;
            box-shadow: 4px 7px 5px #ccc;
            overflow: hidden;
            cursor: pointer;
            img {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 100%;

                transform: translate(-50%, -50%);
            }
            .text {
                position: absolute;
                width: 100%;
                height: 40px;
                line-height: 40px;
                bottom: -40px;
                transition: all 0.5s;
                padding: 0 10px;
                color: #fff;
                font-size: 20px;

                text-align: center;
                div {
                    width: 100%;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
            }
        }
        li:nth-child(3n) {
            margin-right: 0;
        }
        li:hover {
            transform: scale(105%);
            transition: all 0.2s;
        }
        li:hover .text {
            bottom: 0;
            background: rgba(0, 0, 0, 0.7);
        }
    }
}
.employment-img {
    img {
        width: 366px;
        height: 235px;
    }
    img:nth-child(2n) {
        width: 226px;
    }
}

.environment-study-img img {
    margin: 5px 0 0;
    width: 385px;
    height: 268px;
    cursor: pointer;
}

.selected {
    width: 200px;
    border: 1px solid;
    border-radius: 20px;
    line-height: 40px;
    background-color: rgb(72, 90, 255);
    color: white;
}
</style>
