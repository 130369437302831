<template>
    <div>
        <div class="teacher-tito">
            <h1>北大青鸟师资团队</h1>
            <h2>
                提供全方位教学服务的师资团队私人定制您的IT梦想，中心教员、中心班主任、职业规划师从入学到毕业
                提供全方位教学服务，让学习过程更轻松， 让学习效果更明显，私人定制每个学员的IT梦想
            </h2>
        </div>
        <div class="teacer-body">
            <ul class="teacer-body-ul">
                <li class="teacer-body-ul-li" v-for="(item, index) in list" :key="item.id">
                    <div :class="index % 2 == 0 ? 'teacer-body-li-divr' : 'teacer-body-li-divl'">
                        <h3>{{ item.posts }}-{{ item.name }}</h3>
                        <strong class="text">个人简介：</strong>
                        <div class="text" v-html="item.biography"></div>
                        <template v-if="item.technology">
                            <strong>教学实战项目：</strong>
                            <div v-html="item.technology"></div>
                        </template>
                        <template v-else>
                            <strong>学生评价：</strong>
                            <div v-html="item.skill"></div>
                        </template>
                    </div>
                    <img :src="item.img" alt="" />
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import { getTeam } from '@/api/home'
import { getTeacher } from '@/api/class'
export default {
    data() {
        return {
            list: []
        }
    },
    async created() {
        this.list = await getTeam()
        console.log(this.list)
    }
}
</script>

<style scoped>
.teacher-tito {
    margin-top: 60px;
}
.teacher-tito h1 {
    font-size: 40px;
    text-align: center;
    color: rgb(42, 34, 220);
}
.teacher-tito h2 {
    font-size: 18px;
    text-align: center;
    color: rgb(102, 102, 102);
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 30px;
}
.teacer-body img {
    width: 330px;
    height: 425px;
    margin: 0 5px;
}
.teacer-body-ul {
    margin-left: 10%;
    margin-right: 10%;
    width: 1200px;
}
.teacer-body-li-divr {
    float: right;
    width: 859px;
    height: 364px;
    border: 1px solid #003b90;
    border-right-width: 6px;
    margin-top: 60px;
    padding: 30px;
    box-sizing: border-box;
}
.teacer-body-li-divl {
    float: left;
    width: 859px;
    height: 364px;
    border: 1px solid #003b90;
    border-left-width: 6px;
    margin-top: 60px;
    /* margin-left: 5px; */
    padding: 30px;
    box-sizing: border-box;
}
.teacer-body-ul-li {
    margin-top: 70px;
    line-height: 25px;
    font-size: 14px;
    color: #666666;
}
h3 {
    font-size: 20px !important;
    color: #666666;
    letter-spacing: 1px;
}
.teacer-body-ul-li h3 {
    margin-bottom: 20px;
}
strong {
    font-size: 15px !important;
    color: #000 !important;
}
.text {
    font-size: 14px;
    color: #666666;
    line-height: 22px;
    width: 750px;
}
</style>
