<template>
    <div class="section">
        <div class="section-title">为什么要学习Java?</div>
        <div class="section-content">
            <div class="left">
                <div class="title-text">编程语言中的王者——Java</div>
                <div class="content">
                    <img src="@/assets/img/classes/java/img-2.jpg" alt="" />
                    <ul>
                        <li>
                            服务器编程语言<br />
                            占比超91%
                        </li>
                        <li>
                            市场就业领域<br />
                            覆盖面广
                        </li>
                        <li>
                            企业用人需求每周<br />
                            新增10万+
                        </li>
                    </ul>
                </div>
            </div>
            <div class="right">
                <div class="title-text">Java语言随着网络的发展已经在各个行业得到运用</div>
                <img src="@/assets/img/classes/java/img-1.jpg" alt="" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {}
    },
    components: {},
    mounted() {},
    methods: {}
}
</script>
<style scoped lang="scss">
.section {
    // height: 435px;
    background-color: #fafbff;
    padding: 20px 30px 20px 15px;
    margin-top: 50px;
    border-radius: 10px;
    .section-title {
        font-size: 24px;
    }
    .section-content {
        display: flex;
        justify-content: space-between;
        // height: 100%;
        padding-top: 30px;
        .left {
            width: 280px;
            // height: 100%;
            padding: 40px 10px 0;
            border: 1px solid #dae0f0;
            border-radius: 10px;
            box-sizing: border-box;
            .title-text {
                font-size: 18px;
            }
            .content {
                display: flex;
                padding: 70px 30px;
                img {
                    height: 180px;
                }
                ul {
                    li {
                        margin: 0px 10px 20px;
                        line-height: 1.5em;
                    }
                }
            }
        }
        .right {
            width: 850px;
            // height: 100%;
            border: 1px solid #dae0f0;
            border-radius: 10px;
            padding: 40px 10px 0;
            box-sizing: border-box;
            .title-text {
                font-size: 18px;
            }
            img {
                margin-top: 30px;
                width: 100%;
            }
        }
    }
}
</style>
