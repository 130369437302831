import axios from 'axios'
import { Message } from 'element-ui'
const service = axios.create({
    // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
    baseURL: process.env.VUE_APP_BASE_API,
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 50000 // request timeout
})

service.interceptors.response.use(
    response => {
        let { code, message, data } = response.data
        if (code == 200) {
            if (Object.prototype.toString.call(data) === '[object Array]') {
                data = data.map(item => {
                    if (item.img) {
                        item.img = 'http://106.14.74.21:8989/kgc/picture/' + item.img

                    }
                    return item
                })
            }
            return data
        } else {
            Message({
                type: 'error',
                message
            })
            return Promise.reject(new Error(message))
        }
    }

)

export default service