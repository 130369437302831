<template>
    <div class="section">
        <div class="section-title">
            下一个
            <span style="color: red">就业明星</span>
            就是你
        </div>
        <div class="section-content">
            <div class="left">
                <ul>
                    <li v-for="(item, index) in module" :key="index" class="hover">
                        <div>{{ item.title }}</div>
                        <div>
                            <span>{{ item.num }}</span
                            >{{ item.unit }}
                        </div>
                    </li>
                </ul>
            </div>
            <div class="right">
                <div class="table">
                    <div class="thead">
                        <div class="tr">
                            <span>姓名</span>
                            <span>就业薪资</span>
                            <span>入职企业</span>
                            <span>学历</span>
                        </div>
                    </div>
                    <div class="tbody">
                        <div class="tr" v-for="item in list" :key="item.id">
                            <span>
                                {{ item.name[0] }}
                            </span>
                            <span>
                                {{ item.money }}
                            </span>
                            <span>
                                {{ item.city }}
                            </span>
                            <span>
                                {{ item.degree }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        list: {
            default() {
                return [
                    {
                        id: 1,
                        name: '姜*',
                        treatment: '13500',
                        enterprise: '吉*克信息',
                        degree: '专科'
                    },
                    {
                        id: 2,
                        name: '赵*',
                        treatment: '13500',
                        enterprise: '吉*克信息',
                        degree: '专科'
                    },
                    {
                        id: 3,
                        name: '孙**',
                        treatment: '13500',
                        enterprise: '吉*克信息',
                        degree: '专科'
                    },
                    {
                        id: 4,
                        name: '王*',
                        treatment: '13500',
                        enterprise: '吉*克信息',
                        degree: '专科'
                    },
                    {
                        id: 5,
                        name: '孙*',
                        treatment: '13500',
                        enterprise: '吉*克信息',
                        degree: '专科'
                    },
                    {
                        id: 6,
                        name: '赵*',
                        treatment: '13500',
                        enterprise: '吉*克信息',
                        degree: '专科'
                    },
                    {
                        id: 7,
                        name: '李**',
                        treatment: '13500',
                        enterprise: '吉*克信息',
                        degree: '专科'
                    },
                    {
                        id: 8,
                        name: '孙*',
                        treatment: '13500',
                        enterprise: '吉*克信息',
                        degree: '专科'
                    },
                    {
                        id: 9,
                        name: '赵*',
                        treatment: '13500',
                        enterprise: '吉*克信息',
                        degree: '专科'
                    },
                    {
                        id: 10,
                        name: '李**',
                        treatment: '13500',
                        enterprise: '吉*克信息',
                        degree: '专科'
                    }
                ]
            }
        },
        module: {
            default() {
                return [
                    {
                        id: 2,
                        title: '就业学员',
                        num: 5457,
                        unit: '人'
                    }
                ]
            }
        }
    },
    data() {
        return {}
    },
    components: {},
    mounted() {},
    methods: {}
}
</script>
<style scoped lang="scss">
.section {
    margin-top: 60px;

    .section-title {
        font-size: 29px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 35px;
    }

    .section-content {
        display: flex;
    }

    .left {
        width: 345px;
        height: 540px;

        li {
            display: flex;
            justify-content: space-between;
            width: 345px;
            height: 122px;
            margin: 0 35px 20px 0;
            background-size: contain;
            border-radius: 8px;
            font-family: '黑体';
            color: #fff;
            font-size: 19px;
            padding: 15px;
            cursor: pointer;
            box-sizing: border-box;
            div:first-child {
                width: 78px;
                font-weight: 700;
            }
            div:last-child {
                span {
                    font-size: 70px;
                    font-weight: 700;
                }
            }
        }

        li:nth-child(1) {
            background: url(@/assets/img/classes/web-img/bgBox-3.jpg);
        }

        li:nth-child(2) {
            background: url(@/assets/img/classes/web-img/bgBox-4.jpg);
        }

        li:nth-child(3) {
            background: url(@/assets/img/classes/web-img/bgBox-5.jpg);
        }

        li:nth-child(4) {
            background: url(@/assets/img/classes/web-img/bgBox-6.jpg);
        }
        .hover {
            transition: all 0.3s;
        }
        li:hover {
            box-shadow: 0px 0px 10px #00000091;
        }
    }

    .right {
        .table {
            height: 100%;
            width: 820px;
            text-align: center;
            background-color: #fff;
            border-radius: 10px;
            margin-left: 40px;
            border-collapse: separate;
            border-spacing: 0;
            box-shadow: 5px 5px 10px #666;
            .tbody {
                height: 415px;
                overflow-y: scroll;
            }
            .tbody::-webkit-scrollbar-thumb {
                background-color: #4666ff;
            }
            .tbody::-webkit-scrollbar-track {
                background-color: #fff;
            }
            .tr {
                display: flex;
                justify-content: space-around;
                line-height: 57px;

                border-bottom: 2px solid #666;
                span {
                    white-space: nowrap; // 文字强制不换行
                    text-overflow: ellipsis; // 文字溢出换省略号
                    overflow: hidden; // 溢出文字隐藏
                }
            }

            span {
                display: inline-block;
                flex: 1;
                cursor: pointer;
            }

            .thead {
                transform: translateY(-10px);
                margin-bottom: 30px;

                .tr {
                    width: 100%;
                    background-color: #4666ff;
                    border-radius: 16px !important;
                    border: none;
                }

                span:not(span:last-child) {
                    border-right: 2px solid #fff;
                }

                span {
                    font-size: 24px;
                    color: #fff;
                }
            }
        }
    }
}
</style>
