<template>
    <div class="section">
        <div class="section-title">职业发展路径</div>
        <div class="section-content">
            <div class="left">
                <div class="top">
                    <div>
                        <span>学员起点</span><br />
                        <img
                            src="@/assets/img/classes/java/arrow-down.jpg"
                            alt=""
                        />
                    </div>
                </div>
                <div class="middle">
                    <div class="stage-1">零基础学员</div>
                    <img
                        src="@/assets/img/classes/java/arrow-left.png"
                        alt=""
                        class="icon-1"
                    />
                    <div class="stage-2">
                        <img
                            src="@/assets/img/classes/java/img-3.png"
                            alt=""
                        />
                    </div>
                    <img
                        src="@/assets/img/classes/java/arrow-left.png"
                        alt=""
                        class="icon-2"
                    />
                </div>
            </div>
            <div class="right">
                <div class="content">
                    <div class="technique">
                        <div class="direction">技术方向</div>
                        <div class="t-1">资深开发工程师</div>
                        <div class="t-2">技术专员</div>
                        <div class="t-3">CTO</div>
                    </div>
                    <div class="manage">
                        <div class="direction">管理方向</div>
                        <div class="t-1">技术开发组长</div>
                        <div class="t-2">项目经理</div>
                        <div class="t-3">项目总监</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {}
    },
    props: {},
    components: {},
    mounted() {},
    methods: {},
}
</script>
<style scoped lang="scss">
.section {
    margin-top: 60px;
    padding-bottom: 20px;
    .section-title {
        font-size: 23px;
        margin-bottom: 30px;
    }

    .section-content {
        display: flex;

        .left {
            width: 40%;

            .top {
                display: flex;
                justify-content: flex-end;
                div {
                    display: inline-block;
                    margin-right: 140px;
                    text-align: center;
                }
            }
            .middle {
                height: 130px;

                position: relative;
                .stage-1 {
                    width: 165px;
                    height: 50px;
                    line-height: 50px;
                    text-align: center;
                    background-color: #e6f4ff;
                }
                .stage-2 {
                    height: 115px;
                    width: 165px;
                    left: 220px;
                    box-shadow: 0px 0px 30px #2772fea4;
                    img {
                        width: 100%;
                        height: 105%;
                    }
                }

                .stage-1,
                .icon-1,
                .icon-2,
                .stage-2 {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                }
                .icon-1 {
                    left: 185px;
                }
                .icon-2 {
                    left: 400px;
                }
            }
        }
        .right {
            width: 60%;

            .content {
                position: relative;
                top: 35px;
                left: -20px;
                .technique,
                .manage {
                    display: flex;
                    height: 45px;
                    margin: 10px;
                    line-height: 45px;
                    border-radius: 5px;
                    text-align: center;
                }

                .direction {
                    position: relative;
                    width: 165px;
                    height: 45px;
                    line-height: 45px;
                    text-align: center;
                    color: #fff;
                }

                .direction::after {
                    position: absolute;
                    content: "";
                    height: 18px;
                    width: 9px;
                    right: -9px;
                    top: 50%;
                    transform: translateY(-50%);
                }
                .t-1 {
                    position: relative;
                    padding-left: 20px;
                    // display: inline-block;
                    width: 210px;
                }
                .t-2 {
                    position: relative;
                    // display: inline-block;
                    width: 140px;
                }
                .t-3 {
                    width: 118px;
                }
                .t-1::after,
                .t-2::after {
                    position: absolute;
                    content: "";
                    width: 10px;
                    height: 17px;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    background: url(@/assets/img/classes/java/arrow-left.png) no-repeat;
                    background-size: cover;
                }
                .technique {
                    background: #e6f4ff;
                    .direction {
                        background: #528efe;
                    }
                    .direction::after {
                        background: url(@/assets/img/classes/java/left-db.png) no-repeat;
                        background-size: cover;
                    }
                }
                .manage {
                    background: #d9f7ff;
                    .direction {
                        background: #22b6fc;
                    }
                    .direction::after {
                        background: url(@/assets/img/classes/java/left-b.png) no-repeat;
                        background-size: cover;
                    }
                }
            }
        }
    }
}
</style>
