<template>
    <div class="about-history">
        <div class="line"></div>
        <div class="line-color"></div>
        <div class="">
            <h2 class="about_b2_t"><img src="//img.bdqnhf.com/images/about_b2_t.png" width="371px" height="131px"></h2>
            <p class="about_txt">1999年，北大青鸟APTECH成立。十余年披荆斩棘，锐意进取；十余年硕果累累，成就骄人。</p>
        </div>
        <div class="container">
            <ul class="dot">
                <li class="item-1" data-text="2018年">
                    <div class="ab_his_img" style="z-index:9999;display:block"><img src="//img.bdqnhf.com/images/2018.png">
                    </div>
                </li>
                <li class="item-2" data-text="2017年">
                    <div class="ab_his_img"><img src="//img.bdqnhf.com/images/about2017.png"></div>
                </li>
                <li class="item-3" data-text="2016年">
                    <div class="ab_his_img"><img src="//img.bdqnhf.com/images/about2016.png"></div>
                </li>
                <li class="item-4" data-text="2014年">
                    <div class="ab_his_img"><img src="//img.bdqnhf.com/images/about2014.png"></div>
                </li>
                <li class="item-5" data-text="2013年">
                    <div class="ab_his_img" style="z-index:9999"><img src="//img.bdqnhf.com/images/about2013.png"></div>
                </li>
                <li class="item-6" data-text="2010年">
                    <div class="ab_his_img"><img src="//img.bdqnhf.com/images/about2010.png"></div>
                </li>
                <li class="item-7" data-text="2008年">
                    <div class="ab_his_img" style="display:block"><img src="//img.bdqnhf.com/images/about2008.png"></div>
                </li>
                <li class="item-8" data-text="2005年">
                    <div class="ab_his_img"><img src="//img.bdqnhf.com/images/about2005.png"></div>
                </li>
                <li class="item-9" data-text="2004年">
                    <div class="ab_his_img" style="z-index:9999"><img src="//img.bdqnhf.com/images/about2004.png"></div>
                </li>
                <li class="item-10" data-text="2003年">
                    <div class="ab_his_img" style="z-index:9999"><img src="//img.bdqnhf.com/images/about2003.png"></div>
                </li>
                <li class="item-11" data-text="2001年">
                    <div class="ab_his_img"><img src="//img.bdqnhf.com/images/about2001.png"></div>
                </li>
                <li class="item-12" data-text="2000年">
                    <div class="ab_his_img"><img src="//img.bdqnhf.com/images/about2000.png"></div>
                </li>
                <li class="item-13" data-text="1999年">
                    <div class="ab_his_img"><img src="//img.bdqnhf.com/images/about1999.png"></div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.about_txt {
    font-size: 14px;
    color: #a8a8a8;
    text-align: center;
    margin-top: 20px;
}

.about_b2_t {
    width: 371px;
    height: 131px;
    margin: auto;
}

.about-history {
    width: 1062px;
    height: 1740px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
}

/* .about-history .line{position: absolute;top: 132px;left: 0;width: 100%;height: 1618px;background: url(../images/about_us/his_line.png) no-repeat center top;} */
.about-history .line-color {
    position: absolute;
    top: 132px;
    left: 0;
    width: 100%;
    height: 1618px;
    background: url(../../../assets/img/about/his_line_red.png) no-repeat center top;
}

.about-history .container {
    height: 1618px;
    -webkit-user-select: none;
    user-select: none;
}

.about-history .dot {
    position: absolute;
    top: 300px;
    left: 0;
    width: 100%;
    height: 100%;
}

.about-history .dot li {
    position: absolute;
    width: 18px;
    height: 18px;
    background: #666;
    border-radius: 50%;
    cursor: pointer;
}

.about-history .dot li:after {
    position: absolute;
    top: -1px;
    left: 30px;
    content: attr(data-text);
    width: 100px;
    line-height: 20px;
    font-size: 20px;
    color: #333;
}

/* .about-history .dot li.active{background: #d6010f;} */
.about-history .dot li .ab_his_img {
    width: 705px;
    height: 155px;
    position: absolute;
    top: -50px;
    left: 100px;
    display: none;
}

.about-history .dot li:nth-child(3) .ab_his_img {
    left: -733px;
}

.about-history .dot li:nth-child(4) .ab_his_img {
    left: -733px;
}

.about-history .dot li:nth-child(5) .ab_his_img {
    top: 36px;
    left: -253px;
}

.about-history .dot li:nth-child(10) .ab_his_img {
    top: 36px;
    left: -253px;
}

.about-history .dot li:nth-child(11) .ab_his_img {
    top: 36px;
    left: -380px;
}

.about-history .dot li:nth-child(12) .ab_his_img {
    left: -733px;
}

.about-history .dot li:nth-child(13) .ab_his_img {
    top: -130px;
    left: -733px;
}

.about-history .dot li:hover {
    width: 30px;
    height: 30px;
    margin: -6px 0 0 -6px;
    background: #d6010f;
}

.about-history .dot li:hover .ab_his_img {
    display: block;
}

/* .about-history .dot li.current:after{top: 5px;left: 40px;} */
.about-history .dot li.item-1 {
    top: 33px;
    left: 14px;
}

.about-history .dot li.item-2 {
    top: 198px;
    left: 270px;
}

.about-history .dot li.item-3 {
    top: 335px;
    left: 745px;
}

.about-history .dot li.item-4 {
    top: 570px;
    left: 850px;
}

.about-history .dot li.item-5 {
    top: 640px;
    left: 539px;
}

.about-history .dot li.item-6 {
    top: 723px;
    left: 237px;
}

.about-history .dot li.item-7 {
    top: 877px;
    left: 59px;
}

.about-history .dot li.item-8 {
    top: 1000px;
    left: 80px;
}

.about-history .dot li.item-9 {
    top: 1104px;
    left: 234px;
}

.about-history .dot li.item-10 {
    top: 1141px;
    left: 440px;
}

.about-history .dot li.item-11 {
    top: 1157px;
    left: 650px;
}

.about-history .dot li.item-12 {
    top: 1180px;
    left: 880px;
}

.about-history .dot li.item-13 {
    top: 1310px;
    left: 950px;
}</style>