<template>
    <div class="container">
        <router-view></router-view>
        <div class="goTop" ref="goTop" @click="toTop">
            <img src="@/assets/img/icon/goTop.png" alt="" />
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {}
    },
    components: {},
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('scroll', () => {
                if (window.scrollY > 400) {
                    this.$refs.goTop.style.opacity = '100%'
                } else {
                    this.$refs.goTop.style.opacity = '0'
                }
            })
        })
    },
    methods: {
        toTop() {
            const target = document.querySelector(`.container`).offsetTop
            window.scrollTo({ top: target, behavior: 'smooth' })
        }
    }
}
</script>
<style scoped lang="scss">
.goTop {
    position: fixed;
    cursor: pointer;
    bottom: 110px;
    right: 10px;
    opacity: 0;
    transition: all 0.3s;
}

.goTop:hover {
    -webkit-animation: bounce 1s infinite;
    animation: bounce 1s infinite;
}

@keyframes bounce {

    0%,
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }

    50% {
        -webkit-transform: translateY(-25%);
        transform: translateY(-25%);
        -webkit-animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
        animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
}

@-webkit-keyframes bounce {

    0%,
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }

    50% {
        -webkit-transform: translateY(-25%);
        transform: translateY(-25%);
        -webkit-animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
        animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
}
</style>
