<template>
    <div class="Layout-page">
        <div class="page-container">
            <!-- 页头 -->
            <PageHeader />
            <!-- 头部 -->

            <Head :nav="false"></Head>
            <Nav>
                <template>
                    <li class="home-nav-li">
                        <a href="javascript:">培训课程</a>
                        <div class="course-list">
                            <div>
                                <div class="item">
                                    <a @click.prevent="$router.push('/java')">Java开发培训</a>
                                </div>
                                <div class="item">
                                    <a href="#" @click.prevent="$router.push('/ui')">UI/UE设计培训</a>
                                </div>
                                <div class="item">
                                    <a href="" @click.prevent="$router.push('/web')">大前端（H5+小程序+APP）
                                    </a>
                                </div>
                                <div class="item">
                                    <a href="#" @click.prevent="$router.push('/bigdata')">大数据培训</a>
                                </div>
                                <div class="item"><a href="">Python+人工智能</a></div>
                                <div class="item"><a href="">PHP开发设计</a></div>
                                <div class="item"><a href="">云计算工程师培训</a></div>
                                <div class="item"><a href="">C/安卓开发工程师培训</a></div>
                                <div class="item"><a href="">短视频运营+剪辑</a></div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <a href="#" @click.prevent="$router.push('/')">首页</a>
                    </li>
                    <!-- <li><a href="">教学管理</a></li> -->
                    <li>
                        <a href="#" @click.prevent="$router.push('/teacher')">师资力量</a>
                    </li>
                    <li>
                        <a href="#" @click.prevent="$router.push('/student')">就业保障</a>
                    </li>
                    <li>
                        <a href="#" @click.prevent="goTarget('environment')">教学环境</a>
                    </li>
                    <li>
                        <a href="#" @click.prevent="goTarget('subject')">项目实战</a>
                    </li>
                </template>
            </Nav>

            <!-- 轮播图 -->
            <!-- <keep-alive :include="'Banner'"> -->
            <Banner>
                <el-carousel-item v-for="item in banner" :key="item.id">
                    <img :src="item.img" alt="" height="485" />
                </el-carousel-item>
            </Banner>
            <!-- </keep-alive> -->

            <main class="clear">
                <!-- 关于我们 -->
                <About />
                <!-- 课程中心 -->
                <CourseCenter />
                <!-- 最新开课时间 -->
                <LatestClassStart />
                <!-- <keep-alive :include="['DisplaySubject', 'Teams', 'EmploymentInformation', 'CooperativeCompanies', 'CooperativeCollege', 'SmallModules', 'Environment']"> -->
                <!-- 学员项目展示 -->
                <DisplaySubject id="subject" />
                <!-- 团队展示 -->
                <Teams />
                <!-- 就业信息 -->
                <EmploymentInformation id="emp" />
                <!-- 合作企业 -->
                <CooperativeCompanies />
                <!-- 合作高校 -->
                <CooperativeCollege />
                <!-- 媒体报道 就业发展 -->
                <SmallModules />
                <!-- 教学环境 -->
                <Environment id="environment" />
                <!-- </keep-alive> -->
            </main>
            <!-- <keep-alive :include="'Honor'"> -->
            <!-- 企业荣誉 -->
            <Honor />
            <!-- </keep-alive> -->
            <Footer />
            <Sidebar />
        </div>
    </div>
</template>

<script>
// import PageHeader from '@/components/pageHeader.vue'
// import Head from '@/components/head.vue'
// import Banner from '../../components/banner.vue'
// import About from './components/about.vue'
// import CourseCenter from './components/course-center.vue'
// import LatestClassStart from './components/latest-class-start.vue'
// import DisplaySubject from './components/display-subject.vue'
// import Teams from './components/teams.vue'
// import EmploymentInformation from './components/employment-information.vue'
// import CooperativeCompanies from './components/cooperative-companies.vue'
// import CooperativeCollege from './components/cooperative-college.vue'
// import SmallModules from './components/small-modules.vue'
// import Environment from './components/environment.vue'
// import Honor from './components/honor.vue'
// import Footer from '@/components/footer.vue'
// import Nav from '@/components/nav.vue'
// import Sidebar from '@/components/sidebar.vue'
import { getBanner } from '@/api/home'
  
export default {
    name: 'Layout',
    data() {
        return {
            banner: []
        }
    },
    components: {
        PageHeader: () => import("@/components/pageHeader.vue"),
        Head: () => import("@/components/head.vue"),
        Nav: () => import("@/components/nav.vue"),
        Banner: () => import("../../components/banner.vue"),
        About: () => import("./components/about.vue"),
        CourseCenter: () => import("./components/course-center.vue"),
        LatestClassStart: () => import("./components/latest-class-start.vue"),
        DisplaySubject: () => import("./components/display-subject.vue"),
        Teams: () => import("./components/teams.vue"),
        EmploymentInformation: () => import("./components/employment-information.vue"),
        CooperativeCompanies: () => import("./components/cooperative-companies.vue"),
        CooperativeCollege: () => import("./components/cooperative-college.vue"),
        SmallModules: () => import("./components/small-modules.vue"),
        Environment: () => import("./components/environment.vue"),
        Honor: () => import("./components/honor.vue"),
        Footer: () => import("@/components/footer.vue"),
        Sidebar: () => import("@/components/sidebar.vue")
    },
    mounted() {
		document.title = '合肥一元数智教育有限公司'
	},
    created() {
        this.getBanner()
    },
    methods: {
        goTarget(page) {
            const target = document.querySelector(`#${page}`).offsetTop
            window.scrollTo({ top: target, behavior: 'smooth' })
        },
        async getBanner() {
            this.banner = await getBanner('首页')
            console.log(this.banner)
			
        }
    }
}
</script>
<style scoped lang="less">
.home-nav-li {
    background-color: #d72502;
}
</style>
