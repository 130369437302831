import Layout from '@/views/Layout';
import classes from './modules/classes';
import StudentDetail from '@/views/Student';
import About from '@/views/About';
import Teacher from '@/views/Teacher';
import Project from '@/views/Project';
import News from '@/views/News';

export const routes = [{
		path: '/',
		redirect: '/index'
	},
	{
		path: '/index',
		component: Layout
	},
	{
		path: '/student',
		component: StudentDetail
	},
	{
		path: '/about',
		component: About,
		name: 'about',
	},
	{
		path: '/teacher',
		component: Teacher
	},
	{
		path: '/project',
		component: Project
	},
	{
		path: '/News',
		component: News
	},
].concat(classes);