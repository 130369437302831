import request from '@/utils/request'

const limit = 20
const currentPage = 1

export function getScheduleList() {
    // console.log(data);
    return request({
        url: `/schedule/list`,
        method: 'GET',
    })
}
export function getProjectList() {
    // console.log(data);
    return request({
        url: `/works/list`,
        method: 'GET',
    })
}
export function getTeacher() {
    // console.log(data);
    return request({
        url: `/teacher/list`,
        method: 'GET',
    })
}
export function getTeam() {
    // console.log(data);
    return request({
        url: `/team/list`,
        method: 'GET',
        params: {
            limit,
            currentPage
        }
    })
}
export function getJob() {

    return request({
        url: `/job/list`,
        method: 'GET',

    })
}
export function getCompanys() {
    // console.log(data);
    return request({
        url: `/cooperatives/list`,
        method: 'GET',

    })
}
export function getCollege() {
    // console.log(data);
    return request({
        url: `/university/list`,
        method: 'GET',

    })
}
export function getNewsList() {
    // console.log(data);
    return request({
        url: `/hotSearch/list`,
        method: 'GET',

    })
}
export function getEnvironmentList() {
    // console.log(data);
    return request({
        url: `/environment/list`,
        method: 'GET',

    })
}
export function getHonor() {
    // console.log(data);
    return request({
        url: `/honor/list`,
        method: 'GET',

    })
}

export function getBanner(type) {
    // console.log(data);
    return request({
        url: `/banner/list`,
        method: 'GET',
        params: { type }
    })
}
export function getNews() {
    // console.log(data);
    return request({
        url: `/hotSearch/list`,
        method: 'GET',
    })
}
export function getNewsById(id) {
    // console.log(data);
    return request({
        url: `/hotSearch/getHotById`,
        method: 'GET',
        params: { hotId: id }
    })
}
// 课程阶段模块
export function getModule(type) {
    // console.log(data);
    return request({
        url: `/outline/list`,
        method: 'GET',
        params: { type }
    })
}
export function getTeacherList() {
    // console.log(data);
    return request({
        url: `/teacher/page`,
        method: 'GET',
        params: {
            limit,
            currentPage
        }
    })
}




