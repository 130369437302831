<template>
    <div class="head">
        <div class="head-top">
            <img src="@/assets/img/logo.png" alt="" class="logo-img" />
            <div class="center">
                <span
                    class="blink"
                    @mouseover="$refs.code.style.display = 'block'"
                    @mouseout="$refs.code.style.display = 'none'"
                    >关注公众号领取2000元专属优惠</span
                >
                <img src="@/assets/img/index/common/weixin (1).png" alt="" height="105px" ref="code" />
            </div>
            <!-- <img src="@/assets/img/index/common/图层 4.png" alt="" width="325" height="91"> -->
            <div class="text">大学生就业IT培训中心</div>
        </div>
        <!-- 导航 -->

        <Nav v-if="nav">
            <li class="home-nav-li" :class="hover ? 'hover' : ''">
                <a href="javascript:">培训课程</a>
                <div class="course-list" :class="hover ? 'course-lists' : ''">
                    <div>
                        <div class="item">
                            <a @click.prevent="$router.push('/java')">Java开发培训</a>
                        </div>
                        <div class="item">
                            <a href="#" @click.prevent="$router.push('/ui')">UI/UE设计培训</a>
                        </div>
                        <div class="item">
                            <a href="" @click.prevent="$router.push('/web')">大前端（H5+小程序+APP） </a>
                        </div>
                        <div class="item">
                            <a href="#" @click.prevent="$router.push('/bigdata')">大数据培训</a>
                        </div>
                        <div class="item"><a href="">Python+人工智能</a></div>
                        <div class="item"><a href="">PHP开发设计</a></div>
                        <div class="item"><a href="">云计算工程师培训</a></div>
                        <div class="item"><a href="">C/安卓开发工程师培训</a></div>
                        <div class="item"><a href="">短视频运营+剪辑</a></div>
                    </div>
                </div>
            </li>
            <li>
                <a href="#" @click.prevent="$router.push('/')">首页</a>
            </li>
            <!-- <li><a href="">教学管理</a></li> -->
            <li>
                <a href="#" @click.prevent="$router.push('/teacher')">师资力量</a>
            </li>
            <li>
                <a href="#" @click.prevent="$router.push('/student')">就业保障</a>
            </li>
            <li>
                <a href="#" @click.prevent="$router.push('/')">教学环境</a>
            </li>
            <li>
                <a href="#" @click.prevent="$router.push('/')">项目实战</a>
            </li>
        </Nav>
    </div>
</template>

<script>
import Nav from '@/components/nav.vue'
export default {
    name: 'Head',
    data() {
        return {}
    },
    components: {
        Nav
    },
    props: {
        hover: Boolean,
        nav: Boolean
    },

    mounted() {},
    methods: {}
}
</script>
<style scoped lang="less">
.head {
    width: 1200px;
    margin: 0 auto;

    .head-top {
        display: flex;
        justify-content: space-between;

        .logo-img {
            height: 105px;
            margin-left: 10px;
        }

        .center {
            display: flex;
            // width: 340px;
            line-height: 105px;

            img {
                display: none;
                // height: 50px;
            }

            span {
                display: block;
                // width: 230px;
                color: #f90303;
            }

            .blink {
                animation: blink 1s linear infinite;
                -webkit-animation: blink 1s linear infinite;
            }
        }

        .text {
            margin: 48px 0 0;
        }
    }

    .home-nav-li {
        background-color: #d72502;
    }

    nav {
        font-size: 16px;

        .nav-list {
            display: flex;
            height: 40px;
            line-height: 40px;
            justify-content: center;
            text-align: center;

            a {
                color: #000;
            }

            a:hover {
                color: #d72502;
            }

            li {
                width: 100px;
                margin: 0 40px 0 20px;
            }

            .home-nav-li {
                width: 233px !important;
                margin-left: -30px !important;

                border-radius: 9px 11px 0px 0px;

                a {
                    color: #fff !important;
                    cursor: pointer;
                }
            }
        }

        .course-list {
            position: absolute;
            width: 233px;
            line-height: 48.5px;
            font-size: 15px;
            padding: 23px 0;
            // line-height: 25px;
            // font-size: 12px;
            // padding: 23px 24px;
            text-align: left;
            // background-color: #757482;
            background-color: #4b4b4bba;
            box-sizing: border-box;
            border-radius: 0px 0px 30px 30px;
            z-index: 999;

            .item {
                padding-left: 23px;
                transition: all 0.2s;
            }

            .item:hover {
                background-color: #4b4b4bc0;
                padding-left: 30px;
            }
        }
    }
}

.course-lists {
    display: none;
}

.hover:hover .course-list {
    display: block;
}

@keyframes blink {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
</style>
