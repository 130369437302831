<template>
    <div class="honor">
        <div class="detail">
            <div class="evaluate">
                <h2 class="about_b3_t"><img src="//img.bdqnhf.com/images/about_b3_t.png" width="184px" height="126px"></h2>
                <p class="about_b3_t1">三度入选中华人民共和国商 务部评选的“中国连锁经营百强企业”</p>
                <p class="about_b3_t1">连续三年荣获 “中国优秀特许加盟品牌”称号，</p>
                <p class="about_b3_t1">并因此而获得中国特许经营协会颁 发的“中国特许奖”。</p>
            </div>
            <ul class="about_b3_con">
                <li><img src="//img.bdqnhf.com/images/about_b3_01.png"></li>
                <li><img src="//img.bdqnhf.com/images/about_b3_02.png"></li>
                <li><img src="//img.bdqnhf.com/images/about_b3_03.png"></li>
                <li><img src="//img.bdqnhf.com/images/about_b3_04.png"></li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.detail {
    width: 1200px;
    height: 666px;
    margin: auto;
}

.evaluate {
    width: 800px;
    height: 180px;
    overflow: hidden;
    margin-top: 50px !important;
    margin-left: 60px !important;
}

.about_b3_t {
    width: 184px;
    height: 126px;
    float: left;
}

.about_b3_t1 {
    font-size: 18px;
    color: #efd39a;
    float: left;
    line-height: 30px;
    margin-left: 30px;
    margin-top: 20px;
}

.about_b3_con {
    width: 1200px;
    overflow: hidden;
    margin-top: 30px;
}
.about_b3_con li {
    width: 274px;
    height: 266px;
    margin-left: 20px;
    float: left;
}
</style>