<template>
    <div class="section">
        <div class="section-title">
            <slot>
                <div class="bg">
                    <img src="@/assets/img/classes/common/BIG DATA.png" alt="" />
                </div>
                提前了解大数据 ，<span class="text-blue">未来职场不迷茫</span>
            </slot>
        </div>
        <div class="section-content">
            <ul>
                <li v-for="item in list" :key="item.id">
                    <div class="bg-grey"></div>
                    <div class="text-box">
                        <div>
                            <img src="@/assets/img/classes/icon/avatar-icon-s.png" alt="" />
                            <span>{{ item.name }}</span>
                        </div>
                        <button @click="goAsk" :style="{ backgroundColor: btnColor ? btnColor : '' }">立即了解</button>
                    </div>
                </li>
            </ul>
        </div>

        <div class="btn">
            <button @click="goAsk">了解更多相关问题</button>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {}
    },
    props: {
        list: {
            type: Array,
            default() {
                return [
                    {
                        id: 1,
                        name: '大数据是什么？'
                    },
                    {
                        id: 2,
                        name: '大数据发展前景怎么样？'
                    },
                    {
                        id: 3,
                        name: '为什么要学大数据？'
                    },
                    {
                        id: 4,
                        name: '如何学大数据？难不难？'
                    },
                    {
                        id: 5,
                        name: '0基础学大数据要多久？'
                    },
                    {
                        id: 6,
                        name: '大数据应用领域有哪些？'
                    },
                    {
                        id: 7,
                        name: '大数据就业方向有哪些？'
                    },
                    {
                        id: 8,
                        name: '学历不够能学大数据吗？'
                    },
                    {
                        id: 9,
                        name: '大数据有什么优势？'
                    }
                ]
            }
        },
        btnColor: {
            type: String
        }
    },
    components: {},
    mounted() {},
    methods: {
        goAsk() {
            window.open('http://pdt.zoosnet.net/JS/LsJS.aspx?siteid=PDT87252886&float=1&lng=cn')    
        }
    }   
}
</script>
<style scoped lang="scss">
.section {
    .section-title {
        text-align: center;
        font-size: 36px;
        color: #333334;
        font-weight: 500;

        .bg {
            // position: absolute;
            margin-bottom: -15px;
        }
    }

    .section-content {
        ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            li {
                transition: all 0.2s;
                position: relative;
                width: 400px;
                height: 59px;
                margin-top: 29px;

                .bg-grey {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 321px;
                    height: 59px;
                    border-radius: 10px;
                    background: #f3f4f6;
                    z-index: -1;
                }

                .text-box {
                    position: absolute;
                    top: 50%;
                    width: 410px;
                    height: 36px;
                    line-height: 36px;
                    font-size: 18px;
                    transform: translateY(-50%);

                    img {
                        vertical-align: middle;
                        margin: 0 9px 0 12px;
                    }

                    div {
                        display: inline-block;
                    }

                    button {
                        float: right;
                        width: 110px;
                        height: 36px;
                        color: #fff;
                        background: #92d1fd;
                        border-radius: 18px;
                        margin-right: 30px;
                        cursor: pointer;
                    }
                }
            }

            li:hover {
                transform: scale(1.01);
            }
        }
    }
}
.btn {
    margin-top: 60px;
    text-align: center;
    button {
        height: 65px;
        width: 350px;
        font-size: 22px;
        color: #fff;
        background-color: #fb8227;
        border-radius: 35px;
        cursor: pointer;
        & :hover {
            background-color: #cd5900;
        }
    }
}
</style>
