<template>
  <div class="footer-bottom">
    <slot></slot>
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  components: {

  },
  mounted() {

  },
  methods: {

  },
}

</script>
<style scoped lang='scss'>
.footer-bottom {
    height: 50px;
    line-height: 20px;
    width: 100%;
    text-align: center;
    font-size: 14px;
    color: #999;
    background-color: #292929;
    padding-top: 10px;
    a {
        color: #188eee;
    }
  }
</style>