<template>
  <div class="section">
    <div class="section-content">
      <table>
        <thead>
          <tr>
            <th>姓名</th>
            <th>学历</th>
            <th>毕业院校</th>
            <th>就业公司</th>
            <th>月薪</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>周*双</td>
            <td>专科</td>
            <td>合肥交通职业技术学院</td>
            <td>江苏四海商舟电子商务有限公司</td>
            <td>6800</td>
          </tr>
		  <tr >
		    <td>孙*容</td>
		    <td>专科</td>
		    <td>合肥化工职业技术学院</td>
		    <td>合肥柯莱特信息技术有限公司</td>
		    <td>11000</td>
		  </tr>
		  <tr >
		    <td>陈*雨</td>
		    <td>硕士</td>
		    <td>木材科学与技术</td>
		    <td>软通</td>
		    <td>10500</td>
		  </tr>
		  <tr >
		    <td>曾*</td>
		    <td>本科</td>
		    <td>南昌航空大学</td>
		    <td>柯莱特</td>
		    <td>9000</td>
		  </tr>
		  <tr >
		    <td>龚*</td>
		    <td>本科</td>
		    <td>东南大学</td>
		    <td>中科曙光</td>
		    <td>9000</td>
		  </tr>
		  <tr >
		    <td>朱*文</td>
		    <td>本科</td>
		    <td>华东理工大学</td>
		    <td>苏宁</td>
		    <td>8600</td>
		  </tr>
		  <tr >
		    <td>宋*龙</td>
		    <td>本科</td>
		    <td>宿州学院</td>
		    <td>合肥邦众电子商务有限公司</td>
		    <td>8000</td>
		  </tr>
		  <tr >
		    <td>史*东</td>
		    <td>本科</td>
		    <td>合肥工业大学</td>
		    <td>合肥邦众电子商务有限公司</td>
		    <td>8000</td>
		  </tr>
		  <tr >
		    <td>杨*勇</td>
		    <td>本科</td>
		    <td>合肥信息工程大学</td>
		    <td>通联数据</td>
		    <td>7050</td>
		  </tr>
		  <tr >
		    <td>谢*子</td>
		    <td>本科</td>
		    <td>合肥工业大学</td>
		    <td>香港迈威国际有限公司</td>
		    <td>7000</td>
		  </tr>
		  <tr >
		    <td>戴*成</td>
		    <td>专科</td>
		    <td>合肥信息职业技术学院</td>
		    <td>科大国创司</td>
		    <td>6800</td>
		  </tr>
		  <tr >
		    <td>*童</td>
		    <td>本科</td>
		    <td>合肥学院</td>
		    <td>合肥枫准软件技术有限公司</td>
		    <td>6500</td>
		  </tr>
		  <tr >
		    <td>李*飞</td>
		    <td>本科</td>
		    <td>安徽工业大学</td>
		    <td>中软国际</td>
		    <td>7800</td>
		  </tr>
		  <tr >
		    <td>臧*娇</td>
		    <td>本科</td>
		    <td>合肥邮电大学</td>
		    <td>合肥大学工程管理学</td>
		    <td>7000</td>
		  </tr>
		  <tr >
		    <td>*璇</td>
		    <td>本科</td>
		    <td>吉林大学</td>
		    <td>中软国际</td>
		    <td>7500</td>
		  </tr>
		  <tr >
		    <td>陆*超</td>
		    <td>本科</td>
		    <td>石河子大学</td>
		    <td>安徽联众软件有限公司</td>
		    <td>7000</td>
		  </tr>
		  <tr >
		    <td>朱*习</td>
		    <td>大专</td>
		    <td>芜湖职业技术学院</td>
		    <td>江苏点石电子商务有限公司</td>
		    <td>7500</td>
		  </tr>
		  <tr >
		    <td>戴*</td>
		    <td>本科</td>
		    <td>合肥航空航天大学</td>
		    <td>苏宁易购百货公司</td>
		    <td>7250</td>
		  </tr>
		  <tr >
		    <td>沈*雯</td>
		    <td>本科</td>
		    <td>合肥信息学员</td>
		    <td>合肥森根科技发展有限公司</td>
		    <td>8000</td>
		  </tr>
		  <tr >
		    <td>李*</td>
		    <td>专科</td>
		    <td>合肥工业经济技术学院</td>
		    <td>合肥蓝染数码科技有限公司</td>
		    <td>7000</td>
		  </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  mounted() {},
  methods: {},
};
</script>
<style scoped lang='less'>
.section {
  margin-top: 60px;
  width: 100%;
}
.section-content {
  width: 1200px;
  margin: 0 auto;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 500px;
  border: 1px #2671be solid;
}
table {
  position: relative;
}
thead {
  position: sticky;
  top: 0;
}
th {
  color: #fff;
  background-color: #2671be;
}
th,
td {
  width: 240px;
  height: 50px;
  line-height: 50px;
}
td {
  text-align: center;
  color: #666666;
}
</style>