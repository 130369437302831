<template>
  <div class="footerNav-page">
    <div class="main-box">
      <ul class="clear">
        <li>
          <img
            src="@/assets/img/icon/st.png"
            alt="图片"
          />
		  <a href="http://pdt.zoosnet.net/LR/Chatpre.aspx?id=PDT87252886&lng=cn" target="_blank" class="a1">
          <button>预约试听</button>
		  </a>
        </li>
        <li>
          <img
            src="@/assets/img/icon/yh.png"
            alt="图片"
          />
		  <a href="http://pdt.zoosnet.net/LR/Chatpre.aspx?id=PDT87252886&lng=cn" target="_blank" class="a1">
          <button>学费优惠</button>
		  </a>
        </li>
        <li>
          <img
            src="@/assets/img/icon/dk.png"
            alt="图片"
          />
		  <a href="http://pdt.zoosnet.net/LR/Chatpre.aspx?id=PDT87252886&lng=cn" target="_blank" class="a1">
          <button>0元入学</button>
		  </a>
        </li>
        <li>
          <img
            src="@/assets/img/icon/zx.png"
            alt="图片"
          />
		  <a href="http://pdt.zoosnet.net/LR/Chatpre.aspx?id=PDT87252886&lng=cn" target="_blank" class="a1">
          <button>在线咨询</button>
		  </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "footerNavIndex",
};
</script>

<style lang="scss" scoped>
.footerNav-page {
  margin-top: 50px;
  width: 100%;
  background-color: #f1f1f1;
  .clear {
    display: flex;
    justify-content: space-between;
    padding: 50px 0;
    width: 100%;
    height: 125px;
    li {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 240px;
      height: 100%;
      img {
        width: 80px;
        height: 50px;
      }
	  
      button {
        width: 100%;
        height: 40px;
        border: 1px solid #b7b7b7;
        font-size: 14px;
      }
      
    }
    li:hover button{
        background-color: #ec6c00;
        color: #fff;
        transition: .3s;
      }
    li:last-child img {
      height: 72px;
    }
  }
}
.a1{
	display: inline-block;
	width: 100%;
	height: 40px;
	border: 1px solid #b7b7b7;
	font-size: 14px;
}
</style>