<template>
    <div class="section">
        <div class="section-title">
            <span class="title">真实项目</span>
            <div class="intro">
                学员在学习期间进行真是项目实战，完成企业级的项目作品，锻造职场工作思维；实战过程完全还原企业真是开发场景与标准化工作流程
            </div>
            <button>更多>></button>
        </div>
        <div class="section-context">
            <ul>
                <li v-for="(item, index) in list" :key="index">
                    <img :src="item.img" alt="" />
                    <div class="info">
                        <div class="title">
                            {{ item.title }}
                        </div>
                        <div class="context">
                            {{ item.context }}
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        projects: {
            type: Array,
            default() {
                return [
                    {
                        id: 1,
                        img: require('@/assets/img/classes/web-img/truth-1.png'),
                        title: '智能餐饮小程序',
                        context:
                            '该小程序是一个连锁餐饮的智慧服务产品，帮助餐厅实现智能化管理，减轻餐厅员工的工作量，同事又可以让顾客感受到更好的服务，帮助所有连锁店实现“线下'
                    },
                    {
                        id: 2,
                        img: require('@/assets/img/classes/web-img/truth-2.png'),
                        title: '智能施工养护综合平台',
                        context:
                            '可查看施工养护数据，进行实时数据分析，了解资源占比，快速了解需养护区域派遣工作人员进行养护。'
                    },
                    {
                        id: 3,
                        img: require('@/assets/img/classes/web-img/truth-3.png'),
                        title: '国际化数据可视化展示平台',
                        context:
                            '结合国际化技术，可以在不同语言环境自动切换语言平台。展示网站数据，快速生成网站数据分析。帮助网站管理员更好的实现网站运营动态。'
                    },
                    {
                        id: 4,
                        img: require('@/assets/img/classes/web-img/truth-4.png'),
                        title: '课工场商城小程序',
                        context:
                            '课工场电商小程序，一款优选好物的新生活方式的社交电商。专注于新中产的年轻消费群体，主打趣味个性、品质实用、格调精致的关学设计。为用户提供具备高品质、高颜值、科技感的好产品。'
                    }
                ]
            }
        }
    },
    computed: {
        list() {
            if (this.projects[0]) {
                return this.projects.map(item => {
                    item.img = item.img
                    return item
                })
            }
        }
    },
    data() {
        return {}
    },
    components: {},
    mounted() {},
    methods: {}
}
</script>
<style scoped lang="scss">
.section {
    margin-top: 60px;

    .section-title {
        display: flex;

        font-size: 15px;

        .title {
            display: inline-block;
            font-size: 24px;
            width: 110px;
            line-height: 5px;
        }

        .intro {
            width: 970px;
            color: #666;
        }

        button {
            width: 80px;
            border: 1px solid #666;
            color: #666;
            border-radius: 22px;
            cursor: pointer;
        }
    }

    .section-context {
        margin-top: 60px;

        ul {
            display: flex;
            justify-context: space-between;

            li {
                width: 276px;
                height: 362px;
                border: 1px solid #6666662d;
                padding: 5px;
                border-radius: 8px;
                transition: all 0.3s;
                box-sizing: border-box;
                cursor: pointer;
                img {
                    width: 100%;
                    margin-bottom: 30px;
                }

                .info {
                    padding: 0 18px;
                    box-sizing: border-box;
                    display: -webkit-box;
                    /* 2)让元素里面的文本纵向排列,垂直显示,垂直排列 */
                    -webkit-box-orient: vertical;
                    /* 3)让你的文本溢出的省略号在第几行显示 */
                    /* 省略号显示在第三行 */
                    -webkit-line-clamp: 5;
                    /* 4)让超出的文本省略掉 */
                    overflow: hidden;

                    .title {
                        font-weight: bold;
                        text-align: center;
                        margin-bottom: 11px;
                    }

                    .context {
                        font-size: 15px;
                        color: #666;
                    }
                }
            }

            li:hover {
                box-shadow: 3px 4px 10px #ccc;
                transform: scale(105%);
            }
        }
    }
    button {
        background-color: #fff;
        transition: all 0.4s;
    }
    button:hover {
        background-color: #4665ff57;
        border-color: transparent;
        transform: scale(1.1);
    }
}
</style>
