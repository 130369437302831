<template>
  <div class="section">
    <div class="section-title">
      课程定位与<span class="text-blue">培养目标</span>
    </div>
    <div class="section-centent">
      <div class="left">
        <img src="@/assets/img/classes/bigdata/target-img.png" alt="" />
      </div>
      <div class="right">
        以百度人工智能、华为大数据为基础，腾讯云、百度云、亚马逊云、5G技术为支撑，打造
        <span>大数据+人工智能专精课程</span>
        通过6个月精细化学习和一线ICT企业原厂项目综合实战，培养出符合国家信息产业、信息技术发展需要的高端大数据人工智能工程师。学习完毕具备大数据采集、存储、清洗、分析、综合治理的能力，掌握人工智能的核心技术机器学习，相当于2年以上的工作经验。
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  mounted() {},
  methods: {},
};
</script>
<style scoped lang='scss'>
.section {
//   height: 576px;
  margin-top: 60px;
  .section-title {
    text-align: center;
    font-size: 36px;
    color: #333334;
    .text-blue {
      font-weight: 600;
    }
  }
  .section-centent {
    display: flex;
    height: 443px;
    padding: 18px 0 0;

    .left {
      height: 443px;
      width: 423px;
      img {
        width: 100%;
        // border: 2px dashed #333;
      }
    }
    .right {
      padding: 87px 0 0 23px;

      width: 659px;
      font-size: 16px;
      font-family: Microsoft YaHei UI;
      font-weight: 400;
      color: #333333;
      line-height: 40px;
      span {
        font-size: 24px;
        font-weight: bold;
        color: #000;
      }
    }
  }
}
</style>