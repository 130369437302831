<template>
    <div class="section clear">
        <div class="bg"></div>
        <div class="section-title">
            {{ title }}
            <span style="font-size: 16px">{{ info }}</span>
        </div>
        <div class="section-content">
            <div class="title">
                <slot name="chart-title">
                    <span>学生月薪</span><span>课程稳步提升，进阶{{ target }}</span>
                </slot>
            </div>
            <div class="chart">
                <slot name="chart-text">
                    <span style="top: 242px; left: 103px; width: 131px">{{ skill[0] }}</span>
                    <span style="top: 215px; left: 255px; width: 144px">{{ skill[1] }}</span>
                    <span style="top: 175px; left: 407px; width: 131px">{{ skill[2] }}</span>
                    <span style="top: 145px; left: 550px; width: 144px">{{ skill[3] }}</span>
                    <span style="top: 115px; left: 710px; width: 160px">{{ skill[4] }}</span>
                    <span style="top: 75px; left: 877px; width: 160px">{{ skill[5] }}</span>
                    <span style="top: 35px; left: 1050px; width: 130px">{{ skill[6] }}</span>
                </slot>
            </div>
            <div class="chart-bottom">
                <slot name="bottom">
                    <span>{{ direction[0] }}</span>
                    <span>{{ direction[1] }}</span>
                    <span>{{ direction[2] }}</span>
                    <span>{{ direction[3] }}</span>
                    <span>{{ direction[4] }}</span>
                    <span>{{ direction[5] }}</span>
                    <span>{{ direction[6] }}</span>
                </slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: "H5前端工程师技能发展路径",
        },
        info: {
            type: String,
            default:
                "推出高级软件工程师课程，覆盖全栈+全端，知识量和技术深度增加50%；工程化+场景化深度定制，更贴合现阶段大厂要求",
        },
        target: {
            type: String,
            default: "高级前端工程师",
        },
        skill: {
            type: Array,
            default() {
                return [
                    ,
                    "独立使用VUE技术栈",
                    "基于UNi-APP开发企业级微信小程序",
                    "使用大厂主流技术，完成企业级应用开发",
                    "基于混合应用开发技术开发企业APP",
                    "定制脚手架及性能方案提升团队开发效率",
                    "全栈+全端全覆盖达到大厂用人标准",
                ]
            },
        },
        direction: {
            type: Array,
            default() {
                return [
                    "传统前端开发",
                    "VUE阶段",
                    "小程序阶段",
                    "React阶段",
                    "多端跨平台",
                    "全栈+前端工程化",
                    "全栈开发",
                ]
            },
        },
    },
    data() {
        return {}
    },
    components: {},
    mounted() {},
    methods: {},
}
</script>
<style scoped lang="scss">
.section {
    height: 520px;
    position: relative;
    margin-top: 60px;
    color: #fff;

    .bg {
        position: absolute;
        left: -350px;
        width: 1920px;
        height: 520px;
        background-color: #216df4;
        z-index: -1;
    }

    .section-title {
        margin-top: 42px;
        font-size: 24px;
    }

    .section-content {
        position: relative;
        margin-top: 20px;
        height: 355px;
        background: url(@/assets/img/classes/common/table-bg.jpg) no-repeat;
        background-size: cover;
    }

    span {
        display: inline-block;
    }

    .title {
        position: absolute;
        top: 10px;
        font-size: 18px;

        span {
            margin-right: 20px;
        }
    }
    .chart {
        position: relative;
        span {
            position: absolute;
            text-align: center;
        }
    }
    .chart-bottom {
        position: absolute;
        display: flex;
        width: 100%;
        justify-content: space-evenly;
        bottom: -40px;
        left: 37px;
    }
}
</style>
