<template>
  <div>
    <div class="bdqn-fix-sidebar" style="height: 187px">
      <div class="bdqn-fix-sidebar-btn-wrapper">
        <a
          class="bdqn-fix-sidebar-btn bdqn-fix-sidebar-wx-btn rel"
          href="javascript:void(0);"
          title="微信"
        >
          <span>微信</span>
          <img
            class=" hide wx-qr-code-img"
            src="@/assets/img/index/common/weixin.png"
            alt="公众号"
            width="138"
          />
        </a>
        <a
          class="bdqn-fix-sidebar-btn bdqn-fix-sidebar-zx-btn"
          href="http://pdt.zoosnet.net/LR/Chatpre.aspx?id=PDT87252886&lng=cn"
          title="咨询热线"
          target="_blank"
          ><span>咨询热线</span></a
        >
        <a
          class="bdqn-fix-sidebar-btn bdqn-fix-sidebar-course-btn"
          target="_blank"
          href="http://pdt.zoosnet.net/LR/Chatpre.aspx?id=PDT87252886&lng=cn"
          title="免费课程"
          ><span>免费课程</span></a
        >
        <a
          id="back-top"
          class="bdqn-fix-sidebar-btn bdqn-fix-sidebar-back-top"
          href="javascript:void(0);"
          title="回顶部"
          style="display: block"
        ></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  mounted() {},
  methods: {},
};
</script>
<style scoped lang='less'>
.bdqn-fix-sidebar {
  position: fixed;
  bottom: 110px;
  right: 15px;
  transform: translateY(-50%);
  padding-top: 103px;
  z-index: 66;
  border-radius: 0 0 3px 3px;

  .bdqn-fix-sidebar-btn-wrapper {
    display: block;
    width: 80px;
    background: #ffffff;
    border: 10px;
    box-shadow: 0px 0px 10px #eeeeee;
  }
  .bdqn-fix-sidebar-btn {
    display: block;
    width: 80px;
    height: 84px;
    background: #ffffff;
    color: #4d575f;
    font-size: 14px;
    text-align: center;
    line-height: 18px;
  }
  .bdqn-fix-sidebar-btn > span {
    display: block;
    height: 20px;
    background: #fff;
    margin-top: -28px;
  }
  .bdqn-fix-sidebar-btn:before {
    display: block;
    content: "";
    width: 36px;
    box-sizing: content-box;
    margin: 0px auto;
    background: url(@/assets/img/index/common/bdqn-fix-sidebar.png);
  }
  .bdqn-fix-sidebar-btn:hover:before {
    // transform: scale(1.1);
    transition: 0.2s;
    background: url(@/assets/img/index/common/bdqn-fix-sidebar-hover.png);
  }
  .bdqn-fix-sidebar-wx-btn,
  .bdqn-fix-sidebar-wx-btn:before,
  .bdqn-fix-sidebar-course-btn,
  .bdqn-fix-sidebar-course-btn:before {
    height: 84px;
  }
  .bdqn-fix-sidebar-wx-btn:before,
  .bdqn-fix-sidebar-wx-btn:hover:before {
    background-position: -70px -108px;
  }
  .bdqn-fix-sidebar-wx-btn:hover .hide{
    display: block;
  }
  .bdqn-fix-sidebar-zx-btn,
  .bdqn-fix-sidebar-zx-btn:before {
    height: 91px;
  }
  .bdqn-fix-sidebar-zx-btn:before,
  .bdqn-fix-sidebar-zx-btn:hover:before {
    background-position: -70px -192px;
  }

  .bdqn-fix-sidebar-course-btn:before,
  .bdqn-fix-sidebar-course-btn:hover:before {
    background-position: -70px -283px;
  }
  .bdqn-fix-sidebar-back-top,
  .bdqn-fix-sidebar-back-top:before {
    height: 68px;
  }
  .bdqn-fix-sidebar-back-top:before,
  .bdqn-fix-sidebar-back-top:hover:before {
    background-position: -70px -367px;
  }
}
.wx-qr-code-img {
  position: absolute;
  top: 0;
  right: 80px;
  border: 1px solid #ccc;
}
.bdqn-fix-sidebar:before {
  content: "";
  display: block;
  position: absolute;
  top: 0px;
  left: -50px;
  width: 131px;
  height: 128px;
  background: url(@/assets/img/index/common/bdqn-fix-sidebar-bird.png) 0 0;
  z-index: 1;
  animation: sidebar-bird 0.6s infinite steps(1);
}
.rel,
.pos-rel {
  position: relative;
}
.hide {
  display: none;
  .kgc_hide {
    display: none;
  }
}
@keyframes sidebar-bird {
  0.00% {
    background-position: -0px 0;
  }
  20.00% {
    background-position: -131px 0;
  }
  40.00% {
    background-position: -262px 0;
  }
  60.00% {
    background-position: -393px 0;
  }
  100.0% {
    background-position: -524px 0;
  }
}
</style>