<template>
    <div class="section">
        <div class="section-title">
            <p class="section-title-jy">就业学员</p>
            <p class="section-title-ec">EMPLOYMENT CADETS</p>
        </div>
        <div class="section-content">
            <ul>
                <li>
                    <img src="@/assets/img/stud/学员1.png" align="" />
                    <div class="section-content-characters">
                        <p>转行做程序无头绪来北大青鸟一元开启新征程</p>
                        <p class="section-content-characters-xiao">
                            <span class="section-content-characters-black">入职:安夏电子</span>
                            <span class="section-content-characters-orange">薪资:8500</span>
                        </p>
                    </div>
                </li>
                <li>
                    <img src="@/assets/img/stud/学员2.jpg" align="" />
                    <div class="section-content-characters">
                        <p>网络通讯专业转行，来北大青鸟一元学Java</p>
                        <p class="section-content-characters-xiao">
                            <span class="section-content-characters-black">入职:悦能智能</span>
                            <span class="section-content-characters-orange">薪资:8000</span>
                        </p>
                    </div>
                </li>
                <li>
                    <img src="@/assets/img/stud/学员3.png" align="" />
                    <div class="section-content-characters">
                        <p>为提升自己自己参加北大青鸟一元UI培训</p>
                        <p class="section-content-characters-xiao">
                            <span class="section-content-characters-black">入职:神奇科技</span>
                            <span class="section-content-characters-orange">薪资:6000</span>
                        </p>
                    </div>
                </li>
                <li>
                    <img src="@/assets/img/stud/学员4.png" align="" />
                    <div class="section-content-characters">
                        <p>软件专业出身参加北大青鸟一元java培训</p>
                        <p class="section-content-characters-xiao">
                            <span class="section-content-characters-black">入职:悦能智能</span>
                            <span class="section-content-characters-orange">薪资:10500</span>
                        </p>
                    </div>
                </li>
                <li>
                    <img src="@/assets/img/stud/学员5.png" align="" />
                    <div class="section-content-characters">
                        <p>北大青鸟一元Java培训学员华丽转身入职</p>
                        <p class="section-content-characters-xiao">
                            <span class="section-content-characters-black">入职:紫津融畅</span>
                            <span class="section-content-characters-orange">薪资:8100</span>
                        </p>
                    </div>
                </li>
                <li>
                    <img src="@/assets/img/stud/学员6.jpg" align="" />
                    <div class="section-content-characters">
                        <p>因为兴趣选择北大青鸟一元java培训，转行</p>
                        <p class="section-content-characters-xiao">
                            <span class="section-content-characters-black">入职:紫津融畅</span>
                            <span class="section-content-characters-orange">薪资:7600</span>
                        </p>
                    </div>
                </li>
                <li>
                    <img src="@/assets/img/stud/学员7.png" align="" />
                    <div class="section-content-characters">
                        <p>就业不顺参加北大青鸟一元java培训转行</p>
                        <p class="section-content-characters-xiao">
                            <span class="section-content-characters-black">入职:中软国际</span>
                            <span class="section-content-characters-orange">薪资:7500</span>
                        </p>
                    </div>
                </li>
                <li>
                    <img src="@/assets/img/stud/学员8.jpg" align="" />
                    <div class="section-content-characters">
                        <p>机械转行做开发，参加北大青鸟一元java培训</p>
                        <p class="section-content-characters-xiao">
                            <span class="section-content-characters-black">入职:中软国际</span>
                            <span class="section-content-characters-orange">薪资:7500</span>
                        </p>
                    </div>
                </li>
                <li>
                    <img src="@/assets/img/stud/学员9.jpg" align="" />
                    <div class="section-content-characters">
                        <p>应届毕业来北大青鸟一元学java</p>
                        <p class="section-content-characters-xiao">
                            <span class="section-content-characters-black">入职:中软国际</span>
                            <span class="section-content-characters-orange">薪资:7000</span>
                        </p>
                    </div>
                </li>
                <li>
                    <img src="@/assets/img/stud/学员10.jpg" align="" />
                    <div class="section-content-characters">
                        <p>模具专业就业不满意，到北大青鸟一元学UI</p>
                        <p class="section-content-characters-xiao">
                            <span class="section-content-characters-black">入职:会凌广告</span>
                            <span class="section-content-characters-orange">薪资:6000</span>
                        </p>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
    components: {},
    mounted() { },
    methods: {},
};
</script>
<style scoped lang='less'>
.section{
    width: 100%;
}
.section-content{
    width: 1200px;
    margin:  0 auto;
}
.section-title{
    height: 100px;
}
.section-title-jy{
    font-size: 20px;
    text-align: center;
    height: 50px;
    line-height: 50px;
}
.section-title-ec{
    color: #2671BE;
    font-size: 12px;
    text-align: center;
    height: 50px;
    line-height: 50px;
}
ul{
    display: flex;
    flex-flow: wrap;
}
li{
    position: relative;
    width: 240px;
    height: 260px;
}
img{
    position: absolute;
    left: 50%;
    width: 220px;
    transform: translate(-50%,0);
}
.section-content-characters{
    position: absolute;
    top: 180px;
    left: 50%;
    width: 220px;
    transform: translate(-50%,0);
    background-color: #F8F8F8;
    padding: 10px;
    box-sizing: border-box;
}
.section-content-characters-xiao{
    position: relative;
    top: 10px;
    font-size: 12px;
}
.section-content-characters-black{
    color: #777777;
}

.section-content-characters-orange{
    position: absolute;
    color: #FFBC3A;
    right: 0;
}


</style>