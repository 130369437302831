<template>
    <div class="section">
        <div class="section-title">适合人群</div>
        <div class="section-content">
            <ul>
                <li>
                    <p class="title">初学入门</p>
                    <img src="@/assets/img/classes/common/peo-1.png" alt="" />
                    <p class="content">
                        课程针对没有<br />
                        基础人群0墓础无经验适学
                    </p>
                </li>
                <li>
                    <p class="title">迎接毕业生</p>
                    <img src="@/assets/img/classes/common/peo-2.png" alt="" />
                    <p class="content">
                        刚毕业没经验找不到工作<br />
                        来一元，在一元再出发
                    </p>
                </li>
                <li>
                    <p class="title">想转行</p>
                    <img src="@/assets/img/classes/common/peo-3.png" alt="" />
                    <p class="content">
                        想要突破现在枯燥的生活<br />
                        来一元 即刻开始改变
                    </p>
                </li>
                <li>
                    <p class="title">想提升加薪</p>
                    <img src="@/assets/img/classes/common/peo-4.png" alt="" />
                    <p class="content">
                        辛苦工作薪水低<br />
                        来一元迎接“薪”未来
                    </p>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {}
    },
    components: {},
    mounted() {},
    methods: {}
}
</script>
<style scoped lang="scss">
.section {
    margin-top: 60px;

    .section-title {
        font-size: 23px;
        margin-bottom: 80px;
    }

    ul {
        display: flex;
        justify-content: space-between;

        li {
            width: 220px;
            text-align: center;
            transition: all 0.3s;
            padding: 30px 20px;
            // border: 1px solid #ccc;
            border-radius: 5px;
            box-shadow: 0px 0px 16px #ccc;
            cursor: pointer;
            .title {
                font-size: 21px;
                font-size: 600;
                margin-bottom: 20px;
            }

            img {
                width: 100%;
                height: 180px;
            }

            .content {
                line-height: 2em;
            }
        }

        li:hover {
            box-shadow: 3px 4px 10px #ccc;
            transform: scale(105%);
        }
    }
}
</style>
