<template>
  <div class="section">
    <div class="section-title">
      <p class="section-title-jy">就业喜报</p>
      <p class="section-title-ec">EMPLOYMENT NEWS</p>
    </div>
    <div class="section-content">
      <ul>
        <li>
          <img src="@/assets/img/stud/学员11.jpg" align="" />
          <div class="section-content-characters">
            <p class="section-content-characters-black">
              向往IT行业寻转行,参加北大青鸟一元java
            </p>
            <p class="section-content-characters-orange">薪资:7500</p>
          </div>
        </li>
        <li>
          <img src="@/assets/img/stud/学员12.jpg" align="" />
          <div class="section-content-characters">
            <p class="section-content-characters-black">
              应届生参加北大青鸟一元java培训，7K入职
            </p>
            <p class="section-content-characters-orange">薪资:7000</p>
          </div>
        </li>
        <li>
          <img src="@/assets/img/stud/学员13.jpg" align="" />
          <div class="section-content-characters">
            <p class="section-content-characters-black">
              南林硕士参加北大青鸟一元java培训，轻松
            </p>
            <p class="section-content-characters-orange">薪资:10500</p>
          </div>
        </li>
        <li>
          <img src="@/assets/img/stud/学员14.jpg" align="" />
          <div class="section-content-characters">
            <p class="section-content-characters-black">
              网络管理专业出身职场遇瓶颈，学web
            </p>
            <p class="section-content-characters-orange">薪资:11000</p>
          </div>
        </li>
        <li>
          <img src="@/assets/img/stud/学员15.jpg" align="" />
          <div class="section-content-characters">
            <p class="section-content-characters-black">
              Java培训班宋*龙
            </p>
            <p class="section-content-characters-orange">薪资:8000</p>
          </div>
        </li>
        <li>
          <img src="@/assets/img/stud/学员16.jpg" align="" />
          <div class="section-content-characters">
            <p class="section-content-characters-black">
              Java培训班*凯
            </p>
            <p class="section-content-characters-orange">薪资:9100</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  mounted() {},
  methods: {},
};
</script>
<style scoped lang='less'>
.section {
    margin-top: 60px;
  width: 100%;
}
.section-content {
  width: 1200px;
  height: 300px;
  margin: 0 auto;
}
.section-title {
  height: 100px;
}
.section-title-jy {
  font-size: 20px;
  text-align: center;
  height: 50px;
  line-height: 50px;
}
.section-title-ec {
  color: #2671be;
  font-size: 12px;
  text-align: center;
  height: 50px;
  line-height: 50px;
}
ul {
  display: flex;
  flex-flow: wrap;
}
li {
  position: relative;
  width: 200px;
  height: 260px;
}
img {
  position: absolute;
  left: 50%;
  width: 160px;
  transform: translate(-50%, 0);
  border-radius: 50%;
}
.section-content-characters {
  position: absolute;
  top: 180px;
  left: 50%;
  width: 220px;
  transform: translate(-50%, 0);
  background-color: #f8f8f8;
  padding: 10px;
  box-sizing: border-box;
}
.section-content-characters-black {
    height: 70px;
  font-size: 18px;
  color: #333;
  text-align: center;
    padding: 0 20px;
  font-weight: normal;
}

.section-content-characters-orange {
  color: #ffbc3a;
  text-align: center;
  line-height: 30px;
}
.section-content-ya {
  border-radius: 50%;
}
</style>