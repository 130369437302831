<template>
    <div>
        <div class="section">
            <div class="bg-blue"></div>
            <div class="title"><span class="sp1">毕业生薪资</span><span class="sp2">屡创新高</span></div>
            <div class="banner">
                <div class="box" v-for="(item, index) in banner" :key="index">
                    <p>
                        <span class="bsp1">{{ item.num }}</span
                        ><span class="bsp2">{{ item.unit }}</span>
                    </p>
                    <p class="p1">{{ item.title }}</p>
                </div>
            </div>
            <div class="main">
                <div class="pic" v-for="(item, index) in student" :key="index">
                    <img style="margin-left: 4px" :src="item.img" alt="" />
                    <div class="text">
                        <p>{{ item.name[0] }}</p>
                        <p>薪资：{{ item.money }}</p>
                        <p class="p3"></p>
                        <p>入职企业:{{ item.city }}</p>
                        <p>学历:{{ item.degree }}</p>
                    </div>
                </div>
            </div>
            <div class="foot">
                <div class="con-text">
                    <p>资讯更多学院薪资</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        banner: {
            default() {
                return [
                    {
                        title: '2022年毕业学员',
                        num: 5262,
                        unit: '人'
                    },
                    {
                        title: '就业学员',
                        num: 5157,
                        unit: '人'
                    },
                    {
                        title: '就业薪资',
                        num: 17000,
                        unit: '元'
                    },
                    {
                        title: '平均就业薪资',
                        num: 13250,
                        unit: '元'
                    }
                ]
            }
        },
        student: {
            default() {
                return [
                    {
                        name: '孙*平',
                        salay: '月薪17000+ 五险一金',
                        company: '浙江**技术',
                        edu: '专科',
                        img: require('@/assets/img/classes/bigdata/pe-1.png')
                    },
                    {
                        name: '孙*平',
                        salay: '月薪17000+ 五险一金',
                        company: '浙江**技术',
                        edu: '专科',
                        img: require('@/assets/img/classes/bigdata/pe-1.png')
                    },
                    {
                        name: '孙*平',
                        salay: '月薪17000+ 五险一金',
                        company: '浙江**技术',
                        edu: '专科',
                        img: require('@/assets/img/classes/bigdata/pe-1.png')
                    },
                    {
                        name: '孙*平',
                        salay: '月薪17000+ 五险一金',
                        company: '浙江**技术',
                        edu: '专科',
                        img: require('@/assets/img/classes/bigdata/pe-1.png')
                    },
                    {
                        name: '孙*平',
                        salay: '月薪17000+ 五险一金',
                        company: '浙江**技术',
                        edu: '专科',
                        img: require('@/assets/img/classes/bigdata/pe-1.png')
                    },
                    {
                        name: '孙*平',
                        salay: '月薪17000+ 五险一金',
                        company: '浙江**技术',
                        edu: '专科',
                        img: require('@/assets/img/classes/bigdata/pe-1.png')
                    },
                    {
                        name: '孙*平',
                        salay: '月薪17000+ 五险一金',
                        company: '浙江**技术',
                        edu: '专科',
                        img: require('@/assets/img/classes/bigdata/pe-1.png')
                    },
                    {
                        name: '孙*平',
                        salay: '月薪17000+ 五险一金',
                        company: '浙江**技术',
                        edu: '专科',
                        img: require('@/assets/img/classes/bigdata/pe-1.png')
                    },
                    {
                        name: '孙*平',
                        salay: '月薪17000+ 五险一金',
                        company: '浙江**技术',
                        edu: '专科',
                        img: require('@/assets/img/classes/bigdata/pe-1.png')
                    }
                ]
            }
        }
    }
}
</script>

<style lang="less" scoped>
.section {
    margin-top: 100px;
    padding-bottom: 20px;
    position: relative;
    width: 100%;
    // height: 1000px;
    .bg-blue {
        position: absolute;
        left: -360px;
        width: 1920px;
        height: 100%;
        background-color: #4666ff;
        z-index: -1;
    }
    .title {
        padding-top: 37px;
        text-align: center;
        height: 46px;

        span {
            font-size: 48px;
        }
        .sp1 {
            color: #ffffff;
        }
        .sp2 {
            color: #01ff75;
        }
    }
    .banner {
        width: 1200px;
        margin: auto;
        padding: 55px 0 59px 0;
        display: flex;
        justify-content: space-between;

        .box {
            width: 280px;
            height: 225px;
            border: 4px solid #3eb4fc;
            // border-image: linear-gradient(46deg, #394ce2, #3eb4fc) 10 10;
            // background: linear-gradient(46deg, #394ce2 0%, #3eb4fc 100%);
            border-radius: 18px;
            // margin-right: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .bsp1 {
                font-size: 60px;
                color: #ffffff;
            }
            .bsp2 {
                font-size: 24px;
                color: #ffffff;
            }
            .p1 {
                margin-top: 28px;
                font-size: 24px;
                color: #ffffff;
            }
        }
    }
    .main {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 1200px;
        // height: 1000px;
        margin: auto;
        // background-color: #01ff75;
        .pic {
            cursor: pointer;
            display: flex;
            align-items: center;
            width: 380px;
            height: 169px;
            // margin-right: 25px;
            margin-bottom: 28px;
            // padding: 5px 4px 4px 4px;
            transition: all 0.3s;
            // border: 4px solid;
            // border-image: linear-gradient(42deg, #515cfd, #46b6ff) 10 10;
            // background: linear-gradient(42deg, #515cfd 0%, #46b6ff 100%);
            background: url('@/assets/img/classes/common/rectangle.png');
            // border-radius: 0px 0px 33px 0px;
            img {
                width: 148px;
                height: 147px;
                padding-left: 5px;
            }
            .text {
                width: 51%;
                padding-left: 3px;
                p {
                    white-space: nowrap; // 文字强制不换行
                    text-overflow: ellipsis; // 文字溢出换省略号
                    overflow: hidden; // 溢出文字隐藏
                }
            }
        }
        .pic:hover {
            transform: scale(1.1);
        }
        .pic p:nth-child(1) {
            font-size: 24px;
        }
        // .pic:nth-child(1) {
        //   margin-right: 25px;
        // }
        // .pic:nth-child(2) {
        //   margin-right: 25px;
        // }
        .pic p:nth-child(2) {
            margin-top: 17px;
            margin-bottom: 15px;
            font-size: 16px;
        }
        .pic p:nth-child(4) {
            // margin-top: 17px;
            font-size: 16px;
            margin-bottom: 13px;
        }
        .text {
            color: #ffffff;
            .p3 {
                margin-bottom: 16px;
                width: 203px;
                height: 0;
                border: 1px solid #48a8ff;
            }
        }
    }
    .foot {
        width: 341px;
        height: 59px;
        background: linear-gradient(0deg, #0090e4 0%, #04dc78 100%);
        border-radius: 29px;
        margin: 0 auto;
        cursor: pointer;
        .con-text {
            text-align: center;
            line-height: 59px;
            font-size: 24px;
            color: #ffffff;
        }
    }
    .foot:hover {
        background: linear-gradient(0deg, #04dc78 0%, #0090e4 100%);
    }
}
</style>
