<template>
    <div>
        <div class="d-page-banner pos-rel">
            <img class="pos-abs" src="../../assets/img/project/d_page_banner.jpg" alt="">
            <div class="project-name pos-abs">
                <span class="v-m white f18 kgc_mr15">项目名称 :</span>
                <span class="v-m white strong f36">宏鹏智能数据分析系统</span>
            </div>
        </div>

        <!-- 项目介绍 -->
        <div class="project-introduction">
            <div class="project-introduction-content">
                <span class="icon-point">“</span>
                <div class="d-module-title kgc_aC">
                    <p class="name dis-i f36 strong">
                        <span class="text">项目介绍</span>
                        <span class="num-bg">01</span>
                    </p>
                </div>
                <div class="project-introduction-info kgc_mt20">
                    <p>
                        该项目用于校区智能分析学生日常学习状况。系统核心流程“数据采集、分析和前端实时展示”功能，通过按特定时间维度占用设备、人员、场地等资源，以一条线性流程串联成具体事件，围绕事件设置与之相关的管理功能。
                    </p>
                </div>
            </div>
        </div>

        <!-- 视频展示 -->
        <div class="d-project-video kgc-ofx-h">
            <div class="d-module-title kgc_aC kgc_mt30">
                <p class="name dis-i f36 strong">
                    <span class="text white">视频展示</span>
                    <span class="num-bg dark">02</span>
                </p>
            </div>
            <div class="d-p-video-box pos-rel">
                <div class="d-play-box kgc_w kgc_h">
                    <img class="d-p-v-img pos-abs kgc_w kgc_h" src="../../assets/img/project/d_p_video_2021_img.jpg" alt="">
                    <span class="play-icon">
                        <img src="../../assets/img/project/icon_play.png" alt="">
                    </span>
                </div>
                <video class="d-p-video kgc_w kgc_h video-js" id="d-p-video" controls>
                    <!-- <source src="//download.bdqn.cn/zhuanti/videos/2021/06/t4/9101/segments.m3u8" type="application/x-mpegURL"> -->
                </video>
            </div>
        </div>

        <!-- 团队照片 -->
        <div class="d-team-pic pos-rel">
            <div class="d-module-title kgc_aC kgc_mt50">
                <p class="name dis-i f36 strong">
                    <span class="text">团队照片</span>
                    <span class="num-bg">03</span>
                </p>
            </div>

            <div class="pic-box">
                <img class="kgc_w kgc_h" src="../../assets/img/project/6.jpg"
                    alt="宏鹏智能数据分析系统  JAVA技术方向  课工场武汉光谷中心    新技术应用奖">
            </div>
            <img class="team-bg-img1 pos-abs" src="../../assets/img/project/team_bg_img1.png" alt="">
            <img class="team-bg-img2 pos-abs" src="../../assets/img/project/team_bg_img2.png" alt="">
        </div>

        <!-- ppt 下载 -->
        <div class="d-ppt-download">
            <span class="icon-ppt"></span>
            <p class="f30 white lh30">竞赛PPT 下载</p>
            <a class="ppt-download-btn f16 kgc_aC" href="http://download.bdqn.cn/zhuanti/ppt/2021/6.pptx">点击下载</a>
        </div>

        <!-- 项目亮点 -->
        <div class="d-project-sup">
            <div class="d-module-title kgc_aC kgc_mt50">
                <p class="name dis-i f36 strong">
                    <span class="text">项目亮点</span>
                    <span class="num-bg">04</span>
                </p>
            </div>
            <div class="d-sup-info">
                <div class="d-sup-info-box">
                    <p>
                        智能采集分析系统，有利于提高工作效率，减轻工作人员负担。
                        项目组在学习范围外主动应用互联网主流技术并能够根据实际场景给出恰当的解决方案：通过AI人脸识别辅助数据采集，通过redis缓存技术和定时任务主动清理阿里OSS无效图片。
                    </p>
                </div>
            </div>
        </div>

        <!-- copyright -->
        <div class="copyright">
            京ICP备15057271号 京公网安备11010802017390号
        </div>
    </div>
</template>

<script>
// import '@/styles/common.css'
export default {
  mounted() {
  	document.title = '合肥一元数智教育有限公司'
  },
}
</script>

<style lang="scss" scoped>
.kgc-ofx-h {
    overflow-x: hidden
}

.f16 {
    font-size: 16px;
}

.kgc_lh30,
.lh30 {
    line-height: 30px
}

.kgc_mt50,
.top50 {
    margin-top: 50px
}

.kgc_w {
    width: 100%
}

.kgc_mt20 {
    margin-top: 20px;
}

.kgc_mt30 {
    margin-top: 30px;
}

.kgc_h {
    height: 100%
}

.kgc_mt30,
.top30 {
    margin-top: 30px
}

.dis-i {
    display: inline-block
}

.acenter,
.kgc_aC {
    text-align: center
}

.white,
.white a {
    color: #fff
}

.v-m {
    vertical-align: middle
}

.strong,
strong {
    font-weight: 700
}

.f36 {
    font-size: 36px
}

.f30 {
    font-size: 30px;
}

.f18 {
    font-size: 18px;
}

.kgc_mr15 {
    margin-right: 15px
}

body {
    background: #fff;
}

.w1240 {
    width: 1240px;
}

/* header */
.it-page-header {
    background-color: #fff;
    height: 90px;
}

.it-page-header .header-content {
    overflow: hidden;
}

.it-page-header .it-logo {
    margin-top: 16px;
}

.it-page-header .it-tel {
    padding-top: 36px;
    color: #898989;
}

.it-page-header .it-tel .num {
    font-size: 22px;
}

/* banner */
.it-banner {
    overflow: hidden;
    height: 693px;
}

.it-banner img {
    top: 0;
    left: 50%;
    margin-left: -960px;
}

/* èµ›äº‹ä»‹ç» */
.it-com-info {
    height: 635px;
    background-color: #f9faff;
}

.it-com-info .com-content {
    position: relative;
    z-index: 1;
    height: 500px;
    margin-top: -95px;
    box-sizing: border-box;
    border-radius: 15px;
    padding: 40px 30px 0;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    color: #3f4067;
    line-height: 36px;
    //   background: url(../../assets/img/project/com_content_bg.png) no-repeat right bottom #fff;
}

.it-com-info .com-content_title {
    height: 40px;
    font-size: 30px;
    line-height: 40px;
    //   background: url(../../assets/img/project/com_content_title_bg.png) no-repeat center top;
}

.it-com-info .com-content_text p {
    margin-top: 30px;
    text-indent: 2em;
}

/* èŽ·å¥–é¡¹ç›® */
.award-project {
    padding-top: 115px;
    box-sizing: border-box;
    height: 800px;
    //   background: url(../../assets/img/project/project_bg.jpg) no-repeat center bottom;
}

.date-nav {
    padding-left: 25px;
}

.date-nav ul {
    border-bottom: 1px solid #e5e5e5;
}

.date-nav ul li {
    color: #1677ff;
    font-size: 18px;
    margin-right: 100px;
    border-bottom: 2px solid transparent;
    padding-bottom: 20px;
    float: left;
    cursor: pointer;
}

.date-nav ul li:last-child {
    margin-right: 0;
}

.date-nav ul li.active {
    border-color: #1677ff;
}

.project-type-nav {
    top: 150px;
    left: 0;
    width: 250px;
    height: 380px;
    border-radius: 12px 0 12px 0;
    overflow: hidden;
    //   background: url(../../assets/img/project/project_type_nav_bg.png) no-repeat right bottom #1a5cff;
}

.project-type-nav .project-type-item {
    height: 90px;
    line-height: 90px;
    padding-left: 40px;
    font-size: 22px;
    color: #fff;
    cursor: pointer;
}

.project-type-nav .project-type-item span {
    right: 30px;
    top: 40px;
    width: 21px;
    height: 8px;
    //   background: url(../../assets/img/project/icon_arrow.png) no-repeat center;
    display: none;
}

.project-type-nav .project-type-item.active,
.project-type-nav .project-type-item:hover {
    background-color: #ffc079;
    color: #534038;
}

.project-type-nav .project-type-item.active span,
.project-type-nav .project-type-item:hover span {
    display: block;
}

.project-list-box {
    width: 1132px;
    height: 480px;
    margin-left: 108px;
    margin-top: 50px;
    padding: 35px 0;
    background-color: #fff;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    box-sizing: border-box;
}

.project-list-box .table-box {
    height: 440px;
    overflow-y: auto;
}

.project-list-box table {
    width: 960px;
    margin-left: 142px;
    text-align: center;
}

.project-list-box table tr {
    height: 60px;
}

.project-list-box table th,
.project-list-box table td {
    text-align: center;
}

.project-list-box table th {
    font-size: 20px;
    color: #1e61ff;
}

.project-list-box table td {
    color: #3f4067;
    font-size: 16px;
}

.project-list-box .award-name {
    width: 280px;
}

.project-list-box .go-detail {
    width: 95px;
    height: 34px;
    line-height: 34px;
    border-radius: 17px;
    display: inline-block;
    color: #999999;
}

.project-list-box .go-detail:hover {
    background-color: #ffbe78;
    color: #534038;
}

.copyright {
    height: 60px;
    line-height: 60px;
    text-align: center;
    font-size: 14px;
    color: #929ba4;
    background-color: #0d1a26;
}

/* è¯¦æƒ…é¡µcss */
.d-module-title .name {
    position: relative;
    color: #474c65;
    height: 120px;
    line-height: 120px;
}

.d-module-title .name .text {
    position: relative;
    z-index: 1;
}

.d-module-title .name .text::after,
.d-module-title .name .text::before {
    position: absolute;
    top: 18px;
    content: '';
    width: 66px;
    height: 12px;
}

.d-module-title .name .text::after {
    right: -80px;
    background: url(../../assets/img/project/06.png) no-repeat;
}

.d-module-title .name .text::before {
    left: -80px;
    background: url(../../assets/img/project/05.png) no-repeat;
}

.d-module-title .name .num-bg {
    font-size: 150px;
    color: #f8f8f9;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, 0);
    letter-spacing: -15px;
}

.d-module-title .name .num-bg.dark {
    color: #342a5a;
}

.d-module-title .text.white {
    color: #fff;
}

/* .d-module-title .text.white::after {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEIAAAAMCAYAAADWBmv0AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA3FpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDplNDUzNTJhOS1mMjliLWRjNGItODA1YS0wZGE3MjUwMzEzN2UiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MzA3MTEyOUM2OEYzMTFFQkJBQkI5NDFFQjczNDVBQjciIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MzA3MTEyOUI2OEYzMTFFQkJBQkI5NDFFQjczNDVBQjciIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjNhZjUwZmQwLThkOGItMWE0Yy1iNzVhLTI0MzJjMWI2OTQ0YSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDplNDUzNTJhOS1mMjliLWRjNGItODA1YS0wZGE3MjUwMzEzN2UiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz5JwrQcAAAAZElEQVR42mL8//+/FAMCPGPADnyR2JtxqGnAwUYGM5HY6TjUbMZhLzI4g8Q2waEG2S9SONT8hzGYGEbBaEAgA0Zg1hgNhdEUMRoQowExGhCjAUEcYEFrbIw2qEazxigAA4AAAwBSwhYRQp3STwAAAABJRU5ErkJggg==) no-repeat;
}
.d-module-title .text.white::before {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEIAAAAMCAYAAADWBmv0AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA3FpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDplNDUzNTJhOS1mMjliLWRjNGItODA1YS0wZGE3MjUwMzEzN2UiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6M0FDOTgwRDI2OEYzMTFFQkExMDdCMkE5OEFEOEE1NTIiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6M0FDOTgwRDE2OEYzMTFFQkExMDdCMkE5OEFEOEE1NTIiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjNhZjUwZmQwLThkOGItMWE0Yy1iNzVhLTI0MzJjMWI2OTQ0YSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDplNDUzNTJhOS1mMjliLWRjNGItODA1YS0wZGE3MjUwMzEzN2UiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz6sHP9WAAAAYklEQVR42mL8DwQMCMDIgB08Q2JL4VBzBoltgkPNZiS2Lw41M5HY6TjUNOBgM+AwfzMONXC/MDGMgtGAQAaMqDljNEWMBsRoEIwGxGhAjAYEHsACxKMNqtEUMZo1MABAgAEATcMUR/Wy9kwAAAAASUVORK5CYII=) no-repeat;
} */
.d-page-banner {
    height: 349px;
    overflow: hidden;
}

.d-page-banner img {
    top: 0;
    left: 50%;
    margin-left: -960px;
}

.d-page-banner .project-name {
    left: 50%;
    top: 99px;
    line-height: 36px;
    margin-left: -600px;
}

/* é¡¹ç›®ä»‹ç» */
.project-introduction {
    height: auto;
    background: #fff;
    padding-bottom: 125px;
}

.project-introduction .project-introduction-content {
    position: relative;
    z-index: 1;
    margin: -125px auto 0;
    width: 1307px;
    height: auto;
    padding: 28px 30px 50px 40px;
    box-sizing: border-box;
    background: #fff;
    border-radius: 15px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
}

.project-introduction .icon-point {
    position: absolute;
    left: 50px;
    top: 50px;
    width: 95px;
    height: 80px;
    background: url(../../assets/img/project/09.png) no-repeat;
}

.project-introduction .project-introduction-info {
    font-size: 16px;
    color: #546682;
    line-height: 46px;
}

/* è§†é¢‘å±•ç¤º */
.d-project-video {
    height: 666px;
    background: url(../../assets/img/project/d_project_video_bg.jpg) no-repeat center;
}

.d-project-video .d-p-video-box {
    width: 750px;
    height: 420px;
    margin: 30px auto 0;
}

.d-project-video .d-play-box {
    position: absolute;
    z-index: 2;
    cursor: pointer;
}

.d-project-video .d-play-box .play-icon {
    position: absolute;
    width: 83px;
    height: 83px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

/* å›¢é˜Ÿç…§ç‰‡ */
.d-team-pic {
    height: 735px;
    background-color: #fff;
    overflow: hidden;
}

.d-team-pic .pic-box {
    width: 740px;
    height: 400px;
    margin: 45px auto 0;
    border-radius: 15px;
    overflow: hidden;
    position: relative;
    z-index: 1;
}

.d-team-pic .team-bg-img1 {
    top: 0;
    left: 50%;
    margin-left: -725px;
}

.d-team-pic .team-bg-img2 {
    bottom: -15px;
    left: 50%;
    margin-left: 345px;
}

.d-ppt-download {
    height: 260px;
    padding: 50px 0 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background: url(../../assets/img/project/d_ppt_download_bg.jpg) no-repeat center;
}

.d-ppt-download span {
    width: 53px;
    height: 52px;
    background: url(../../assets/img/project/icon_ppt.png) no-repeat;
}

.d-ppt-download .ppt-download-btn {
    width: 106px;
    height: 34px;
    line-height: 34px;
    border-radius: 5px;
    color: #4e342e;
    background-color: #ffcd87;
}

/* é¡¹ç›®äº®ç‚¹ */
.d-project-sup {
    height: 527px;
    overflow: hidden;
    background: linear-gradient(to bottom, #ffffff 15%, #e0e9f9);
}

.d-project-sup .d-sup-info {
    margin: 15px auto 0;
    width: 1306px;
    height: 348px;
    padding: 60px 45px;
    background-color: #fff;
    border-radius: 15px 15px 0 0;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
}

.d-project-sup .d-sup-info-box {
    color: #546682;
    font-size: 16px;
    line-height: 36px;
}

.pos-rel {
    position: relative;
}

.yui3-u-1-2.pos-rel .pro_share .bdsharebuttonbox .bds_more {
    display: block;
    border: 1px solid #ccc !important;
    width: 24px !important;
    margin: 0 !important;
    font-size: 12px !important;
    cursor: pointer !important;
    padding-left: 4px !important
}

.pos-abs {
    position: absolute
}</style>