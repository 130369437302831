<template>
    <div class="Layout-page">
        <div class="page-container">
            <PageHeader />
            <!-- 头部 -->

            <Head :nav="false"> </Head>
            <Nav>
                <li></li>
                <li><router-link to="/index">首页</router-link></li>
                <li><router-link to="/bigdata">大数据课程</router-link></li>
                <li><router-link to="/java">Java课程</router-link></li>
                <li><router-link to="/web">Web前端</router-link></li>
                <li><router-link to="/ui">UI设计</router-link></li>
                <li><router-link to="">最新资讯</router-link></li>
            </Nav>

            <!-- banner -->

           <!-- <Banner arrow="never">
                <el-carousel-item v-for="item in banner" :key="item.id">
                    <img :src="item.img" alt="" height="485" />
                </el-carousel-item>
            </Banner> -->
            <main>
                <!-- 提前了解 -->
                <AdvanceUnderstand :list="advList" btn-color="#5526CA">
                    <div style="font-size: 50px">
                        <img
                            src="@/assets/img/classes/common/num-1.png"
                            alt=""
                            width="50px"
                            style="vertical-align: text-bottom"
                        />
                        提前了解Java ，<span style="color: #ff5f18">未来职场不迷茫</span>
                    </div>
                </AdvanceUnderstand>
                <why-study></why-study>
                <!-- <trandsCharts
                    title="Java工程师技能发展路径"
                    info="推Java培训扁级软件工程师课程，较传统课程知识量和技术深度增加50%，更贴合大厂企业技术要求，助力学员高起点就业"
                    target="高级软件工程师"
                    :skill="skill"
                /> -->
                <div class="charts">
                    <div class="bg-blue"></div>
                    <img src="@/assets/img/classes/java/java-chart.png" alt="" />
                </div>
                <latest-outline :outline="outline" :plates="plates"></latest-outline>
                <Crowd />
                <development />
                <truthProject :projects="projects" />
                <nextStarVue :module="figures" :list="grStu" />
                <famousTeacher :imgUrl="teacherImg" :textTitle="textTitle" :textContent="textContent" />
                <graduate :banner="figures" :student="grStu" />
                <flowChart />
                <employment />
                <doubt />
            </main>
            <page-footer>
                <p>皖ICP备13012660号-1如有图片侵权请及时联系本站，将及时删错或更改</p>
                <p>合肥一元教育咨询有限公司版权所有</p>
            </page-footer>
        </div>
    </div>
</template>

<script>
import Head from '@/components/head.vue'
import PageHeader from '@/components/pageHeader.vue'
import Nav from '@/components/nav.vue'
import AdvanceUnderstand from '../../../components/advance-understand.vue'
import Banner from '@/components/banner.vue'
import whyStudy from '../Java/components/whyStudy.vue'
import trandsCharts from '@/components/trands-charts.vue'
import latestOutline from '@/components/latest-outline.vue'
import Crowd from '@/components/crowd.vue'
import development from './components/development.vue'
import truthProject from '@/components/truth-project.vue'
import nextStarVue from '@/components/next-star.vue'
import famousTeacher from '@/components/famous-teacher.vue'
import graduate from '@/components/graduate.vue'
import flowChart from '@/components/flow-chart.vue'
import employment from '@/components/employment.vue'
import doubt from '@/components/doubt.vue'
import pageFooter from '@/components/pageFooter.vue'
import { getFigures, getProject, getStudent, getTeacher, getModule } from '@/api/class'
import { strToArray } from '@/utils'
// import { getBanner } from '@/api/home'
export default {
    data() {
        return {
            name: 'java',
            banner: '',
            advList: [
                {
                    id: 1,
                    name: 'Java值得报培训班吗？'
                },
                {
                    id: 2,
                    name: 'Java培训班包住吗？'
                },
                {
                    id: 3,
                    name: 'Java培训班难吗？'
                },
                {
                    id: 4,
                    name: '0基础可以学Java吗？'
                },
                {
                    id: 5,
                    name: 'Java主要学习什么内容？'
                },
                {
                    id: 6,
                    name: 'java培训有什么优势？'
                },
                {
                    id: 7,
                    name: 'Jaa培训先交钱吗？'
                },
                {
                    id: 8,
                    name: 'Java学不会怎么办？'
                },
                {
                    id: 9,
                    name: '学习Java月工资有多少？'
                }
            ],
            plates: [],
            outline: [
                {
                    id: 1,
                    title: 'Java核心技术',
                    skill: [
                        'ava基础语法',
                        'Java数据类型',
                        'Java条件分支',
                        'Java循环语句',
                        'Java方法',
                        'Java数组',
                        'Java面向对象',
                        '包装类和字符串',
                        '常用类、枚举、异常',
                        'Java集合',
                        'I/o流',
                        '多线程',
                        '网络通信',
                        'Java新特性',
                        '反射、注解、JSON、 XML',
                        'MySQL',
                        'JDBC'
                    ],
                    ability: [
                        '1、掌握Java开发环境基本配置;',
                        '2、掌握运算符、表达式、流程控制语句、数组等的使用;',
                        '3、熟练使用IDEA开发工具;',
                        '4、熟练掌握Java语法以及面向对象;',
                        '5、掌握常用类String、ArrayList等的使用;',
                        '6、可以搭建Java项目工程并进行控制台项目的开发;',
                        '7、熟练操作数据库以及数据库的基本语法格式;8、可胜任的岗位:初级JAVA软件工程师;',
                        '9、可拥有的市场前景:熟练掌握之后，可以满足企业对初级软件工程师的需求.但是还需要进行前端页面以及网站开发的学习才能符合目前企业的用'
                    ]
                },
                {
                    id: 2,
                    title: 'JavaWEB核心技术',
                    skill: [
                        '前瑞HTML CSS H5',
                        '前端JS',
                        'JSP、EL表达式',
                        'JSTL',
                        'Ajax+jQuery',
                        'Boostrap',
                        'VUE',
                        'ElementUI',
                        'SERVLET',
                        'Filter&Listener',
                        '文件上传和下载'
                    ],
                    ability: [
                        '1、熟练地在Java中使用JSON 与XML两种数据格式;',
                        '2、熟悉Web开发中常用知识如HTML5、CSS3、JavaScript、BootStrap、JQuery等;3、掌握JavaWeb开发核心技术Servlet、Listener、Filter等;',
                        '4、掌握 MyBatis数据库持久层框架;',
                        '5、熟悉Linux服务器,并安装开发常用软件Tomcat等;',
                        '6、掌握同步及异步操作的JavaWeb开发，具备B/S结构软件开发能力，完成基本的JavaWeb项目;7、熟练掌握web开发的相关流程,以及所用到的知识,进行web项目开发;',
                        '8、可胜任的岗位:初级JAVA软件工程师,数据库开发工程师;',
                        '9、可拥有的市场前景:熟练掌握之后，可以满足企业对初级软件工程师的需求能够进行数据存储过程的研发.能够完成企业使用jsp进行的项目的研发但是,还需要进行框架的学习才能符合目前企业的用人需求。'
                    ]
                },
                {
                    id: 3,
                    title: '企业级框架技术',
                    skill: [
                        'Git',
                        'Oracle',
                        'MyBatis',
                        'Spring5',
                        'SpringMVC',
                        'Spring Security',
                        'Linux操作系统',
                        '阿里云对象存储Oss',
                        '阿里云短信服务接口',
                        '七牛云',
                        '支付接口'
                    ],
                    ability: [
                        '1、掌握Spring-IOC, AOP核心思想;1、掌握SpringMVC表现层框架;1、掌握SSM 框架并进行整合;',
                        '1、熟练使用Git 进行代码管理与版本控制;1、熟练使用Maven项目管理工具;',
                        '1、熟练掌握Oracle数据;',
                        '1、可胜任的岗位:JAVAEE软件工程师、JAVAWEB开发工程师、中级软件工程师，需求分析师、PMP做具备的知识;',
                        '1、可拥有的市场前景:熟练掌握之后，可以满足企业对中级软件工程的需求,能进行ssm进行软件研发。'
                    ]
                },
                {
                    id: 4,
                    title: '分布式微服务核心技术',
                    skill: [
                        'SpringBoot',
                        'zookeeper',
                        'Dubbo',
                        'ElasticSearch',
                        'RocketMQ',
                        'MyBatis-plus',
                        'Redis',
                        'docker',
                        'Spring Cloud',
                        'Linux'
                    ],
                    ability: [
                        '1、能够掌握SpringBoot开发;',
                        '2、掌握Dubbo框架的架构;',
                        '3、掌握Dubbo服务提供者和消费者开发;4、掌握ZooKeeper使用;',
                        '5、能够掌握RocketMO消息队列的使用;',
                        '6、能够掌握使用ElasticSearch完成大数据量告诉检索;7、能够掌握使用Mybatis-Plus快速完成持久层开发;',
                        '8、掌握Redis使用;',
                        '9、能够熟悉什么是微服务项目;',
                        '10、能够掌握使用SpringCloud组件进行微服务的开发;11、能够掌握使用docker来完成容器化部署;'
                    ]
                }
            ],
            projects: [
                {
                    id: 1,
                    img: require('@/assets/img/classes/java/p-1.png'),
                    title: '智慧医疗云平台',
                    context:
                        '简介:该项目是供在线少儿编程教育平台对外提供的学习教育网站,及教育平台内部人员使用的后台管理系统。前台主要功能有兔费课程展示,名师展示,相关文章展示,...'
                }
            ],
            teacherImg: require('@/assets/img/classes/java/孟顺老师.png'),
            textTitle: '孟老师Java高级讲师',
            textContent:
                '10年Java软件开发及相关教育工作,熟悉JavaEE技术体系，精通Java开发技术（HTML、JSP/Servlet、JDBC、MySQL、Redis、 jQue-ry.SSH框架、SSM框架、adminLTE等)。热衷于主流Java开源框架技术,尤以擅长JavaWEB技术，对jQuery有深入的研究。熟悉分布式．缓存、消息等机制，并且有丰富的IT项目管理经验。: Vue2, Vue3，Node.js, koa2框架Three.js 3D开发，Python开发经验，对移动应用和流行框架有深入研究。',
            grStu: [
                {
                    name: '孙*平',
                    salay: '月薪17000+ 五险一金',
                    company: '浙江**技术',
                    edu: '专科',
                    img: require('@/assets/img/classes/bigdata/pe-1.png')
                },
                {
                    name: '苏',
                    salay: '月薪15000+ 五险一金+包住',
                    company: '上海**软件',
                    edu: '专科',
                    img: require('@/assets/img/classes/bigdata/pe-1.png')
                },
                {
                    name: '廉*',
                    salay: '月薪15000+ 五险一金+餐补',
                    company: '浙江**技术',
                    edu: '本科',
                    img: require('@/assets/img/classes/bigdata/pe-1.png')
                },
                {
                    name: '张*永',
                    salay: '月薪14000+ 五险一金',
                    company: '南京**网络科技',
                    edu: '专科',
                    img: require('@/assets/img/classes/bigdata/pe-1.png')
                },
                {
                    name: '张*鸣',
                    salay: '月薪12000+ 五险一金',
                    company: '上海**信息科技',
                    edu: '专科',
                    img: require('@/assets/img/classes/bigdata/pe-1.png')
                },
                {
                    name: '尹*泉',
                    salay: '月薪12000+ 五险一金',
                    company: '上海**通信科技',
                    edu: '专科',
                    img: require('@/assets/img/classes/bigdata/pe-1.png')
                }
            ],
            figures: []
        }
    },
    components: {
        Head,
        PageHeader,
        Nav,
        AdvanceUnderstand,
        Banner,
        whyStudy,
        trandsCharts,
        latestOutline,
        Crowd,
        development,
        truthProject,
        nextStarVue,
        famousTeacher,
        graduate,
        flowChart,
        employment,
        doubt,
        pageFooter
    },
    mounted() {
    	document.title = '合肥一元数智教育有限公司'
    },created() {
        this.getModule()
        // this.getBanner()
        this.getProject()
        this.getStudent()
        this.getFigures()
        // this.getTeacher()
    },
    methods: {
        // async getBanner() {
        //     this.banner = await getBanner(this.name)
        //     console.log(this.banner)
        // },
        async getProject() {
            const list = await getProject(this.name)
            this.projects = list.filter((item, index) => {
                if (index <= 4) {
                    return item
                }
            })
        },
        async getModule() {
            const r = await getModule(this.name)
            console.log(r)
            this.outline = r.map(item => {
                item.skill = strToArray(item.skill)
				// item.ability = strToArray(item.ability)
                this.plates.push(item.module)
                return item
            })
            console.log(this.outline)
        },
        async getStudent() {
            const list = await getStudent(this.name)
            const grStus = list.map(item => {
		    item.name = item.name.split(',')
            return item
      })
	       this.grStu=grStus.filter(item=>item.name[1]=='java')
        },
        async getTeacher() {
            // const res = await getTeacher(this.name)
            // console.log(res)
            // if (res instanceof Array) {
            //     this.teacherImg = res[0].img
            //     this.textTitle = res[0].name
            //     this.textContent = res[0].introduction
            // } else if (res instanceof Object) {
            //     this.teacherImg = res.img
            //     this.textTitle = res.name
            //     this.textContent = res.introduction
            // }
			const { name, introduction, img } = await getTeacher(this.name)
			this.teacherImg = img
			this.textTitle = name
			this.textContent = introduction
			 console.log(this.teacherImg)
        },
        async getFigures() {
            this.figures = await getFigures(this.name)
        }
    }
}
</script>
<style scoped lang="scss">
.charts {
    position: relative;
    margin-top: 60px;
    .bg-blue {
        position: absolute;
        width: 1920px;
        height: 100%;
        background-color: #216df4;
        left: -350px;
        z-index: -1;
    }
    img {
        margin-top: 30px;
        width: 1200px;
    }
}
</style>
