import BigData from '@/views/Classes/BigData/index.vue'
import WebClass from '@/views/Classes/WebClass/index.vue'
import Java from '@/views/Classes/Java/index.vue'
import ui from '@/views/Classes/ui/index.vue'
export default [
    {
        path: '/bigdata',
        component: BigData,
        name: 'bigdate',
    },
    {
        path: '/web',
        component: WebClass,
        name: 'webclass',
    },
    {
        path: '/java',
        component: Java,
        name: 'java',
    },
    {
        path: '/ui',
        component: ui,
        name: 'ui',
    },

]