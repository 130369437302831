<template>
    <div class="Layout-page">
        <div class="page-container">
            <PageHeader />
            <!-- 头部 -->

            <Head :nav="false"> </Head>
            <Nav>
                <li></li>
                <li><router-link to="/index">首页</router-link></li>
                <li><router-link to="/bigdata">大数据课程</router-link></li>
                <li><router-link to="/java">Java课程</router-link></li>
                <li><router-link to="/web">Web前端</router-link></li>
                <li><router-link to="/ui">UI设计</router-link></li>
                <li><router-link to="">最新资讯</router-link></li>
            </Nav>

            <!-- banner -->

           <!-- <Banner arrow="never">
                <el-carousel-item v-for="item in banner" :key="item.id">
                    <img :src="item.img" alt="" height="485" />
                </el-carousel-item>
            </Banner> -->
            <main>
                <!-- 提前了解 -->
                <AdvanceUnderstand :list="advList">
                    <div style="font-size: 50px">
                        <img
                            src="@/assets/img/classes/common/num-1.png"
                            alt=""
                            width="50px"
                            style="vertical-align: text-bottom"
                        />
                        提前了解前端 ，<span class="text-blue">未来职场不迷茫</span>
                    </div>
                </AdvanceUnderstand>

                <!--为什么要学前端  -->
                <why-study />

                <!-- 发展路径 -->
                <TrandsCharts />
                <!-- 最新课程大纲 -->
                <latestOutline :outline="outline" :plates="plates" />

                <!-- 适合人群 -->
                <Crowd />
                <!-- 职业发展路径 -->
                <Development />
                <!-- 真实项目 -->
                <TruthProject :projects="project" />
                <!-- 下一个就业明星 -->
                <NextStar :list="students" :module="figures" />
                <!-- 教师的简介 -->
                <FamousTeacher :imgUrl="teacherImg" :textTitle="textTitle" :textContent="textContent" />

                <!-- 就业流程图 -->
                <flow-chart></flow-chart>

                <!-- 名企直招  环境-->
                <Employment />

                <!-- 疑问 -->
                <Doubt />
            </main>
            <!-- 页脚 -->
            <PageFooter>
                <p>皖ICP备13012660号-1如有图片侵权请及时联系本站，将及时删错或更改</p>
                <p>合肥一元教育咨询有限公司版权所有</p>
            </PageFooter>
        </div>
    </div>
</template>

<script>
import Head from '@/components/head.vue'
import PageHeader from '@/components/pageHeader.vue'
import Nav from '@/components/nav.vue'
import AdvanceUnderstand from '../../../components/advance-understand.vue'
import Banner from '@/components/banner.vue'
import whyStudy from './components/why-study.vue'
import latestOutline from '@/components/latest-outline.vue'
import FamousTeacher from '@/components/famous-teacher.vue'
import Crowd from '@/components/crowd.vue'
import Development from './components/development.vue'
import TruthProject from '../../../components/truth-project.vue'
import NextStar from '../../../components/next-star.vue'
import Employment from '@/components/employment.vue'
import TrandsCharts from '@/components/trands-charts.vue'
import FlowChart from '@/components/flow-chart.vue'
import PageFooter from '@/components/pageFooter.vue'
import Doubt from '@/components/doubt.vue'
// import { getBanner } from '@/api/home'
import { getFigures, getProject, getStudent, getTeacher, getModule } from '@/api/class'
import { strToArray } from '@/utils'

export default {
    data() {
        return {
            name: '前端',
            advList: [
                {
                    id: 1,
                    name: '如何快速学前端？'
                },
                {
                    id: 2,
                    name: 'web前端需要哪些技术？'
                },
                {
                    id: 3,
                    name: '前端和后端有什么区别'
                },
                {
                    id: 4,
                    name: '从开始到就业要学多久？'
                },
                {
                    id: 5,
                    name: '前端学习有要求吗？'
                },
                {
                    id: 6,
                    name: '如何获取免费前端课程？'
                },
                {
                    id: 7,
                    name: '前端工程师工资多少'
                },
                {
                    id: 8,
                    name: '前端机构哪家好？'
                },
                {
                    id: 9,
                    name: '前端和后端哪个更好就业？'
                }
            ],
            teacherImg: require('@/assets/img/classes/web-img/李文才老师.png'),
            textTitle: '李老师前端高级讲师',
            textContent:
                '全栈开发工程师，国家工信部高级web开发工程师，有丰富的企业级应用架构以及开发经验，12年开发和管理经验及授课经验。技术方向: Vue2, Vue3，Node.js, koa2框架Three.js 3D开发，Python开发经验，对移动应用和流行框架有深入研究。',
            banner: [],
            plates: [],
            project: [],
            students: [],
            figures: [],
            outline: []
        }
    },
    components: {
        Head,
        PageHeader,
        Nav,
        AdvanceUnderstand,
        Banner,
        whyStudy,
        latestOutline,
        FamousTeacher,
        Crowd,
        Development,
        TruthProject,
        NextStar,
        PageFooter,
        Doubt,
        Employment,
        // Graduate,
        FlowChart,
        TrandsCharts
    },
    mounted() {
    	document.title = '合肥一元数智教育有限公司'
    },
    created() {
        // this.getBanner()
        this.getProject()
        this.getModule()
        this.getStudent()
        this.getFigures()
    },
    methods: {
        // async getBanner() {
        //     this.banner = await getBanner(this.name)
        //     // console.log(this.banner)
        // },
        async getProject() {
            const list = await getProject(this.name)
            this.project = list.filter((item, index) => {
                if (index <= 4) {
                    return item
                }
            })
        },
        async getModule() {
            const r = await getModule(this.name)
            this.outline = r.map(item => {
                item.skill = strToArray(item.skill)
                this.plates.push(item.module)
                return item
            })
            console.log(this.outline)
        },

        async getStudent() {
            const list = await getStudent(this.name)
            const studentss = list.map(item => {
		    item.name = item.name.split(',')
            return item
      })
	  this.students=studentss.filter(item=>item.name[1]=='前端')
        },
        async getTeacher() {
            const { name, introduction, img } = await getTeacher(this.name)
            this.teacherImg = img
            this.textTitle = name
            this.textContent = introduction
        },
        async getFigures() {
            this.figures = await getFigures(this.name)
        }
    }
}
</script>
<style scoped lang="scss"></style>
