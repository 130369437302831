<template>
  <div class="section clear">
    <div class="section-title">
      大数据<span class="text-blue">三大主流技术</span>
    </div>
    <div class="section-content">
      <el-row class="content-top" type="flex" justify="center">
        <el-col :span="6">
          <span :class="{ active: currentIndex == 1 }" @mouseover="change(1)"
            >数据采集</span
          >
        </el-col>
        <el-col :span="6">
          <span :class="{ active: currentIndex == 2 }" @mouseover="change(2)"
            >数据储存与管理</span
          >
        </el-col>
        <el-col :span="6">
          <span :class="{ active: currentIndex == 3 }" @mouseover="change(3)"
            >数据处理与分析</span
          >
        </el-col>
      </el-row>
      <el-carousel
        indicator-position="none"
        :loop="false"
        arrow="never"
        :autoplay="false"
        ref="carousel"
      >
        <el-carousel-item>
          <div class="content-main">
            <div class="left">
              <div class="text">
                <div class="title">大数据主流技术 - 数据采集</div>
                <div>
                  <p>使用Flume，可进行流式日志数据的收集。</p>
                  <p>使用Sqoop可以交互关系型数据库，进行导入导出数据。</p>
                  <p>使用使用爬虫技术，可以在网上爬取。</p>
                </div>
              </div>
              <div class="img-info">
                <img
                  src="@/assets/img/classes/bigdata/technology-1.1.png"
                  alt=""
                />
                <img
                  src="@/assets/img/classes/bigdata/technology-1.2.png"
                  alt=""
                />
              </div>
            </div>
            <div class="right">
              <div class="block-blue"></div>
              <img
                src="@/assets/img/classes/bigdata/technology-1.3.png"
                alt=""
              />
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="content-main">
            <div class="left">
              <div class="text">
                <div class="title">大数据主流技术 - 数据存储与管理</div>
                <div>
                  <p>
                    大数B利用分布式文件系统HDFS.HBase。Hve，实现<br />
                    对达构化半喝构化和非感构化数期的存临和管理。
                  </p>
                </div>
              </div>
              <div class="img-info">
                <img src="@/assets/img/classes/bigdata/2_06.jpg" alt="" />
                <img src="@/assets/img/classes/bigdata/2_08.jpg" alt="" />
              </div>
            </div>
            <div class="right">
              <div class="block-green"></div>
              <img
                src="@/assets/img/classes/bigdata/2_03.jpg"
                alt=""
                width="406px"
              />
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="content-main">
            <div class="left">
              <div class="text">
                <div class="title">大数据主流技术 - 数据处理与分析</div>
                <div>
                  <p>
                    利用分布式井行编程模型和计算框架,结合机器学习和数<br />
                    据挖F算法,实现对海量数据的处理和分析。
                  </p>
                </div>
              </div>
              <div class="img-info">
                <img src="@/assets/img/classes/bigdata/3_06.jpg" alt="" />
                <img src="@/assets/img/classes/bigdata/3_08.jpg" alt="" />
              </div>
            </div>
            <div class="right">
              <div class="block-orange"></div>
              <img
                src="@/assets/img/classes/bigdata/3_03.jpg"
                alt=""
                width="406px"
              />
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>

      <div class="content-foot">
        <button>了解更多技术信息</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentIndex: 1,
    };
  },
  components: {},
  mounted() {},
  methods: {
    change(index) {
      this.currentIndex = index;
      this.$refs.carousel.setActiveItem(this.currentIndex - 1);
    },
  },
};
</script>
<style scoped lang='scss'>
.section {
  margin-top: 60px;
  .section-title {
    text-align: center;
    font-size: 36px;
    color: #333334;
    .text-blue {
      font-weight: 600;
    }
  }
  .section-content {
    height: 576px;
    margin-top: 15px;
    background-color: #f5f6fa;
    .content-top {
      height: 73px;
      line-height: 71px;
      text-align: center;
      font-size: 24px;
      border-bottom: 2px solid #c5c5c5;
      span {
        display: inline-block;
        padding: 0 30px;
        font-weight: bord;
      }
    }
    .active {
      color: #4666ff;
      border-bottom: 3px solid #4666ff;
    }
    .el-carousel,
    .el-carousel__item {
      height: 431px;
    }
    .content-main {
      display: flex;
      justify-content: space-between;
      height: 431px;

      .left {
        height: 100%;
        width: 500px;
        padding: 60px 0;
        box-sizing: border-box;
        .text {
          line-height: 30px;
          font-size: 16px;
          text-align: right;
          .title {
            font-size: 24px;
            font-weight: bold;
            margin: 0 16px 17px;
          }
        }
        .img-info {
          display: flex;
          justify-content: space-between;
          padding: 10px 0;
          img {
            height: 138px;
            border: 3px #ccc;
            border-style: dotted;
          }
        }
      }
      .right {
        position: relative;
        width: 560px;
        .block-green,
        .block-orange,
        .block-blue {
          position: absolute;
          top: 50%;
          width: 100%;
          height: 164px;
          background-image: linear-gradient(#4666ff, #758dff);
          transform: translateY(-50%);
          //   z-index: -1;
        }
        .block-green {
          background-image: linear-gradient(to right, #008b1e, #75ff93);
        }
        .block-orange {
          background-image: linear-gradient(to right, #8b1e00, #ffbc75);
        }
        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border: 10px solid #fff;
          border-radius: 10px;
          box-shadow: 0px 17px 79px 2px rgba(65, 65, 65, 0.32);
          z-index: 3;
        }
      }
    }
    .content-foot {
      text-align: center;
      padding-bottom: 12px;
      button {
        height: 58px;
        width: 345px;
        font-size: 24px;
        color: #fff;
        background-color: #4666ff;
        border-radius: 29px;
      }
    }
  }
}
</style>