<template>
    <div class="section">
        <div class="section-title">紧贴企业要求 打造<span class="text-blue">独特的课程模式</span></div>
        <div class="section-content">
            <div class="content-top">
                <ul>
                    <li
                        :class="{ active: currentIndex == item }"
                        v-for="(item, index) in module"
                        :key="index"
                        @mouseover="currentIndex = item"
                    >
                        {{ item }}
                    </li>
                </ul>
            </div>
            <div class="content-main">
                <p class="title">第一阶段课程：{{ viewObj.title }}</p>
                <div class="main">
                    <p style=" font-weight: bolder;">主要内容：</p>
                    <p>
                        {{ viewObj.skill }}
                    </p>
                    <p style=" font-weight: bolder;">核心能力培养：</p>
                    <p v-for="(item, index) in viewObj.ability" :key="index">{{ item }}</p>
                    <p></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        module: {
            type: Array,
            default() {
                return ['技术阶段一', '技术阶段二', '技术阶段三', '技术阶段四', '技术阶段五']
            }
        },
        outline: {
            type: Array,
            default() {
                return []
            }
        }
    },
    computed: {
        viewObj() {
            let result = this.outline.find(item => item.module == this.currentIndex)
            if (!result) {
                return {
                    title: '',
                    skill: '',
                    ability: ''
                }
            }
            console.log(result)

            return result
        }
    },
    data() {
        return {
            currentIndex: '技术阶段一'
        }
    },
    components: {},
    mounted() {},
    methods: {
        change(index) {
            this.currentIndex = index
        }
    }
}
</script>
<style scoped lang="scss">
.section {
    margin-top: 60px;
    .section-title {
        text-align: center;
        font-size: 36px;
        color: #333334;
        .text-blue {
            font-weight: 600;
        }
    }
    .section-content {
        margin-top: 27px;
        // height: 550px;
        border-radius: 10px;
        background-color: #eee;
        ul {
            display: flex;
            height: 100px;
            justify-content: space-between;
            li {
                height: 100px;
                line-height: 100px;
                width: 19.9%;
                text-align: center;
                font-size: 25px;
                color: #fff;

                background-color: #3bbd72;
            }
            li:first-child {
                border-radius: 10px 0 0 0;
            }
            li:last-child {
                border-radius: 0 10px 0 0;
            }
            .active {
                position: relative;
                background-color: #4666ff;
            }
            .active::after {
                position: absolute;
                bottom: -14px;
                left: 50%;
                transform: translateX(-50%);
                content: '';
                height: 22px;
                width: 15px;
                background: url(@/assets/img/classes/icon/small-triangle.png) no-repeat;
                background-position: center;
            }
        }
        .content-main {
            height: 550px;

            line-height: 30px;
            padding: 53px 0 53px 63px;
            box-sizing: border-box;
            overflow: hidden;
            .title {
                line-height: 45px;
                font-size: 25px;
                color: #4666ff;
            }

            p:first-child() {
                margin: 10px 0;
            }
        }
    }
}
</style>
