var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"footer-main"},[_vm._m(0),_c('div',{staticClass:"foot_nav"},[_c('dl',{staticStyle:{"margin-left":"60px"}},[_c('dt',[_vm._v("关于我们")]),_c('dd',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$router.push('/about')}}},[_vm._v("青鸟概况")])]),_vm._m(1),_c('dd',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$router.push('/teacher')}}},[_vm._v("了解课程")])]),_vm._m(2)]),_vm._m(3),_vm._m(4)]),_vm._m(5)]),_vm._m(6),_c('page-footer',[_c('p',[_vm._v("合肥一元教育咨询有限公司版权所有 如有图片侵权请及时联系本站，将及时删错或更改")]),_c('p',[_c('a',{attrs:{"href":"https://beian.miit.gov.cn"}},[_vm._v("皖ICP备13012660号-1")]),_vm._v(" 主营：java程序员培训 java编程培训 ui设计培训 web前端培训 前端开发培训 ")])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('img',{attrs:{"src":require("@/assets/img/index/common/kgc.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('dd',[_c('a',{attrs:{"href":"#"}},[_vm._v("师资力量")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('dd',[_c('a',{attrs:{"href":"#","onclick":"_hmt.push(['_trackEvent', '沟通','咨询链接', '商务通链接']);"}},[_vm._v("咨询学费")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('dl',[_c('dt',[_vm._v("教育服务")]),_c('dd',[_c('a',{attrs:{"href":"#","rel":"nofollow","target":"_blank"}},[_vm._v("就业保障")])]),_c('dd',[_c('a',{attrs:{"href":"#","rel":"nofollow","target":"_blank"}},[_vm._v("就业学员")])]),_c('dd',[_c('a',{attrs:{"href":"#","onclick":"_hmt.push(['_trackEvent', '沟通','咨询链接', '商务通链接']);","rel":"nofollow","target":"_blank"}},[_vm._v("免费试听")])]),_c('dd',[_c('a',{attrs:{"href":"#","onclick":"_hmt.push(['_trackEvent', '沟通','咨询链接', '商务通链接']);","target":"_blank"}},[_vm._v("报名流程")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('dl',{staticStyle:{"margin-right":"60px"}},[_c('dt',[_vm._v("业务合作")]),_c('dd',[_c('a',{attrs:{"href":"#","onclick":"_hmt.push(['_trackEvent', '沟通','咨询链接', '商务通链接']);","rel":"nofollow"}},[_vm._v("加入我们")])]),_c('dd',[_c('a',{attrs:{"href":"#","onclick":"_hmt.push(['_trackEvent', '沟通','咨询链接', '商务通链接']);","rel":"nofollow"}},[_vm._v("商务合作")])]),_c('dd',[_c('a',{attrs:{"href":"#","onclick":"_hmt.push(['_trackEvent', '沟通','咨询链接', '商务通链接']);","rel":"nofollow"}},[_vm._v("联系我们")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right"},[_c('img',{staticClass:"code",attrs:{"src":require("@/assets/img/index/common/weixin (1).png"),"alt":""}}),_c('div',{staticClass:"tel"},[_c('h3',[_c('img',{attrs:{"src":require("@/assets/img/index/common/foot_tel.png"),"alt":""}}),_vm._v(" 0551-69117050 ")]),_c('p',[_vm._v("咨询服务热线：8:00-23:00")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"foot_friend_link"},[_c('h3',[_vm._v("友情链接")]),_c('ul',[_c('li',[_c('a',{attrs:{"href":"http://www.kgcbdqn.com"}},[_vm._v("课工场")])]),_c('li',[_c('a',{attrs:{"href":"http://www.bdqnhf.com"}},[_vm._v("合肥校区")])]),_c('li',[_c('a',{attrs:{"href":"http://www.kgcbdqn.com"}},[_vm._v("蚌埠校区")])]),_c('li',{staticStyle:{"color":"#999"}},[_c('strong',[_vm._v("合肥校区：")]),_vm._v("合肥市庐阳区阜阳北路与北城大道交口（工投创智天地园区） ")]),_c('li',{staticStyle:{"color":"#999"}},[_c('strong',[_vm._v("蚌埠校区：")]),_vm._v("蚌埠市蚌山区东海大道万达广场A座6楼 ")])])])
}]

export { render, staticRenderFns }