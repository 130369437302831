<template>
    <div class="container">
        <div class="footer-main">
            <div class="left">
                <img src="@/assets/img/index/common/kgc.png" alt="" />
            </div>
            <div class="foot_nav">
                <dl style="margin-left: 60px">
                    <dt>关于我们</dt>
                    <dd><a href="#" @click.stop.prevent="$router.push('/about')">青鸟概况</a></dd>
                    <dd><a href="#">师资力量</a></dd>
                    <dd>
                        <a
                            href="#"
                            @click.stop.prevent="$router.push('/teacher')"
                            >了解课程</a
                        >
                    </dd>
                    <dd>
                        <a
                            href="#"
                            onclick="_hmt.push(['_trackEvent', '沟通','咨询链接', '商务通链接']);"
                            >咨询学费</a
                        >
                    </dd>
                </dl>
                <dl>
                    <dt>教育服务</dt>
                    <dd><a href="#" rel="nofollow" target="_blank">就业保障</a></dd>
                    <dd><a href="#" rel="nofollow" target="_blank">就业学员</a></dd>
                    <dd>
                        <a
                            href="#"
                            onclick="_hmt.push(['_trackEvent', '沟通','咨询链接', '商务通链接']);"
                            rel="nofollow"
                            target="_blank"
                            >免费试听</a
                        >
                    </dd>
                    <dd>
                        <a
                            href="#"
                            onclick="_hmt.push(['_trackEvent', '沟通','咨询链接', '商务通链接']);"
                            target="_blank"
                            >报名流程</a
                        >
                    </dd>
                </dl>
                <dl style="margin-right: 60px">
                    <dt>业务合作</dt>
                    <dd>
                        <a
                            href="#"
                            onclick="_hmt.push(['_trackEvent', '沟通','咨询链接', '商务通链接']);"
                            rel="nofollow"
                            >加入我们</a
                        >
                    </dd>
                    <dd>
                        <a
                            href="#"
                            onclick="_hmt.push(['_trackEvent', '沟通','咨询链接', '商务通链接']);"
                            rel="nofollow"
                            >商务合作</a
                        >
                    </dd>
                    <dd>
                        <a
                            href="#"
                            onclick="_hmt.push(['_trackEvent', '沟通','咨询链接', '商务通链接']);"
                            rel="nofollow"
                            >联系我们</a
                        >
                    </dd>
                </dl>
            </div>
            <div class="right">
                <img src="@/assets/img/index/common/weixin (1).png" alt="" class="code" />
                <div class="tel">
                    <h3>
                        <img src="@/assets/img/index/common/foot_tel.png" alt="" />
                        0551-69117050
                    </h3>

                    <p>咨询服务热线：8:00-23:00</p>
                </div>
            </div>
        </div>

        <div class="foot_friend_link">
            <h3>友情链接</h3>
            <ul>
                <li><a href="http://www.kgcbdqn.com">课工场</a></li>
                <li><a href="http://www.bdqnhf.com">合肥校区</a></li>
                <li><a href="http://www.kgcbdqn.com">蚌埠校区</a></li>
                <li style="color: #999">
                    <strong>合肥校区：</strong>合肥市庐阳区阜阳北路与北城大道交口（工投创智天地园区）
                </li>
                <li style="color: #999">
                    <strong>蚌埠校区：</strong>蚌埠市蚌山区东海大道万达广场A座6楼
                </li>
            </ul>
        </div>

        <page-footer>
            <p>合肥一元教育咨询有限公司版权所有 如有图片侵权请及时联系本站，将及时删错或更改</p>
            <p>
                <a href="https://beian.miit.gov.cn">皖ICP备13012660号-1</a>
                主营：java程序员培训 java编程培训 ui设计培训 web前端培训 前端开发培训
            </p>
        </page-footer>
    </div>
</template>

<script>
import PageFooter from "@/components/pageFooter.vue"

export default {
    data() {
        return {}
    },
    components: {
        PageFooter,
    },
    mounted() {},
    methods: {},
}
</script>
<style scoped lang="less">
.container {
    height: 400px;
    background-color: #404040;
    .footer-main {
        display: flex;
        width: 1200px;
        height: 250px;
        justify-content: space-around;
        align-items: center;
        margin: 0 auto;
        .left img {
            height: 57px;
        }
        .right {
            text-indent: 2px;
            font-size: 16px;
            color: #999;
            .code {
                margin: 0 0 10px 60px;

                width: 105px;
            }
            .tel {
                position: relative;
                width: 227px;
                line-height: 29px;
                h3 {
                    font-size: 24px;
                    overflow: hidden;
                }

                img {
                    width: 24px;
                    margin-bottom: -3px;
                }
            }
        }
    }
    .foot_nav {
        float: left;
        color: #999;
        font-size: 13px;
    }
    .foot_nav dl {
        float: left;
        margin-left: 120px;
    }
    .foot_nav dl dt {
        font-size: 18px;
        line-height: 28px;
        color: #e6e6e6;
        margin-bottom: 5px;
    }
    .foot_nav dl dd {
        font-size: 14px;
        line-height: 32px;
    }
    .foot_nav a {
        color: #bbb;
        text-decoration: none;
        transition: all 200ms ease-in-out;
    }
    .foot_friend_link {
        border-top: 1px solid #4d4d4d;
        padding-top: 10px;
        width: 1200px;
        margin: 0 auto;
        margin-bottom: 40px;

        overflow: hidden;
    }
    .foot_friend_link h3 {
        font-weight: normal;
        font-size: 16px;
        color: #999;
    }
    .foot_friend_link ul {
        margin-top: 10px;
    }
    ul,
    li {
        list-style: none;
    }
    .foot_friend_link ul li {
        float: left;
        margin-right: 30px;
        font-size: 15px;
    }
    .foot_friend_link ul li a {
        color: #999;
    }
}
</style>
