<template>
  <div class="Layout-page">
    <div class="page-container">
      <!-- 页头 -->
      <PageHeader />
      <!-- 头部 -->

      <Head :nav="true" :hover="true"> </Head>
      
      <div class="banner">
        <Banner arrow="never">
          <el-carousel-item>
            <img src="@/assets/img/stud/金牌.jpg" alt="" />
          </el-carousel-item>
        </Banner>
      </div>

      <Sid />
      <Sids />
      <Forms />
      <Sidebar />
      <Reason />
      <FooterNav />
      <PageFooter >
                <p>
          合肥一元教育咨询有限公司版权所有
          如有图片侵权请及时联系本站，将及时删错或更改
        </p>
        <p>
          <a href="https://beian.miit.gov.cn">皖ICP备13012660号-1</a>
          主营：java程序员培训 java编程培训 ui设计培训 web前端培训 前端开发培训
        </p>
      </PageFooter>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/pageHeader.vue";
import Head from "@/components/head.vue";
import Banner from "@/components/banner.vue";
import Sid from "./components/sid.vue";
import Sids from "./components/sids.vue";
import Forms from "./components/forms.vue";
import Reason from "./components/reasons.vue";
import Sidebar from "@/components/sidebar.vue";
import FooterNav from '@/components/footerNav.vue';
import PageFooter from '@/components/pageFooter.vue';


export default {
  name: "Stuent",
  data() {
    return {};
  },
  components: {
    PageHeader,
    Head,
    Banner,
    Sid,
    Sids,
    Forms,
    Reason,
    Sidebar,
    FooterNav,
    PageFooter,
  },
  mounted() {
  	document.title = '合肥一元数智教育有限公司'
  },
  methods: {},
};
</script>
<style scoped lang='less'>


</style>