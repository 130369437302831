export function strToArray(str) {

    console.log(str)
    if (typeof str == 'string') {
        str = str.replace(/'/g, '')
        str = str.replace(/\\[|]/g, '')
        console.log(1)
        if (str.includes("，")) {
            console.log(2)
            return str.split('，')
        } else if (str.includes(",")) {
            str.split(",")
            console.log(3)
            return str.split(",")
        } else if (str.includes("/")) {
            console.log(4)
            return str.split('/')
        } else if (str.includes("、")) {
            console.log(5)
            return str.split("、")
        }
        console.log(123)
    }
    return str

}
