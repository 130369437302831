<template>
    <div class="section clear">
        <div class="section-title">你是否还有以下<span class="text-blue">疑问</span>?</div>
        <div class="section-content">
            <div class="doubt-list">
                <div class="item" style="top: 0px; left: 0px">
                    <div class="doubt-1">学校地址在哪？</div>
                </div>
                <div class="item" style="top: 92px; left: 227px">
                    <div class="doubt-1">这门技术难学吗</div>
                </div>
                <div class="item" style="top: 0px; left: 800px">
                    <div class="doubt-1">怎么保证能学好呢？</div>
                </div>
                <div class="item" style="top: 200px; left: 702px">
                    <div class="doubt-1">零基础能学习这门技术吗？</div>
                </div>
                <div class="item" style="top: 98px; left: 652px">
                    <div class="doubt-1">学了不适合怎么办？</div>
                </div>
                <div class="item" style="top: 350px; left: 270px">
                    <div class="doubt-1">和北上广深比，咱们的教资水平如何呢？</div>
                </div>
                <div class="item" style="top: 175px; left: 0px">
                    <div class="doubt-1">学习这技术好就业吗？</div>
                </div>
                <div class="item" style="top: 300px; left: 900px">
                    <div class="doubt-1">什么时候开班？</div>
                </div>
                <div class="item" style="top: 00px; left: 300px">
                    <div class="doubt-1">学习这技术需要数学基础吗？</div>
                </div>
                <div class="item" style="top: 200px; left: 343px">
                    <div class="doubt-1">对学历要求高么？</div>
                </div>
                <img src="@/assets/img/classes/bigdata/p-1.png" alt="" class="p-1" />
                <img src="@/assets/img/classes/bigdata/p-2.png" alt="" class="p-2" />
                <button><a href="http://pdt.zoosnet.net/LR/Chatpre.aspx?id=PDT87252886&lng=cn" style="color:#fff;">找老师解答</a></button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {}
    },
    components: {},

    mounted() {},
    methods: {}
}
</script>
<style scoped lang="scss">
.section {
    margin-top: 100px;
    .section-title {
        text-align: center;
        font-size: 36px;
        color: #333334;
        .text-blue {
            font-weight: 600;
        }
    }
    .section-content {
        height: 706px;
        .doubt-list {
            position: relative;
            margin-top: 130px;
            height: 570px;
        }
        .item {
            display: inline-block;
            position: absolute;
        }
        .item:hover {
            animation: shake 1s 1;
        }
        .doubt-1 {
            position: relative;
            display: inline-block;
            height: 60px;
            line-height: 60px;
            border-radius: 60px;
            padding: 0 40px;
            background-color: #edf0f4;
            font-size: 19px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #333333;
            text-align: center;
            cursor: pointer;
        }
        .doubt-1::before {
            position: absolute;
            content: '';
            width: 40px;
            height: 40px;
            background: url(@/assets/img/classes/common/wen.png) no-repeat;
            background-size: 40px;
            top: -12px;
            left: 7px;
        }
        .doubt-1::after {
            position: absolute;
            content: '';
            width: 30px;
            height: 30px;
            background: url(@/assets/img/classes/common/triangle-down.png) no-repeat;
            background-size: 30px;
            bottom: -29px;
            left: 30px;
        }
        .p-1 {
            position: absolute;
            bottom: 0;
            left: 0;
        }
        .p-2 {
            position: absolute;
            bottom: 0;
            right: 0;
        }
        button {
            position: absolute;
            bottom: 40px;
            left: 50%;
            transform: translateX(-50%);
            width: 339px;
            height: 58px;
            font-size: 26px;
            color: #fff;
            background: #1f7eff;
            border-radius: 28px;
            cursor: pointer;
        }
    }
}
</style>
