<template>
  <div class="section">
    <div class="section-title">
      <div class="bg">
        <img src="@/assets/img/classes/common/BIG DATA.png" alt="" />
      </div>
      <span class="text-blue">应时而生</span>大数据助力解锁薪职场
    </div>
    <div class="section-content">
      <div class="content-left">
        <ul>
          <li :class="{ active: currentIndex == 1 }" @mouseover="change(1)">
            <div>
              <p class="title">好前景</p>
              <p class="p">万亿市场+百万人才缺口</p>
            </div>
          </li>
          <li :class="{ active: currentIndex == 2 }" @mouseover="change(2)">
            <div>
              <p class="title">好发展</p>
              <p class="p">应用广，行业无限制</p>
            </div>
          </li>
          <li :class="{ active: currentIndex == 3 }" @mouseover="change(3)">
            <div>
              <p class="title">好就业</p>
              <p class="p">各企大厂都需要</p>
            </div>
          </li>
          <li :class="{ active: currentIndex == 4 }" @mouseover="change(4)">
            <div>
              <p class="title">好薪资</p>
              <p class="p">平均月薪19300元/月</p>
            </div>
          </li>
        </ul>
      </div>
      <!-- 详情板 -->
      <div class="content-right" v-show="currentIndex === 1">
        <div class="title">万亿市场+百万人才缺口</div>
        <div class="info-1">
          近年来随着互联网和智能硬件的快速发展，数据呈爆炸式增长，国家数字建设、企业产业转型级、企业上云用云等，人才缺口越来越大，未来发展前景可见一般。<br />
          据工V部和信息化部发布的《 “十四五”
          大数据产业发展规划》指出，2025年我国大数据产业测算规模突破3万亿元。中国商业联合会数据分析专业委员会统计，未来中国基础性数据分析人才缺口将达到1400万。截止到2022年8月11日，仅职友及相关岗位招聘111720个
        </div>
        <div class="info-2">
          <div class="text-b">
            <p class="text-red"><span class="text-big"> 30000</span>亿万</p>
            <p>年产业规模突破</p>
          </div>
          <div class="text-b">
            <p class="text-red"><span class="text-big"> 14000</span>万</p>
            <p>国内基础数据人才缺口</p>
          </div>
          <div class="text-b">
            <p class="text-red"><span class="text-big"> 111720</span>个</p>
            <p>职友及相关职位日招聘</p>
          </div>
        </div>
        <div class="info-3">
          <p>此处相关数据来源分别为《 “ 十四五” 大数据产业发展规划》；</p>
          <p>
            中国商业联合会数据分析专业委员会统计；职友及样本选取日期2022年8月11日
          </p>
        </div>
      </div>
      <div class="content-right" v-show="currentIndex === 2">
        <div class="title">大数据技能应用领域广，行业覆盖全</div>
        <div class="info-img">
          <img src="@/assets/img/classes/bigdata/21_03.jpg" alt="" />
        </div>
      </div>
      <div class="content-right" v-show="currentIndex === 3">
        <div class="title">万亿市场+百万人才缺口</div>
        <div class="list">
          <ul>
            <li>
              <div class="content">
                <p>数据开发工程师</p>
                <p style="color: #ff5143">
                  <span style="font-size: 38px"> 24-30K</span>/月
                </p>
                <p>互联网大厂</p>
              </div>
            </li>
            <li>
              <div class="content">
                <p>数据开发工程师</p>
                <p style="color: #ff5143">
                  <span style="font-size: 38px"> 24-30K</span>/月
                </p>
                <p>互联网大厂</p>
              </div>
            </li>
            <li>
              <div class="content">
                <p>数据开发工程师</p>
                <p style="color: #ff5143">
                  <span style="font-size: 38px"> 24-30K</span>/月
                </p>
                <p>互联网大厂</p>
              </div>
            </li>
            <li>
              <div class="content">
                <p>数据开发工程师</p>
                <p style="color: #ff5143">
                  <span style="font-size: 38px"> 24-30K</span>/月
                </p>
                <p>互联网大厂</p>
              </div>
            </li>
            <li>
              <div class="content">
                <p>数据开发工程师</p>
                <p style="color: #ff5143">
                  <span style="font-size: 38px"> 24-30K</span>/月
                </p>
                <p>互联网大厂</p>
              </div>
            </li>
            <li>
              <div class="content">
                <p>数据开发工程师</p>
                <p style="color: #ff5143">
                  <span style="font-size: 38px"> 24-30K</span>/月
                </p>
                <p>互联网大厂</p>
              </div>
            </li>
          </ul>
        </div>
        <div class="info-3">
          <p>
            数据来源于boss直聘，数据样本选取日期为2022年8月17日,仅作为信息展示,不作为效果承诺
          </p>
        </div>
      </div>
      <div class="content-right" v-show="currentIndex === 4">
        <div class="title">万亿市场+百万人才缺口</div>
        <div class="info-title">
          <div>岗位平均薪资19.3K/月</div>
          <div>2016-2021年工资变化</div>
        </div>
        <div>
          <img
            src="@/assets/img/classes/bigdata/4_03.jpg"
            alt=""
            width="100%"
          />
        </div>
        <div class="info-3">
          <p>
            数据来源于职友集,数据样本选取日期为2022年8月11日,仅作为信息展示，不作为效果承诺
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentIndex: 1,
    };
  },
  components: {},
  mounted() {},
  methods: {
    change(index) {
      this.currentIndex = index;
    },
  },
};
</script>
<style scoped lang='scss'>
.section {
  margin-top: 60px;
  .section-title {
    text-align: center;
    font-size: 36px;
    color: #333334;
    font-weight: 500;
    .bg {
      // position: absolute;
      margin-bottom: -15px;
    }
  }
  .section-content {
    height: 520px;
    margin-top: 75px;
    background-color: #f7f7f7;

    .content-left {
      float: left;
      width: 290px;
      height: 100%;
      padding: 21px 0 21px 12px;
      li {
        height: 123px;
        box-sizing: border-box;
        padding: 32px 0 32px 50px;
        transition: all 0.3s;
        .title {
          font-size: 28px;
        }
        .p {
          padding-top: 14px;
        }
      }
      .active {
        color: #fff;
        transform: translateX(10px);
        background: url(@/assets/img/classes/common/bubble-green.png) no-repeat;
      }
    }

    .content-right {
      float: right;
      position: relative;
      top: -30px;
      right: 25px;
      width: 810px;
      height: 513px;
      background-color: #fff;
      box-shadow: 0 0 20px 8px #f1bcbc;
      border-radius: 16px;
      transition: all 0.3;
      .title {
        width: 417px;
        height: 41px;
        line-height: 41px;
        margin: 0 auto;
        text-align: center;
        color: #ff5143;
        background: #fceaec;
        border-radius: 0px 0px 14px 11px;
      }
      .info-img {
        box-sizing: border-box;
        padding: 20px;
        img {
          width: 100%;
        }
      }
      .info-1 {
        font-size: 16px;
        line-height: 26.5px;
        padding: 48px 45px 60px 30px;
      }
      .info-2 {
        display: flex;
        justify-content: space-evenly;
        margin-bottom: 66px;
        font-size: 16px;
        .text-b {
          line-height: 22.8px;
          font-weight: 500;
          text-align: center;
          .text-red {
            color: #ff5143;
          }
          .text-big {
            font-size: 38px;
          }
        }
      }
      .info-3 {
        line-height: 26px;
        text-align: center;
      }
      .list {
        text-align: center;
        width: 100%;
        height: 340px;
        box-sizing: border-box;
        padding: 20px;
        margin-bottom: 20px;
        ul {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          height: 320px;
          justify-content: space-between;
          align-content: space-between;
          li {
            position: relative;
            width: 220px;
            height: 150px;
            border: 1px solid #4e4e5372;
            .content {
              width: 100%;
              position: absolute;
              top: 50%;

              transform: translateY(-50%);
            }
          }
        }
      }
      .info-title {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 30px;
        line-height: 30px;
        padding: 0 20px;
        margin: 20px 0;
        box-sizing: border-box;
        text-align: center;

        div {
          width: 45%;
          height: 30px;
          background-color: #fceaec;
          color: #ff6c6c;
          border-radius: 30px;
        }
      }
    }
  }
}
</style>