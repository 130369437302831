<template>
    <div class="Layout-page">
        <div class="page-container">
            <PageHeader />
            <!-- 头部 -->
            <Head :nav="false"> </Head>
            <Nav>
                <li></li>
                <li><router-link to="/index">首页</router-link></li>
                <li><router-link to="/bigdata">大数据课程</router-link></li>
                <li><router-link to="/java">Java课程</router-link></li>
                <li><router-link to="/web">Web前端</router-link></li>
                <li><router-link to="/ui">UI设计</router-link></li>
                <li><router-link to="">最新资讯</router-link></li>
            </Nav>
            <!-- banner -->
            <Banner arrow="never">
                <el-carousel-item>
                    <img src="@/assets/img/classes/ui/ui-banner.jpg" alt="" height="485" />
                </el-carousel-item>
            </Banner>
            <!-- 课程介绍 -->
            <div class="courses-introduction intro">
                <div class="courses-nav">
                    <span
                        v-for="(item, index) in coursesNav"
                        :key="item.id"
                        :class="currentIndex == index ? 'courses-bor' : ''"
                        @click="goTarget(item.target, index)"
                    >
                        {{ item.name }}</span
                    >
                </div>
                <div class="courses-main intro">
                    <h3>UI设计是什么</h3>
                    <p>用户界面设计——不只是简单的ps设计，让产品有品位，让操作更舒适。</p>
                    <ul>
                        <li class="lis">
                            <img
                                src="../../../assets/img/classes/ui/6b58cf54-a739-40f9-b0ad-1476af0c0ef1.png"
                            />
                            <h6>用户体验设计</h6>
                            <p>
                                了解用户期望、用户习惯，构架产品设计，从用户角度，实现产品可操作性。
                            </p>
                        </li>
                        <li class="lis">
                            <img
                                src="../../../assets/img/classes/ui/59ae1abe-860e-4f54-9e0c-83e772cbc1e9.png"
                            />
                            <h6>平面设计</h6>
                            <p>
                                通过ICON设计、LOGO设计、图标设计等界面设计，实现产品构架界面展示。
                            </p>
                        </li>
                        <li class="lis ">
                            <img
                                src="../../../assets/img/classes/ui/0bb20e68-d3f0-4e15-837d-a7807ec87a16.png"
                            />
                            <h6>交互设计</h6>
                            <p>结合用户需求，实现产品界面交互，让界面优美动起来。</p>
                        </li>
                    </ul>
                    <div class="consultBtn">
                        <button>立即咨询</button>
                    </div>
                </div>
            </div>
            <!-- 职业路径 -->
            <div class="occupation ">
                <h2 class="occupation-t">
                    <img src="../../../assets/img/classes/ui/e64efda6-4ee3-4303-a66a-1b17dc83cbb4.png" />
                    <u>高瞻远瞩，</u>
                    <span>十年</span>
                    <u>职业路径！</u>
                </h2>
                <div class="text">
                    <p>
                        据职友集数据显示：全链路UI/UE设计师是10大薪资较多的职业之一，平均薪资
                        <span>11330元 /月</span>，<span>3-5</span> 年升任总监，薪资 <span>30万+</span>。
                    </p>
                    <p>随着UI交互设计学习的加深，能胜任的工作岗位也越来越丰富。</p>
                </div>
                <div class="chart">
                    <dl class="chart-one">
                        <dt>6w+</dt>
                        <dd>平面设计师</dd>
                        <dd>广告设计师</dd>
                    </dl>
                    <dl class="chart-two">
                        <dt>10w+</dt>
                        <dd>网页设计师</dd>
                        <dd>淘宝美工</dd>
                        <dd>平面设计师</dd>
                        <dd>广告设计师</dd>
                    </dl>
                    <dl class="chart-three">
                        <dt>15w+</dt>
                        <dd>UI界面设计师</dd>
                        <dd>移动UI设计师</dd>
                        <dd>网页设计师</dd>
                        <dd>淘宝美工</dd>
                        <dd>平面设计师</dd>
                        <dd>广告设计师</dd>
                    </dl>
                    <dl class="chart-four">
                        <dt>20w+</dt>
                        <dd>
                            <p>
                                <span>产品经理</span>
                                <span>WEB前端设计师</span>
                                <span>穿戴设备UI设计师</span>
                            </p>
                            <p>
                                <span>UI界面设计师</span>
                                <span>移动UI设计师</span>
                                <span>网页设计师</span>
                            </p>
                            <p>
                                <span>淘宝美工</span>
                                <span>平面设计师</span>
                                <span>广告设计师</span>
                            </p>
                        </dd>
                    </dl>
                </div>
                <div class="payBtn">
                    <button>开启你的"薪"路历程！</button>
                </div>
            </div>
            <!-- 人才告急 -->
            <div class="profession">
                <h3>
                    全链路UI/UE设计师人才告急
                    <span>薪资水涨船高</span>
                </h3>
                <div class="text">
                    <p>随着“互联网+”时代带来的大发展，企业更加意识到用户界面的重要性，</p>
                    <p>
                        注重用设计思维来规划产品形态，对于UI设计师的需求也在不断加大，预计UI人才缺口将超过100万
                    </p>
                </div>
                <div class="box">
                    <div class="box-item">
                        <div class="box-hd">
                            <span>39209</span>
                            <span>日均39209条招聘</span>
                        </div>
                        <div class="box-bd box-bd1">
                            <div class="image">
                                <img src="../../../assets/img/classes/ui/猎聘.jpg" />
                                <span>共6082个职位</span>
                            </div>
                            <div class="image">
                                <img src="../../../assets/img/classes/ui/前程无忧.jpg" />
                                <span>共5560个职位</span>
                            </div>
                            <div class="image">
                                <img src="../../../assets/img/classes/ui/智联招聘.jpg" />
                                <span>共7000个职位</span>
                            </div>
                            <div class="image">
                                <img src="../../../assets/img/classes/ui/58同城.jpg" />
                                <span>共9600个职位</span>
                            </div>
                        </div>
                        <div class="box-ft">
                            据统计，目前我国UI设计人才缺口达40万之多，而市场每年至少产生15万的人才缺口，UI就业市场供不应求。
                        </div>
                    </div>
                    <div class="box-item">
                        <div class="box-hd box-hd2">
                            <span>平均月薪达</span>
                            <span>13060</span>
                        </div>
                        <div class="box-bd box-bd2">
                            <div class="image"></div>
                        </div>
                        <div class="box-ft">
                            随着工作经验的增加，薪资涨幅空间大，互联网岗位中低门槛、高晋升的岗位UI设计行业正在快速发展，未来的价值急速飙升！
                        </div>
                    </div>
                    <div class="box-item">
                        <div class="box-hd box-hd3">
                            <span>就职企业</span>
                        </div>
                        <div class="box-bd box-bd3">
                            <div class="image"></div>
                        </div>
                        <div class="box-ft">
                            招聘UI人才的企业不仅仅局限于互联网企业，越来越多的企业开始注重交互设计相关的人才投入，如金融、交通、零售等行业都在热招该类型的设计人才。
                        </div>
                    </div>
                </div>
                <div class="getPro">
                    <button>我要入行 >></button>
                </div>
            </div>
            <!-- 学习交互设计 -->
            <div class="student">
                <h3>我适合学习 <span>UI/UE交互设计</span> 吗？</h3>
                <div class="text">
                    <p>
                        零基础小白、有基础设计菜鸟、平面设计师、初级UI设计师以及一切对设计
                    </p>
                    <p>行业抱有好感和认同的人，UI都是你晋升的不二之选</p>
                </div>
                <ul class="card">
                    <li>
                        <h5>在校大学生</h5>
                        <p>
                            专业或非专业在校大学生、应届毕业生，熟悉时下流行的互联网产品，学习UI设计，就业前景相当广阔！
                        </p>
                        <button>我要咨询 ></button>
                    </li>
                    <li>
                        <h5>零基础人员</h5>
                        <p>
                            0基础从未接触过互联网，不管你有没有相关经验，只要有态度、有付出都能成为一名出色的设计师！
                        </p>
                        <button>我要咨询 ></button>
                    </li>
                    <li>
                        <h5>同行业深造</h5>
                        <p>
                            甘心当只会做图的美工？UI设计与传统设计行业相比，薪资高、前景好，进行UI深造能收获广阔未来。
                        </p>
                        <button>我要咨询 ></button>
                    </li>
                    <li>
                        <h5>转行换工作</h5>
                        <p>
                            互联网时代UI设计行业发展前景广阔，转行人员的不二之选，新时代催生新技术，开启UI设计逆袭之旅！
                        </p>
                        <button>我要咨询 ></button>
                    </li>
                    <li>
                        <h5>其他情况</h5>
                        <p>我是其他情况，想要咨询了解UI课程。</p>
                        <button>我要咨询 ></button>
                    </li>
                </ul>
                <div class="select">
                    今天的选择，决定你明天的生活！
                    <img src="../../../assets/img/classes/ui/free-btn_01.jpg" />
                </div>
            </div>
            <!-- 区别 -->
            <div class="distinction">
                <h3>5k月薪与12k月薪UI设计师的区别</h3>
                <div class="text">
                    <p>同是UI设计师，为什么有的月薪5k，</p>
                    <p>有的月薪12k？到底什么样的UI设计师，才是企业会高薪聘请的呢？</p>
                </div>
                <div class="table"></div>
            </div>
            <!-- 课程 -->
            <div class="course">
                <h3>匠心课程 助你挑战月薪12k+</h3>
                <p>如何让你成为2年以上工作经验的UI设计大师</p>
                <ul>
                    <li>
                        <div class="left">01</div>
                        <div class="right">
                            <h6>视觉设计基础篇</h6>
                            <p>
                                掌握UI设计工具的使用后，通过学习素描光影关系、色彩原理与搭配及构图原理，打好成为UI设计师的美术造型基础和树立色彩审美意识，并能利用色彩原理进行相关案例设计与制作。
                            </p>
                        </div>
                    </li>
                    <li>
                        <div class="left">02</div>
                        <div class="right">
                            <h6>UI设计师入门篇</h6>
                            <p>
                                树立设计师意识，了解UI发展规律及就业前景，并通过本阶段的学习能独立完成图标作品设计、平面作品设计及手绘作品设计等。
                            </p>
                        </div>
                    </li>
                    <li>
                        <div class="left">03</div>
                        <div class="right">
                            <h6>多平台界面设计篇</h6>
                            <p>
                                基于用户界面设计准则和移动新特征，了解UI设计流程和学习后，能独立完成移动端框架设计和产品界面设计。
                            </p>
                        </div>
                    </li>
                    <li>
                        <div class="left">04</div>
                        <div class="right">
                            <h6>交互设计思维篇</h6>
                            <p>
                                通过对产品设计思维的掌握和原型图、脑图设计学习后，充分了解用户体验的重要性，学习触摸式设计和界面优化设计，成为一名真正懂交互的UI设计师。
                            </p>
                        </div>
                    </li>
                    <li>
                        <div class="left">05</div>
                        <div class="right">
                            <h6>Html5学习篇</h6>
                            <p>
                                基于web端知识的学习，在个人能力的基础上进一步提高，并能独立完成PC端及移动端的各种响应式网站制作。
                            </p>
                        </div>
                    </li>
                    <li>
                        <div class="left">06</div>
                        <div class="right">
                            <h6>动效设计及项目实战</h6>
                            <p>
                                学习AE在UI交互过程中的动态效果设计方法和把握动画节奏。并以实战项目来进阶工作经验，让你快速成长为全能的UI设计师
                                。
                            </p>
                        </div>
                    </li>
                </ul>
                <div class="seeBtn">
                    <button>查看完整课程大纲</button>
                </div>
            </div>
            <!-- 学员作品 -->
            <div class="works">
                <h3>学员作品</h3>
                <ul>
                    <li>
                        <img src="../../../assets/img/classes/ui/img1.jpg" />
                        <p>LOGO设计</p>
                    </li>
                    <li>
                        <img src="../../../assets/img/classes/ui/img2.jpg" />
                        <p>界面设计</p>
                    </li>
                    <li>
                        <img src="../../../assets/img/classes/ui/img3.jpg" />
                        <p>网页设计</p>
                    </li>
                    <li>
                        <img src="../../../assets/img/classes/ui/img4.jpg" />
                        <p>图标设计</p>
                    </li>
                    <li>
                        <img src="../../../assets/img/classes/ui/img5.jpg" />
                        <p>网页设计</p>
                    </li>
                    <li>
                        <img src="../../../assets/img/classes/ui/img6.jpg" />
                        <p>界面展示</p>
                    </li>
                    <li>
                        <img src="../../../assets/img/classes/ui/img7.jpg" />
                        <p>网页设计</p>
                    </li>
                    <li>
                        <img src="../../../assets/img/classes/ui/img8.jpg" />
                        <p>界面设计</p>
                    </li>
                    <p class="copy">
                        以上为学员临摹作品，最终解释权归其所有，若原作者有任何疑问，请致电官方联系:0551-69117050
                    </p>
                </ul>
            </div>
            <!-- 选择班级 -->
            <div class="selectClass">
                <div class="title">
                    你若不为自己改变，世界更不会为你改变！
                    <img src="../../../assets/img/classes/ui/free-btn_01.jpg" />
                </div>
                <h5>
                    <img src="../../../assets/img/classes/ui/e64efda6-4ee3-4303-a66a-1b17dc83cbb4.png" />
                    不同 <span>班型</span> ，由你选择
                </h5>
                <ul>
                    <li>
                        <h6>全日制封闭特训班</h6>
                        <div class="text">
                            <p>0基础！无专业背景！</p>
                            <p>全日制、封闭式教学，</p>
                            <p>学习效率高、学习效果</p>
                            <p>好，就业薪资理想！</p>
                        </div>
                        <button>点击查看详情 >></button>
                    </li>
                    <li>
                        <h6>就业促进班</h6>
                        <div class="text">
                            <p>刚毕业 / 工资低 / 想转行，</p>
                            <p>渴望丰厚薪资！</p>
                            <p>这里有你的同盟军！</p>
                        </div>
                        <button>点击查看详情 >></button>
                    </li>
                    <li>
                        <h6>周末班</h6>
                        <div class="text">
                            <p>工作日上班，想利用周</p>
                            <p>末提升技能，追求更高</p>
                            <p>职位和更多薪水！那还不来这里！</p>
                        </div>
                        <button>点击查看详情 >></button>
                    </li>
                    <li>
                        <div class="hd">
                            <h6>PS全方位</h6>
                            <h6>软件教程班</h6>
                        </div>
                        <div class="text">
                            <p>这年头！没点儿PS技术，</p>
                            <p>你还想混江湖！还不来学习！</p>
                        </div>
                        <button>点击报名 >></button>
                    </li>
                    <li>
                        <div class="hd">
                            <h6>AI广告</h6>
                            <h6>高端创意设计班</h6>
                        </div>
                        <div class="text">
                            <p>有天马星空的创意，</p>
                            <p>没有转化的能力，那将都是浮云！</p>
                            <p>唯技高才能出精品！</p>
                        </div>
                        <button>点击报名 >></button>
                    </li>
                    <li>
                        <div class="hd">
                            <h6>网课/远程</h6>
                            <h6>教学班</h6>
                        </div>
                        <div class="text">
                            <p>没时间？想学习？远程网课适合你！</p>
                            <p>名师研发，</p>
                            <p>这里全是精华！</p>
                        </div>
                        <button>点击报名 >></button>
                    </li>
                </ul>
                <div class="classBtn">
                    <div class="btn-box">
                        <button>咨询合适我的班级</button>
                    </div>
                </div>
            </div>
            <!-- 高薪就业 -->
            <div class="money">
                <div class="title">
                    选择报名的必杀技，先试听，再报名！
                    <img src="../../../assets/img/classes/ui/free-btn_01.jpg" />
                </div>
                <h3>为什么 <span>90%+</span> 学员能 <span>高薪</span> 就业?</h3>
                <p>
                    因为我们就是为解决学员从学习到生活到就业的所有痛点，排除你的重重障碍，只为你拿高薪！
                </p>
                <ul>
                    <li>
                        <img src="../../../assets/img/classes/ui/w01.png" alt="" />
                        <div class="item">
                            <h5>就业保障</h5>
                            <p>
                                入学签订“北大青鸟学员教育培训险”，就业符合理赔条件的，最高
                                可获得全额学费的保险赔偿金！
                            </p>
                        </div>
                    </li>
                    <li>
                        <img src="../../../assets/img/classes/ui/w02.png" alt="" />
                        <div class="item">
                            <h5>基础补习</h5>
                            <p>根据学生报到时间，集体为基础薄 弱学员做补习。</p>
                        </div>
                    </li>
                    <li>
                        <img src="../../../assets/img/classes/ui/w03.png" alt="" />
                        <div class="item">
                            <h5>阶段性考核</h5>
                            <p>
                                每一阶段针对学员所学课程进行阶 段性测试考核，严格学好每门课，
                                时刻掌握学员学习情况。
                            </p>
                        </div>
                    </li>
                    <li>
                        <img src="../../../assets/img/classes/ui/w04.png" alt="" />
                        <div class="item">
                            <h5>案例式教学</h5>
                            <p>
                                北大青鸟采用自主研发实训产品，完全贴合学员实训目标，完全区别于别家采用网上通用案例教学。
                            </p>
                        </div>
                    </li>
                    <li>
                        <img src="../../../assets/img/classes/ui/w05.png" alt="" />
                        <div class="item">
                            <h5>全程助教辅导</h5>
                            <p>助教老师24小时全程跟踪辅导360° 无死角解决学生疑问。</p>
                        </div>
                    </li>
                    <li>
                        <img src="../../../assets/img/classes/ui/w06.png" alt="" />
                        <div class="item">
                            <h5>专属学习方案</h5>
                            <p>
                                根据每位学员的实际情况，导师给 学员制定个性化学习方案，无论基
                                础好差都能根据实际情况出色的完 成学业。
                            </p>
                        </div>
                    </li>
                </ul>
            </div>
            <footerNav></footerNav>
            <Footer></Footer>
        </div>
    </div>
</template>

<script>
import Head from '@/components/head.vue'
import PageHeader from '@/components/pageHeader.vue'
import Nav from '@/components/nav.vue'
import Banner from '@/components/banner.vue'
import footerNav from '@/components/footerNav.vue'
import Footer from '@/components/footer.vue'

export default {
    data() {
        return {
            coursesNav: [
                {
                    id: 0,
                    name: '课程介绍',
                    target: 'intro'
                },
                {
                    id: 1,
                    name: '课程目录',
                    target: 'course'
                }
            ],
            currentIndex: 0,
            isRemove: true
        }
    },
    components: {
        Head,
        PageHeader,
        Nav,
        Banner,
        footerNav,
        Footer
    },
    mounted() {
		document.title = '合肥一元数智教育有限公司'
        window.addEventListener('scroll', () => {
            let nav = document.querySelector('.courses-nav')
            // console.log(window.scrollY)
            // 730px
            if (window.scrollY > 660) {
                nav.style.position = 'fixed'
            } else {
                nav.style.position = 'absolute'
            }
        })
    },
    methods: {
        goTarget(page, index) {
            this.currentIndex = index
            const target = document.querySelector(`.${page}`).offsetTop - 100
            window.scrollTo({ top: target, behavior: 'smooth' })
        }
    }
}
</script>
<style scoped lang="scss">
@import url('../../../styles/ui.css');
.courses-bor {
    border-bottom-color: #14ddab;
    color: #14ddab;
}
</style>
