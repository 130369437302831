<template>
  <div class="about">
    <div class="about-title" @click="$router.push('/about')">
      <img src="@/assets/img/index/common/about_b1_t.png" alt="" />
      <p>培养职场精英，成就每一个家庭的幸福生活。</p>
    </div>
    <div class="about-intro">
      <div class="text">
        合肥一元教育咨询有限公司是经北大青鸟授权并在安徽地区从事北大青鸟品牌IT培训的机构。<br>
        北大青鸟APTECH，中国IT职业教育的先行者，由北大青鸟集团与印度阿博泰克<br>
        （APTECH）合资成立于1999年。
        依托北京大学优质雄厚的教育资源和背景，秉承 &ensp;“教育改变生活”&ensp;的发展理念，一直致力于培养中国IT技能型紧缺人才，
        通过特许加盟和院校合作两大经营模式以及先进的IT职业教育课程体系在教育领域广泛开展合作。
        目前北大青鸟200&ensp;余家授权培训中心遍布全国60多个重要城市，全国合作院校超过600所，
        同10000余家知名企业建立了战略合作伙伴关系，累计培养和输送85余万IT职业化人才进入IT行业。
        北大青鸟将继续继续领航中国职业教育，培养职场精英，成就每一个家庭的幸福生活！
      </div>
      <div>
        <img src="@/assets/img/index/common/about_b1_02.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  mounted() {},
  methods: {},
};
</script>
<style scoped lang='less'>
.about {
  width: 1200px;

//padding: 73px 0 0;
  .about-title {
    text-align: center;
    font-size: 14px;
    color: #999;
    margin-left: -60px;
    cursor: pointer;
    p {
      line-height: 38.79px;
    }
  }
  .about-intro {
    display: flex;
    justify-content: space-between;
    div {
      width: 50%;
      img {
        width: 100%;
        margin-left: 40px;
      }
    }
    .text {
      padding: 40px 8px 0 0px;
      font-size: 14px;
      line-height: 30px;
    }
  }
}
</style>