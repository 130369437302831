v<template>
    <div class="section clear">
        <div class="section-title">大牛师资带你实现<span class="text-blue">高薪就业</span></div>
        <div class="section-content">
            <div class="">
                <img :src="imgUrl" alt="" class="teacher" />
                <img src="@/assets/img/classes/bigdata/t-1.png" alt="" class="t-1" />
                <img src="@/assets/img/classes/bigdata/t-2.png" alt="" class="t-2" />
                <img src="@/assets/img/classes/bigdata/t-3.png" alt="" class="t-3" />
                <button class="t-4" style="cursor: pointer;"><a href="http://pdt.zoosnet.net/LR/Chatpre.aspx?id=PDT87252886&lng=cn" style="color:#fff;">与名师交谈</a></button>
                <span class="text-big">TEACHER</span>
                <div class="text">
                    <p class="title">{{ textTitle }}</p>
                    <p class="main">
                        {{ textContent }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {}
    },
    props: {
        imgUrl: String,
        textTitle: String,
        textContent: String
    },
    components: {},
    mounted() {},
    methods: {}
}
</script>
<style scoped lang="scss">
.section {
    margin: 60px 0;
    padding-bottom: 60px;
    overflow: hidden;
    .section-title {
        text-align: center;
        font-size: 36px;
        color: #333334;

        .text-blue {
            font-weight: 600;
        }
    }

    .section-content {
        position: relative;
        height: 600px;
        width: 100%;
        margin-top: 30px;

        img {
            position: absolute;
        }

        .teacher {
            height: 550px;
            width: 426px;
            bottom: 0;
            left: 20px;
            z-index: 5;
        }

        .t-1 {
            top: 229px;
            right: 76px;
        }

        .t-2 {
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
        }

        .t-3 {
            top: 158px;
            right: 482px;
        }

        .t-4 {
            position: absolute;
            bottom: -27px;
            left: 492px;
            height: 55px;
            width: 335px;
            border-radius: 55px;
            font-size: 25px;
            color: #fff;
            background-color: #1f7eff;
            // border-right: 10px solid #fff;
        }

        .text-big {
            position: absolute;
            top: 117px;
            right: 128px;
            font-size: 78px;
            font-weight: bold;
            color: #e0dfdf;
        }

        .text {
            position: absolute;
            top: 202px;
            right: 140px;
            font-size: 18px;
            width: 420px;
            line-height: 30px;

            .title {
                font-size: 35px;
                font-weight: bold;
                margin-bottom: 26px;
            }
        }
    }
}
</style>
