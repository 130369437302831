<template>
    <div class="Layout-page">
        <div class="page-container">
            <PageHeader />
            <!-- 头部 -->

            <Head :nav="false"> </Head>
            <Nav>
                <li></li>
                <li><router-link to="/index">首页</router-link></li>
                <li><router-link to="/bigdata">大数据课程</router-link></li>
                <li><router-link to="/java">Java课程</router-link></li>
                <li><router-link to="/web">Web前端</router-link></li>
                <li><router-link to="/ui">UI设计</router-link></li>
                <li><router-link to="">最新资讯</router-link></li>
            </Nav>

            <!-- banner -->

            <!-- <Banner arrow="never">
                <el-carousel-item v-for="item in banner" :key="item.id">
                    <img :src="item.img" alt="" height="485" />
                </el-carousel-item>
            </Banner> -->
            <main>
                <!-- 提前了解 -->
                <AdvanceUnderstand> </AdvanceUnderstand>
                <!-- 优势板块 -->
                <Advantage />
                <!-- 三大主流技术 -->
                <MainTechnology />
                <!-- 课程目标 -->
                <CourseOrientation />
                <!-- 课程适合谁 -->
                <Suitable />
                <!-- 课程模式阶段 -->
                <CourseModules :outline="outline" />
                <!-- 名师介绍 -->
                <FamousTeacher :imgUrl="teacherImg" :textTitle="textTitle" :textContent="textContent" />

                <!-- 毕业生 -->
                <Graduate :banner="figures" :student="students" />
                <!-- 就业流程图 -->
                <flow-chart></flow-chart>

                <!-- 名企直招  环境-->
                <Employment />

                <!-- 疑问 -->
                <Doubt />
            </main>
            <!-- 页脚 -->
            <PageFooter>
                <p>皖ICP备13012660号-1如有图片侵权请及时联系本站，将及时删错或更改</p>
                <p>合肥一元教育咨询有限公司版权所有</p>
            </PageFooter>
        </div>
    </div>
</template>

<script>
import Head from '@/components/head.vue'
import PageHeader from '@/components/pageHeader.vue'
import AdvanceUnderstand from '../../../components/advance-understand.vue'
import Advantage from './components/advantage.vue'
import Banner from '@/components/banner.vue'
import MainTechnology from './components/main-technology.vue'
import CourseOrientation from './components/course-orientation.vue'
import Suitable from '@/components/suitable.vue'
import CourseModules from './components/course-modules.vue'
import FamousTeacher from '@/components/famous-teacher.vue'
import Doubt from '@/components/doubt.vue'
import Employment from '@/components/employment.vue'
import Graduate from '../../../components/graduate.vue'
import FlowChart from '@/components/flow-chart.vue'
import PageFooter from '@/components/pageFooter.vue'
import Nav from '@/components/nav.vue'
// import { getBanner } from '@/api/home'
import { getFigures, getProject, getStudent, getTeacher, getModule } from '@/api/class'
import { strToArray } from '@/utils'
export default {
    name: 'BigData',
    components: {
        Head,
        PageHeader,
        Nav,
        AdvanceUnderstand,
        Advantage,
        Banner,
        MainTechnology,
        CourseOrientation,
        Suitable,
        CourseModules,
        FamousTeacher,
        Doubt,
        PageFooter,
        Employment,
        Graduate,
        FlowChart
    },
    data() {
        return {
            name: '大数据',
            banner: '',
            teacherImg: require('@/assets/img/classes/bigdata/曹海洋老师.png'),
            textTitle: '曹老师大数据高级讲师',
            textContent:
                '高级讲师，多年开发及教学经历，对大数据，后端开发等有丰富经验，精通Java等常用开发语言，有人脸识别，数据统计，集群搭建配置，招商银行理财管理系统，万家在线商务网站等多个大型项目经验。',
            plates: ['技术阶段一', '技术阶段二', '技术阶段三', '技术阶段四', '技术阶段五'],
            project: [],
            students: [],
            figures: [],
            outline: [],
        }
    },
    created() {
        // this.getBanner()
        this.getProject()
        this.getModule()
        this.getStudent()
        this.getFigures()
        // this.getTeacher()
		
    },
    mounted() {
    	document.title = '合肥一元数智教育有限公司'
    },
    methods: {
        // async getBanner() {
        //     this.banner = await getBanner(this.name)
        //     // console.log(this.banner)
        // },
        async getProject() {
            const list = await getProject(this.name)
            this.project = list.filter((item, index) => {
                if (index <= 4) {
                    return item
                }
            })
        },
        async getModule() {
            const r = await getModule(this.name)
            this.outline = r.map(item => {
                item.skill = strToArray(item.skill).join('、')
                item.ability = strToArray(item.ability)
                item.module = '技术' + item.module
                return item
            })
            console.log(this.outline)
        },

        async getStudent() {
            const list = await getStudent()
            this.students = list.filter(item => item.position == this.name)
            console.log(this.students)
			
        },
        async getTeacher() {
            const res = await getTeacher(this.name)
            console.log(res)
            // if (res instanceof Array) {
            //     this.teacherImg = res[0].img
            //     this.textTitle = res[0].name
            //     this.textContent = res[0].introduction
            // } else if (res instanceof Object) {
            //     this.teacherImg = res.img
            //     this.textTitle = res.name
            //     this.textContent = res.introduction
            // }
			const { name, introduction, img } = await getTeacher(this.name)
			this.teacherImg = img
			this.textTitle = name
			this.textContent = introduction
			 console.log(this.teacherImg)
        },
        async getFigures() {
            this.figures = await getFigures(this.name)
        }
    }
}
</script>
<style scoped lang="scss"></style>
