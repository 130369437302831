<template>
  <div class="tophead">
    <div class="inner">
      <span>课工场­­­───北大青鸟旗下，高端IT培训教育品牌</span>
      <span>免费咨询热线：0551-69117050</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  mounted() {},
  methods: {},
};
</script>
<style scoped lang='scss'>
.tophead {
    width: 100%;
    height: 30px;
    line-height: 30px;
    margin-right: 1px;
    font-size: 19px;
    background-color: #ebebeb;

    .inner {
      display: flex;
      width: 1200px;
      justify-content: space-between;
      margin: 0 auto;
      span:first-child {
        font-size: 12px;
      }
    }
  }
</style>